import React, { useState, useEffect } from "react";
import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../Constant";
import CustomTooltip from "../CustomTooltip";
import { useDispatch } from "react-redux";
import { fetchVisitPad } from "../ReduxApi/VistPadApiCall";
import ToastFist from "../Toast/ToastFist";

const AddNewDataForAll = ({ catID, name, InputField }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    type: "",
    units: "",
    categoryId: catID,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  // Update categoryId in the state when catID prop changes
  // useEffect(() => {
  //   setInputValue((prev) => ({
  //     ...prev,
  //     categoryId: catID,
  //   }));
  // }, [catID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue((prev) => ({ ...prev, [name]: value.toUpperCase() }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    //  console.log(" inputValue.categoryId", inputValue.categoryId,)
    try {
      const response = await axios.post(
        `${BASE_URL}/addNewDataForAll`,
        {
          pubInfo: { sessionId: "3762D32E2F01170A2D5B50B5C74F48C7" },
          request: {
            busiParams: {
              categoryId: catID,
              name: inputValue.name,
              type: inputValue.type,
              units: inputValue.units,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );

      setInputValue({ name: "", type: "", units: "", categoryId: catID });
      dispatch(fetchVisitPad());
      ///   alert("Data added successfully!");

      //  console.log(response.data)
    } catch (err) {
      setInputValue({ name: "", type: "", units: "", categoryId: catID });
      setError(err.response?.data?.message || "Something went wrong!");
    } finally {
      setInputValue({ name: "", type: "", units: "", categoryId: catID });
      setLoading(false);
    }
  };

  return (
    <>
      <div data-bs-toggle="modal" data-bs-target={`#modal-${catID}`}>
        <CustomTooltip icon="fa-solid fa-square-plus" tooltipText="New Item" />
      </div>

      <div
        className="modal fade"
        id={`modal-${catID}`}
        tabIndex="-1"
        aria-labelledby={`modalLabel-${catID}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`modalLabel-${catID}`}>
                Create New {name} Record
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {InputField === 1 ? (
                <form>
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      Name:
                    </span>

                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={inputValue.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  )}
                </form>
              ) : (
                <form>
                  <div className="row align-items-start">
                    <div className="col">
                      <label htmlFor="name" className="col-form-label">
                        Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="type" className="col-form-label">
                        Type:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="type"
                        name="type"
                        value={inputValue.type}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="units" className="col-form-label">
                        Units:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="units"
                        name="units"
                        value={inputValue.units}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  )}
                </form>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Sending..." : "Save"}
              </button>
            </div>
          </div>
        </div>
        <ToastFist
          showToast={loading}
          setShowToast={setLoading}
          title="Indication Server"
          message="Data added successfully!."
          duration="Just now"
          status={1}
        />
      </div>
    </>
  );
};

export default AddNewDataForAll;
