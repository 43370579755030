import axios from "axios";
import { ACCESS_TOKEN, BASE_URL } from "../../Constant"; // Assuming you're using this constant for storing tokens

// Create an Axios instance with default configurations
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    "Access-Control-Allow-Headers": "Content-Type",
  },
});

// Function to make a GET request
const getApiHelper = async (endpoint, config = {}) => {
  try {
    // Create query string from params object
    const queryString = new URLSearchParams(config).toString();
    const url = `${BASE_URL}${endpoint}?${queryString}`;

    // const response = await axios.get(url);
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem(ACCESS_TOKEN),
      },
    });

    return response.data; // return the data from the response
  } catch (error) {
    console.error("Error fetching data:", error);
    // throw error; // throw error to handle it where you call this function
    return false;
  }
};

export default getApiHelper;
