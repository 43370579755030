import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  ArrayuniqueData,
  convertOnlyValue,
  convertSelectedDataToServerFormat,
  DataConverte,
  DataConvertecheck,
  removeMatchingItems,
} from "../../DataConverte/DataConverte";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";
import Categories from "../../visitConstant/Categories";
import AddNewDataForAll from "../../modal/AddNewDataForAll";
import PlusButton from "../../ui/PlusButton";

import { iconColor } from "../../visitConstant/repotOtherConstant";

import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from "../../ui/CSS/customStyles";

import CreatableSelect from "react-select/creatable";
import { handleNewOptions } from "../../Utils/handleNewOptions";
import { DROPDOWNSARCHYNAME } from "../../visitConstant/ApiConstant";
import DropdownSearchApl from "../../ApiHelpar/DropdownSearchApl";
import ToastFist from "../../Toast/ToastFist";

const History = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  const [addData, setAddData] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({});
  const [AddDataShowUi, setAddDataShowUi] = useState();
  const [optionsData, setOptionsData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  // Access the data from Redux state
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);
  //// templet Data update
  const [TempletDataReserve, setTempletDataReserve] = useState();
  useEffect(() => {
    setTempletDataReserve(patientData?.historyData);
  }, [patientData]);

  const TempletDataTransfer = (TempletData) => {
    const serveData = {
      allergies: ArrayuniqueData([
        ...(addData?.allergies || []),
        ...(DataConvertecheck(TempletDataReserve?.allergies) || []),
        ...(DataConvertecheck(TempletData.allergies) || []),
      ]),
      personalHistory: ArrayuniqueData([
        ...(addData?.personalHistory || []),
        ...(DataConvertecheck(TempletDataReserve?.personalHistory) || []),
        ...(DataConvertecheck(TempletData.personalHistory) || []),
      ]),
      pastMedicalhistory: ArrayuniqueData([
        ...(addData?.pastMedicalhistory || []),
        ...(DataConvertecheck(TempletDataReserve?.pastMedicalhistory) || []),
        ...(DataConvertecheck(TempletData.pastMedicalhistory) || []),
      ]),
      familyHistory: ArrayuniqueData([
        ...(addData?.familyHistory || []),
        ...(DataConvertecheck(TempletDataReserve?.familyHistory) || []),
        ...(DataConvertecheck(TempletData.familyHistory) || []),
      ]),
    };

    // console.log("combinedData====", serveData);
    setTempletDataReserve(serveData);
  };

  useEffect(() => {
    if (TempletDataReserve) {
      const localData = {
        allergies: DataConvertecheck(TempletDataReserve.allergies),
        personalHistory: DataConvertecheck(TempletDataReserve.personalHistory),
        pastMedicalhistory: DataConvertecheck(
          TempletDataReserve.pastMedicalhistory
        ),
        familyHistory: DataConvertecheck(TempletDataReserve.familyHistory),
      };

      if (
        localData?.allergies?.length > 0 ||
        localData?.personalHistory?.length > 0 ||
        localData?.pastMedicalhistory?.length > 0 ||
        localData?.familyHistory?.length > 0
      ) {
        setOpen(true);
      } else {
        setColorStatus(3);
        setToastMsg("Data not available");
        setShowToast(true);
      }

      const serveData = {
        allergies: convertOnlyValue(localData.allergies),
        personalHistory: convertOnlyValue(localData.personalHistory),
        pastMedicalhistory: convertOnlyValue(localData.pastMedicalhistory),
        familyHistory: convertOnlyValue(localData.familyHistory),
      };

      // console.log(localData)

      setAddData(localData);
      setAddDataShowUi(serveData);
      dispatch(updateField("historyData", serveData));

      // console.log("localData", localData);
      // console.log("serveData", serveData);
    }
  }, [TempletDataReserve]);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setAddData({});
    setAddDataShowUi();
    setTempletDataReserve();
    dispatch(updateField("historyData", {}));
    // console.log("All data cleared");
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  // Handle change in Select components
  const handleSelectChange = async (selectedOptions, name, categoryKey) => {
    //    console.log(selectedOptions, name, categoryKey, "selectedOptions history")

    try {
      const updatedOptions = await handleNewOptions(
        selectedOptions,
        categoryKey,
        showToast,
        setShowToast,
        setToastMsg,
        setColorStatus
      );

      const serverData = convertSelectedDataToServerFormat(
        name,
        updatedOptions || []
      );
      const updatedData = {
        ...addData,
        [name]: removeMatchingItems(updatedOptions),
      }; // Store the selected options locally

      // console.log( removeMatchingItems(updatedData))

      console.log(updatedData);

      setAddData(updatedData);

      setAddDataShowUi((prevData) => ({ ...prevData, ...serverData }));
      dispatch(updateField("historyData", { ...AddDataShowUi, ...serverData }));
      //   console.log("historyData ====>", { ...AddDataShowUi, ...serverData });
    } catch (error) {
      console.log(error);
    }

    //  console.log("Updated Data:", { ...AddDataShowUi, ...serverData });

    // console.log( "ui data ==",  updatedData)
  };

  const [DropDownInfo, setDropDownInfo] = useState({});

  // Function to handle input change and fetch data from search API
  const handleInputChange = (newValue, index, categoryId) => {
    const uppercasedValue = newValue.toUpperCase();

    setInputValues((prevValues) => ({
      ...prevValues,
      [index]: uppercasedValue,
    }));
    setDropDownInfo({ categoryId, uppercasedValue, index });
  };
  const { DropdownServerData, DropdownIsLoading, DropdownError } =
    DropdownSearchApl(
      DROPDOWNSARCHYNAME,
      DropDownInfo.uppercasedValue,
      DropDownInfo.categoryId
    );

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      if (!DropDownInfo.uppercasedValue) return; // Avoid fetching if searchTerm is empty

      setIsLoading({ [DropDownInfo.index]: DropdownIsLoading });
      try {
        setOptionsData((prevOptions) => ({
          ...prevOptions,
          [DropDownInfo.categoryId]: DropdownServerData,
        }));
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setIsLoading({ [DropDownInfo.index]: DropdownIsLoading });
      }
    };

    fetchDropdownOptions(); // Call the function to fetch options
  }, [DropdownServerData, DropDownInfo.categoryId, DropdownIsLoading]); // Dependencies

  const categories = [
    {
      label: "Allergies",
      placeholder: "Allergies...",
      optionsData: visitPadData?.histories?.allergies,
      fieldName: "allergies",
    },
    {
      label: "Personal History",
      placeholder: "Personal History...",
      optionsData: visitPadData?.histories?.personalHistories,
      fieldName: "personalHistory",
    },
    {
      label: "Past Medical History",
      placeholder: "Past Medical History...",
      optionsData: visitPadData?.histories?.pastMedicalHistories,
      fieldName: "pastMedicalhistory",
    },
    {
      label: "Family History",
      placeholder: "Family History...",
      optionsData: visitPadData?.histories?.familyHistories,
      fieldName: "familyHistory",
    },
  ];

  return (
    <>
      <div className="d-flex">
        <IconFunctionality
          catID={Categories?.HISTORY?.catID}
          fieldName={Categories?.HISTORY?.name}
          name="History"
          iconSave
          iconRefresh
          iconErase
          onClick={handleClearData}
          setTempData={TempletDataTransfer}
        />

        <PlusButton setOpen={setOpen} open={open} />
      </div>

      <div className="col-12 col-xxl-8 mx-xxl-auto" style={{ paddingLeft: 40 }}>
        {open && (
          <div>
            <div className="row row-cols-2 gx-3">
              {categories.map((category, index) => {
                const categoryId =
                  index === 0
                    ? Categories.ALLERGIES.catID
                    : index === 1
                    ? Categories.PERSONAL_HISTORY.catID
                    : index === 2
                    ? Categories.PAST_MEDICAL_HISTORY.catID
                    : Categories.FAMILY_HISTORY.catID;

                return (
                  <div className="col mb-1" key={index}>
                    <div className="d-flex">
                      <label className="me-3" style={{ color: iconColor }}>
                        {category.label}
                      </label>
                      {/* <div className="float-end">
                        <AddNewDataForAll catID={categoryId} InputField={1} />
                      </div> */}
                    </div>

                    <CreatableSelect
                      closeMenuOnSelect={false}
                      key={index}
                      isMulti
                      options={
                        optionsData[categoryId]?.length > 0
                          ? optionsData[categoryId]
                          : DataConverte(category?.optionsData)
                      }
                      placeholder={category.placeholder}
                      onChange={(selectedOptions) => {
                        handleSelectChange(
                          selectedOptions,
                          category.fieldName,
                          categoryId
                        );
                      }}
                      value={addData[category.fieldName] || []}
                      onInputChange={(newValue) =>
                        handleInputChange(newValue, index, categoryId)
                      }
                      inputValue={inputValues[index] || ""}
                      isLoading={isLoading[index]}
                      loadingMessage={() => "Loading options..."}
                      components={{ DropdownIndicator, IndicatorSeparator }}
                      styles={customSelectStyles}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default History;
