import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";
import TextCardItemView from "../landing/TextCardItemView";
import {
  SESSION_ID,
  BASE_URL,
  PRODUCT_LIST,
  ACCESS_TOKEN,
  SEARCH_API,
  DEBUG_LOG_ENABLED,
  USER_ROLE,
  ROLE,
  CARD_VIEW,
  TOP_PRODUCT,
  you_tube_view,
  article_view_01,
  CARD_VIEW_WITH_TEXT,
  FULL_BODY_CHEAKUP,
  IMG_BASE_URL,
} from "../Constant";
import swal from "sweetalert";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import ProductItemCart from "../landing/ProductItemCart";
import YouTubecard from "../landing/YouTubecard";
import FeatureProduct from "../landing/FeatureProduct";
import ErrorImages from "../Images/error.png"
import FullBodyView from "../landing/fullBodyCheckp/FullBodyView";

function ItemsViewMore() {
  const myDataview = localStorage.getItem("myDataview");
  let [showEdit, setShowEdit] = useState(false);
  const [viewType, setViewType] = useState({ grid: true });
  const [view, setView] = useState("");
  let [ItemShow, setItemShow] = useState(true);

  function changeViewType() {
    setViewType({
      grid: !viewType.grid,
    });
  }
  const navigate = useNavigate();
  const { catId } = useParams();

  const [catItemList, setCatItemList] = useState([]);

  const getCategoryLists = async () => {
    try {
      const categoryListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: catId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + PRODUCT_LIST,
        categoryListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      setCatItemList(response.data.respData.respMsg.catItemList);
      if (DEBUG_LOG_ENABLED) {
        console.log("catItemList : " + catItemList);
      }
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };
  useEffect(() => {


    const myDataview = localStorage.getItem("myDataview");
    setView(myDataview);

    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
    const myData = localStorage.getItem("myData");
    const itemList = JSON.parse(myData);

    setCatItemList(itemList.items);
    setDisocunt(itemList);
  }, []);

  let [Discount, setDisocunt] = useState()
  const Search = async (value) => {
    try {
      const searchApi = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            title: value,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await SearchApi.create(searchApi);

      let response = await axios.post(BASE_URL + SEARCH_API, searchApi, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      const filteredSearchList =
        response.data.respData.respMsg.catItemList.filter((item) => {
          return (
            item.viewType !== "scroll_01" &&
            item.viewType !== "article_view_01" &&
            item.viewType !== "you_tube_view_01" &&
            item.viewType !== "you_tube_view_01" &&
            item.viewType !== "you_tube_view" &&
            item.viewType !== "CustomerReview"

          );
        });

      // setCatItemList(response.data.respData.respMsg.catItemList);
      setCatItemList(filteredSearchList);
      if (response.data.respData.respMsg.catItemList.length === 0) {
        setItemShow(false);
      } else {
        setItemShow(true);
      }
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      const tempList = catItemList.filter((item) => {
        return item.title.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      {
        tempList.length === 0 ? setItemShow(false) : setCatItemList(tempList);
      }


      // Global search enable


      // Search(value);
    } else {
      const myData = localStorage.getItem("myData");
      const itemList = JSON.parse(myData);
      setItemShow(true);
      setCatItemList(itemList.items);
    }
  };



  return (

    <div className="container  py-4 px-xl-5 " style={{ marginTop: 90, minHeight: "700px" }}>
      <div className="row mb-4 mt-lg-3">
        <div className="col ">
          <div className="row mb-3">
            <div className="col-9 d-flex mx-auto" style={{ width: "80%" }}>
              <div className="input-group">
                <input
                  className="form-control"
                  // value={searchApi}
                  onChange={(e) => handleChange(e)}
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  size="32"
                />
                <button type="button" className="btn btn-primary ">
                  <FontAwesomeIcon icon={["fas", "search"]} />
                </button>
              </div>
              {/* <div>{view}</div> */}
            </div>
          </div>
          {ItemShow ? (
            <div
              className={
                view === CARD_VIEW || view === you_tube_view
                  ? "row row-cols-1 row-cols-md-3 row-cols-lg-3 g-3 "
                  : "row row-cols-1 row-cols-md-2 row-cols-lg-4 g-3"
              }
            >
              {catItemList.map((it, index) => {
                if (viewType.grid) {
                  return view === CARD_VIEW ? (
                    <ProductItemCart key={it.itemId} product={it} showEdit />
                  ) : view === you_tube_view ? (
                    <div class="card rounded-4 p-1 border border-dark ">
                      {/* <iframe
                          title="{it?.iconURL}"
                          height="200"
                          src={it?.iconURL}
                          allowFullScreen
                          className="rounded-4 d-block"
                          loading="lazy"
                        ></iframe> */}
                      <Link
                        to={"/productdetail/" + it?.itemId}
                        className="text-decoration-none   text-dark "
                        onClick={() =>
                          localStorage.setItem(
                            "myProduct",
                            JSON.stringify(it)
                          )
                        }
                      >
                        <div
                          className="card  text-white rounded-4"
                          style={{ maxWidth: 600 }}
                        >
                          {/* <img
                              src={IMG_BASE_URL+  it?.emailId}
                              className="rounded-4 d-block "
                              alt="Stony Beach"
                              style={{ width: "100%", height: 200 }}
                            /> */}
                          <iframe
                            title="{it?.iconURL}"
                            height="200"
                            src={it?.iconURL}
                            allowFullScreen
                            className="rounded-4 d-block"
                            loading="lazy"
                          ></iframe>
                          <div
                            className="card-img-overlay rounded-4"
                          // style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                          >
                            {/* <FontAwesomeIcon
                                icon="fa-regular fa-circle-play"
                                style={{
                                  width: 50,
                                  height: 50,
                                  alignSelf: "center",
                                }}
                                className="rounded-4 position-absolute top-50 start-50 translate-middle"
                              /> */}
                          </div>
                        </div>
                        <div class="card-body ">
                          <h5 class="card-title">{it?.title}</h5>
                          <p class="card-text">{it?.subTitle}</p>
                          {showEdit ? (
                            <Link
                              to={"/productdetail/" + it?.itemId}
                              onClick={() =>
                                localStorage.setItem(
                                  "myProduct",
                                  JSON.stringify(it)
                                )
                              }
                            >
                              <button class="btn btn-primary ">
                                Video Edit
                              </button>
                            </Link>
                          ) : null}
                        </div>
                      </Link>
                    </div>
                  ) : view === article_view_01 ? (
                    <FeatureProduct
                      key={it.itemId}
                      product={it}
                      showEdit={showEdit}
                    />
                  ) : view === CARD_VIEW_WITH_TEXT ||
                    view === "PanchakarmaUnit" ? (
                    <TextCardItemView
                      key={it.itemId}
                      product={it}
                      showEdit={showEdit}
                      discountPrice={Discount.discountPrice}
                    />
                  ) : view === FULL_BODY_CHEAKUP ? (
                    <FullBodyView
                      key={it.itemId}
                      product={it}
                      showEdit={showEdit}
                    />
                  ) : null;
                }
              })}
            </div>
          ) : (
            <div class="d-flex justify-content-center">
              <div className=" text-center">
                <img src={ErrorImages} style={{ width: "18rem" }} />
                <h5 className="card-title">

                  Item not found, please try another way
                </h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>

  );
}

export default ItemsViewMore;
