import axios from "axios";
import { addTemplateByCatIdAndTempId, BASE_URL, SESSION_ID, ACCESS_TOKEN } from "../../Constant";
import swal from "sweetalert";
import { fetchTemplateNames } from "./FetchTemplateApi";


export const saveTemplate = async (catID, templateName, data, fieldName, dispatch) => {
    // console.log(data, templateName, " for saving template", fieldName, " kd ", catID)
    try {
        const formattedData = typeof data === 'string' ? data.toUpperCase() : data;
        const payload = {
            pubInfo: {
                sessionId: localStorage.getItem(SESSION_ID),
            },
            request: {
                busiParams: {
                    categoryId: catID,
                    templateName: templateName.toUpperCase(),
                    //  [fieldName]: data,
                    [fieldName]: formattedData,
                },
                isEncrypt: false,
                transactionId: "897987987989",

            },
        };


        // console.log(payload, " payload")
        const response = await axios.post(
            `${BASE_URL}${addTemplateByCatIdAndTempId}`,

            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
                },
            }
        );

        // Handle success
        // swal("Success", "Template saved successfully.");
        // console.log(response, ' comfing form tepmsave')
        dispatch(fetchTemplateNames());
        return response?.data;
    } catch (error) {
        console.log(error, " errr")
        return false
    }
};