import React, { useEffect, useState } from "react";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  ERROR_MSG,
  GETALLMASTERVISITPADSBUTD,
  NO_HISTORY_FOUND,
  update_User_Token,
} from "../Constant";

import { useDispatch } from "react-redux";
import { updateField } from "./ReduxApi/ReduxApi";
import axios from "axios";
import apiHelper from "./ApiHelpar/apiHelper";
import useDebounce from "./useDebounce/useDebounce";
import UserSearchDropdown from "./CustomDropdown/UserSearchDropdown";
import { useNavigate } from "react-router-dom";
import TokenModal from "../booking/TokenModal";
import moment from "moment";
import ToastFist from "./Toast/ToastFist";
import {
  GET_DATA_BYNAME_MOBILE,
  getDataByNameOrMobileNo,
} from "./visitConstant/ApiConstant";

import { iconColor, headerText } from "./visitConstant/repotOtherConstant";
import { setLastHistory } from "./ReduxApi/VisitReducerCount";

const ReportHeader = ({ receivedDatas }) => {

  // const [receivedData, setReceiveData] = useState(receivedDatas);
  const [receivedData, setReceiveData] = useState(receivedDatas);

  useEffect(() => {
    // Update the state whenever the receivedDatas prop changes
    setReceiveData(receivedDatas);
  }, [receivedDatas]); // Dependency array includes receivedDatas

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("dd", receivedData)
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [editToken, setEditToken] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const onSelect = async (value) => {


    // console.log(value, "value----->>>")
    const data = {
      tokenId: value?.tokenId,
    };

    const response = await apiHelper(GETALLMASTERVISITPADSBUTD, data);

    // console.log(response, " dd>Foo")
    const resData = response == false ? false : response.respData.respMsg;
    // console.log("sss", resData)
    if (resData == "done") {
      setToastMsg(NO_HISTORY_FOUND);
      setColorStatus(3);
      setShowToast(true);
    } else if (resData?.length == 0) {
      setToastMsg(NO_HISTORY_FOUND);
      setColorStatus(3);
      setShowToast(true);
    } else if (resData == false) {
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
      setShowToast(true);
    } else if (resData?.length > 0) {
      dispatch(setLastHistory(resData[0]));
      navigate("/reportMain", { state: value });

    } else {
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
      setShowToast(true);
    }
    setInputValue("");
    setOptions([]);
  };

  useEffect(() => {
    // Dispatch individual field updates
    dispatch(updateField("customerName", receivedData?.name));
    dispatch(updateField("date", receivedData?.date));
    dispatch(updateField("phoneNo", Number(receivedData?.mobileNo)));
    dispatch(updateField("tokenId", Number(receivedData?.tokenId)));
    dispatch(updateField("userId", Number(localStorage.getItem(CUSTOMER_ID))));
  }, [receivedData]);

  // Use the debounced input outside the event handler

  const debouncedInput = useDebounce(inputValue, 200);
  // Effect to handle debounced input change
  useEffect(() => {
    if (debouncedInput?.length > 1) {
      if (!isNaN(debouncedInput) && debouncedInput.trim() !== "") {
        if (debouncedInput?.length < 6) {
          fetchData({ tokenId: debouncedInput });
        } else {
          fetchData({ mobileNo: debouncedInput });
        }
      } else {
        fetchData({ name: debouncedInput }); // Call fetchData with name
      }
    }
  }, [debouncedInput]); // Trigger this effect whenever the debounced input changes

  let ageGenderInfo = "";

  if (receivedData) {
    const age = receivedData.age ? `${receivedData.age} Y` : "";
    const gender = receivedData.gender
      ? receivedData?.gender == "M"
        ? "Male"
        : receivedData?.gender == "F"
          ? "Female"
          : "Other"
      : "";

    // Create the parentheses content based on availability
    if (age && gender) {
      ageGenderInfo = `(${age}, ${gender})`;
    } else if (age) {
      ageGenderInfo = `(${age})`;
    } else if (gender) {
      ageGenderInfo = `(${gender})`;
    }
  }

  const fetchData = async (SerchingValue) => {
    try {
      const endpoint = GET_DATA_BYNAME_MOBILE;
      const data = SerchingValue;
      const response = await apiHelper(endpoint, data);
      // console.log("******",response)
      if (response.respData.respMsg == "done") {
        setOptions([]);
      } else {
        setOptions(response.respData.respMsg);

        //  console.log(    "****",response.respData)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearchValueChange = (value) => {
    setInputValue(value.toUpperCase());
    if (value?.length == 0) {
      setOptions([]);
    }
  };

  const handleUpdateToken = async (tokenData) => {
    // console.log(tokenData, "Save tg");
    try {
      const busiParams = {
        ...tokenData,
        name: tokenData?.name?.trim(),
        date: tokenData.date.length
          ? tokenData.date
          : moment(new Date()).format("YYYY-MM-DD"),
      };

      const response = await apiHelper(update_User_Token, busiParams);
      // console.log(response, " sss")
      if (
        response.respData.message == "UserToken has been updated successfully"
      ) {
        fetchTokens(tokenData);
      } else {
        setToastMsg("Not updated");
        setColorStatus(0);
        setShowToast(true);
      }
    } catch (error) {
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  const fetchTokens = async (tokenData) => {
    try {
      const busiParams = {
        tokenId: tokenData.tokenId,
      };

      const response = await apiHelper(getDataByNameOrMobileNo, busiParams);
      //  console.log(response.respData.respMsg[0]);
      if (response.respData.respMsg.length > 0) {
        setReceiveData(response?.respData?.respMsg[0]);
        setToastMsg("Token updated successfully");
        setColorStatus(1);
        setShowToast(true);
      } else {
        setColorStatus(0);
        setToastMsg("Not updated");
        setShowToast(true);
      }
    } catch (error) {
      // swal("Error", error.message, "error");
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(ERROR_MSG);
    }
  };

  // navigate("/reportMain", { state: data });

  return (
    <>
      {editToken && (
        <header>
          <TokenModal
            token={receivedData}
            onUpdate={handleUpdateToken}
            onClose={() => setEditToken(null)}
          />
        </header>

      )}
      <nav class="navbar bg-white ">
        <div class="container-fluid">
          {/* <a class="navbar-brand">Navbar</a> */}
          <div onClick={() => setEditToken(true)} style={{ cursor: "pointer" }}>
            <span className="fw-bold" style={{ color: headerText }}>
              {receivedData?.name} {ageGenderInfo}
            </span>
            <br />
            <span style={{ color: "#8250ff" }}>
              {" "}
              {receivedData?.tokenId}
            </span> | {receivedData?.mobileNo}
          </div>
          <form class="d-flex" role="search">
            {/* <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={inputValue}
             
              onChange={handleInputChange}
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button> */}

            <UserSearchDropdown
              options={options}
              handleInputChange={handleSearchValueChange}
              onSelect={onSelect}
              value={inputValue}
              rowIndex={1}
              loading={loading}
              searchTerm={inputValue}
            />
          </form>
        </div>

      </nav>

      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />

    </>
  );
};
export default ReportHeader;
