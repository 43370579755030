import React, { useCallback, useEffect, useState } from "react";
import {
  ArrayuniqueData,
  convertOnlyValue,
  labServerFormate,
  DataConverte,
  DataConvertecheck,
  removeMatchingItems,
} from "../../DataConverte/DataConverte";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";
import Select from "react-select";
import Categories from "../../visitConstant/Categories";
import CreatableSelect from "react-select/creatable";
import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from "../../ui/CSS/customStyles";
import { handleNewOptions } from "../../Utils/handleNewOptions";
import DropdownSearchApl from "../../ApiHelpar/DropdownSearchApl";
import { DROPDOWNSARCHYNAME } from "../../visitConstant/ApiConstant";
import ToastFist from "../../Toast/ToastFist";

const LabTestsandImaging = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const [LabTests, setLabTests] = useState([]);
  const dispatch = useDispatch();
  const clearAllData = useSelector((state) => state?.ClearAllData?.isLoggedIn);
  const [TempletDataReserve, setTempletDataReserve] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [searchTerm, setSearchTerm] = useState([]);


  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");


  useEffect(() => {
    setSearchTerm(visitPadData?.testsAndImagings);
  }, [visitPadData?.testsAndImagings]);

  const { DropdownServerData, DropdownIsLoading, DropdownError } =
    DropdownSearchApl(
      DROPDOWNSARCHYNAME,
      inputValue,
      Categories?.LAB_TESTS_AND_IMAGING?.catID
    );

  useEffect(() => {
    setSearchTerm(DropdownServerData);
    setIsLoading(DropdownIsLoading);
  }, [DropdownServerData, DropdownIsLoading]);

  useEffect(() => {
    setTempletDataReserve(
      patientData?.labTestsAndImagingData
    );
    // console.log(patientData?.labTestsAndImagingData, "lllbb")
  }, [patientData]);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setLabTests([]);
    setTempletDataReserve([]);
    dispatch(updateField("labTestsAndImagingData", ""));
  }, [dispatch]);

  const LabestsandImagingDataSelectChange = async (
    selectedOptions,
    actionMeta
  ) => {
    try {
      const updatedOptions = await handleNewOptions(
        selectedOptions,
        Categories?.LAB_TESTS_AND_IMAGING.catID, showToast,
        setShowToast
        , setToastMsg
        , setColorStatus
      );
      // console.log("Updated Options:", updatedOptions);
      if (actionMeta.action === "clear") {
        handleClearData();
      }
      setLabTests(removeMatchingItems(updatedOptions));
      // console.log(updatedOptions);

      const serverData = labServerFormate(
        "labTestsAndImaging",
        updatedOptions
      );


      dispatch(updateField("labTestsAndImagingData", serverData));
      // console.log("labTestsAndImagingData", serverData);
    } catch (error) {
      // console.log(error);
    }
  };

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  //// data update

  useEffect(() => {
    // if (TempletDataReserve) {
    //   const localData = DataConvertecheck(TempletDataReserve);
    //   console.log("localData??", localData);
    //   console.log("localData?", TempletDataReserve);
    //   setLabTests(localData);
    //   const serverData = labServerFormate(
    //     "labTestsAndImaging",
    //     localData
    //   );
    //   dispatch(updateField("labTestsAndImagingData", serverData));
    //   // console.log(servetData);
    // }

    //afyer 
    if (TempletDataReserve) {
      const localData = DataConvertecheck(TempletDataReserve?.map((item) => item?.labTestsAndImaging));
      // console.log("localData??", localData);
      // console.log("localData?", TempletDataReserve);
      setLabTests(localData);
      const serverData = labServerFormate(
        "labTestsAndImaging",
        localData
      );
      dispatch(updateField("labTestsAndImagingData", serverData));
      // console.log(servetData);
    }
  }, [TempletDataReserve]);

  const TempletDataTransfer = (TempletData) => {
    // console.log("TempletData LAB", TempletData);

    const reserveData = Array.isArray(TempletDataReserve)
      ? TempletDataReserve
      : [];

    const formattedData =
      TempletData?.labTestsAndImaging?.map((item) => ({
        value: item?.id,
        label: item?.name,
      })) || [];

    const combinedData = [...LabTests, ...reserveData, ...formattedData];

    // console.log(
    //   "RESERV: ",
    //   reserveData,
    //   +" Format: " + formattedData + " COMBINE: " + combinedData
    // );
    const uniqueData = Array.from(
      new Map(combinedData.map((item) => [item.value, item])).values()
    );

    const datacinvar = ArrayuniqueData(DataConvertecheck(uniqueData));
    // console.log("uniqueData",ArrayuniqueData(   DataConvertecheck (uniqueData)));
    setTempletDataReserve(datacinvar);
    setLabTests(datacinvar);
  };

  return (
    <>
      <IconFunctionality
        name="Lab Tests and Imaging"
        iconSave
        iconRefresh
        iconErase
        iconPlus
        onClick={() => handleClearData()}
        catID={Categories?.LAB_TESTS_AND_IMAGING.catID}
        fieldName={Categories?.LAB_TESTS_AND_IMAGING.name}
        setTempData={TempletDataTransfer}
        InputField={1}
      />

      <div className="col-12   col-xxl-9 px-4">


        <CreatableSelect
          closeMenuOnSelect={false}
          // key={index}
          isMulti
          options={
            searchTerm?.length > 0
              ? DataConvertecheck(searchTerm)
              : DataConvertecheck(visitPadData?.testsAndImagings)
          }
          placeholder="Lab Tests and Imaging..."
          onChange={LabestsandImagingDataSelectChange}
          value={LabTests}
          onInputChange={(value) => setInputValue(value.toUpperCase())}
          inputValue={inputValue}
          // menuIsOpen={true} // Keep the menu open to show loader
          isLoading={isLoading} // Show loading state
          loadingMessage={() => "Loading options..."} // Custom loader message
          components={{ DropdownIndicator, IndicatorSeparator }}
          styles={customSelectStyles}
        />
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default LabTestsandImaging;
