


  
const Error = () => {
  return (
    <div>
      <h1 className="p-5 pb-0">
        404 Page not found
      </h1>
    </div>
  );
};
  
export default Error;
