import { Link } from "react-router-dom";
import  { useEffect, useState  } from "react";

import { useNavigate } from "react-router-dom";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ratings from "react-ratings-declarative";
import Placeholder from "../../src/default_img/placeholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from "html-react-parser";
import defaultimg from "../Images/doctor.png"
import {
  CALLLINK_LINK_ADD_TO_CART,
  ADD_TO_CART,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  DEBUG_LOG_ENABLED,
  ACCESS_TOKEN,
  IMG_BASE_URL,
  USER_ROLE,
  BLOGGER,
  ROLE,
} from "../Constant";
import FeatureP from "./FeatureP.css";
import { useSelector, useDispatch } from "react-redux";
import { increment } from "../actions";
import axios from "axios";
import unavailable from "../Images/unavailable.png"
import swal from "sweetalert";

const iconPath =
  "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
// function changeRating(newRating) {}

function FeatureProduct({ items, product, nama }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const isLogged = useSelector((state) => state.isLogged);

  const addToMycart = () => {
    // e.preventDefault();
    const id = localStorage.getItem(CUSTOMER_ID);
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (DEBUG_LOG_ENABLED) {
      console.log("CartService : id:" + id + "  token:" + token);
    }
    if (isLogged && id != null && id != "" && token != "") {
      addToCart();
    } else {
      swal("Please log in and try again ");
      navigate("/login");
    }
  };
  const addToCart = async () => {
    try {
      const itemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            id: 0,
            customerId: localStorage.getItem(CUSTOMER_ID),
            itemId: product.itemId,
            price: product.price,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : " + itemData);
      }

      let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      dispatch(increment(response.data.respData.respMsg.cartCount));
      //dispatch(increment(response.data.respData.respMsg.cartCount));

      swal(response.data.respData.message);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.setItem(CUSTOMER_ID, "");
        navigate("/login");
      } else {
        alert("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const bookingclick = () => {
    if (product?.callingURL === CALLLINK_LINK_ADD_TO_CART) {
      addToMycart();
    } else {
      localStorage.setItem("myProduct", JSON.stringify(product));

      navigate(
        //product.callingURL
        "/slotbooking" + "/" + product?.itemId
      );
    }
  };

  const productdata = () => {
    localStorage.setItem("myProduct", JSON.stringify(product));
    localStorage.setItem("itemslist", JSON.stringify(items));
    // localStorage.setItem("itemslistViewtype", nama);

   
  };



  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
  
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(BLOGGER) ||userRole?.includes(ROLE) ) {
      setShowEdit(true);
    }
  }, []);



  return (
    <div class="card-group me-2">
      <div
        class=" card  rounded-5  "
        //  style={{ minWidth: 330, minHeight: 200 }}
       // style={{ minWidth: 300, minHeight: 300 ,maxWidth:150}}
      >
        <Link
          style={{ textDecoration: "none" }}
          // to={
          //   product?.callingURL === CALLLINK_LINK_ADD_TO_CART
          //     ? ""
          //     : // ? "/productdetail/" + product?.itemId
          //       "/slotbooking" + "/" + product?.itemId
          // }

          // to={  ("/productdetail/" + product?.itemId)}

          to={
            product?.callingURL === "" || product?.callingURL === null
              ? "/productdetail/" + product?.itemId
              : product.callingURL + "/" + product?.itemId
          }
          onClick={() => productdata()}
        >
          <img
            className="card-img-top  rounded-4   "
              height="150"
            alt=""
            onError={(event) => {
              event.target.src = defaultimg;
            }}
            src={
              product.iconURL == "" || product.iconURL === null
                ? unavailable
                : IMG_BASE_URL + product.iconURL 
            }
          />

          <div class="card-body">
            <h5 className="card-title text-center text-dark text-truncate">
              {parse(`${product.title}`)}
            </h5>

            <div className="card-title text-center text-dark text-truncate">
              {/* <Ratings
              rating={4.5}
              widgetRatedColors="rgb(253, 204, 13)"
              // changeRating={changeRating}
              widgetSpacings="2px"
            >
              {Array.from({ length: 5 }, (_, i) => {
                return (
                  <Ratings.Widget
                    key={i}
                    widgetDimension="20px"
                    svgIconViewBox="0 0 19 20"
                    svgIconPath={iconPath}
                    widgetHoverColor="rgb(253, 204, 13)"
                  />
                );
              })}
            </Ratings> */}
            </div>
            <p className="card-text text-center text-muted">
              {product.titleID.length > 100
                ? product.titleID.substring(0, 100) + "......."
                : product.titleID}
            </p>
            {showEdit ? (
              <Link
                className="btn btn-outline-primary mt-3"
                to={"/addcatitem" + "/"}
                replace
                onClick={() =>
                  localStorage.setItem("catItem", JSON.stringify(product))
                }
                type="submit"
              >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
              </Link>
            ) : null}

          
            <div className="d-grid gap-2">
              {product?.price === "0" ? (
                ""
              ) : (
                <button className="btn btn-primary " onClick={bookingclick}>
                  {product?.callingURL === CALLLINK_LINK_ADD_TO_CART
                    ? "Add to cart"
                    : "Book now"}
                </button>
              )}
            </div>

            
          </div>
        </Link>
      </div>
    </div>
  );
}

export default FeatureProduct;
