export const MedicineHeader = [
  { title: "No.", w: "30px" },
  { title: "Name", w: "260px" },
  { title: "Content", w: "140px" },
  { title: "Type", w: "80px" },
  { title: "Dosage", w: "80px" },
  { title: "Duration", w: "80px" },
  { title: "Unit", w: "80px" },
  { title: "Frequency", w: "100px" },
  { title: "When", w: "100px" },
  { title: "Notes", w: "100px" },
  { title: "Action", w: "80px" }
];

export const DiagnosisHeader = [
  { title: "No.", w: "60px" },
  { title: "Diagnosis", w: "300px" },
  { title: "Duration", w: "110px" },
  { title: "Date", w: "100px" },
  { title: "Action", w: "100px" },
];

export const InvestigationHeader = [
  { title: "No.", w: "60px" },
  { title: "Investigation", w: "300px" },
  { title: "Unit", w: "150px" },
  { title: "Today", w: "100px" },
  { title: "Action", w: "100px" },
];

export const Complaints = [
  { title: "No.", w: "60px" },
  { title: "Complaints", w: "300px" },
  { title: "Action", w: "100px" },
];

