const { color } = require("framer-motion");

module.exports = Object.freeze({
  DROPDOWNSARCHYNAME: "dropDownSearchByName",

  SERCH_TEMPLATE_BY_CAT_ID_AVD_TEMPNAME: "searchTemplateByCatIdAndTempName",

  ADDNEWDATAFORALL: "addNewDataForAll",
  GETALLTEMPLATENAME: "getAllTemplateName",
  GETTEMPLATEBYCATIDANDTEMID: "getTemplateByCatIdAndTempId",
  GET_ROOT_TEMPLATE: "getRootTemplate",
  GET_DATA_BYNAME_MOBILE: "getDataByNameOrMobileNo",

  ADDMEDICINE: "addMedicine",

  ADD_MEDICENE: "addMedicine",
  ITEM_STATUS_CHANGE: "itemStatusChange",
  SEARCH_ALL_MASTER_BY_PAGINATION: "searchAllMasterByPagination",
  getDataByNameOrMobileNo: "getDataByNameOrMobileNo",
  getLabTestDataByToken: "getLabTestDataByToken",
  deleteLabTestRecord: "deleteLabTestRecord",
  saveLabTestRecord: "saveLabTestRecord",
  uploadFileToDrive: "uploadFileToDrive",
  getLabTestTokensByDateRange: "getLabTestTokensByDateRange",
  // Constant for Google Drive URLs
  GOOGLE_DRIVE_PREVIEW_URL: 'https://drive.google.com/file/d/{fileId}/view',
  GOOGLE_DRIVE_DOWNLOAD_URL: 'https://drive.google.com/uc?export=download&id={fileId}',


  ///  color
  visitBackgroundColor: "#f5f5f5", // Background color for visit
  visitPadBackgroundSecondary: "#ffffff", // Secondary background color for visit pad
  allVisitPadBackground: "#d0d5dd", // Background color for all visit pads
  tableBackgroundHeader: "#eaecf5", // Background color for table header
  iconColor: "#667085", // Color for icons
});
