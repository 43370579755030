
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";


import moment from "moment";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  getCategoryItemByItemId,
  you_tube_view01,
  GENERATEUSERTOKEN,
  Tokan_Type_RequestCall,
  Tokan_Status_In_Queue,
  Request_Call_Msg,
  ROLE,
  USER_ROLE,
} from "../Constant";
import { useSelector, useDispatch } from "react-redux";
// import { increment, decrement, handleLoggin } from "../../actions";
import { useNavigate } from "react-router-dom";


import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";

import { useParams } from "react-router-dom";
import swal from "sweetalert";

function RequestCall({comment}) {
  let [showEdit, setShowEdit] = useState(false);

  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    address: "",
    date: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const savaUser = (e) => {
    e.preventDefault();
    if (user.name === "" || user.mobileNo === "") {
      // props.showAlert("Please entery username and mobail no", "success");
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Danger:"
        >
          <use class="#exclamation-triangle-fill" />
        </svg>
        <div>Please entery username and mobail no., success</div>
      </div>;

      //  alert("Please entery username and mobail no ");
      //  showAlert("Please entery username and mobail no", "success");
    } else {
      userData(user.name, user.mobileNo);
    }
  };
  const userData = async (name, mobileNo) => {
    try {
      const userData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: name,
            date: "" + moment(new Date()).format("YYYY-MM-DD"),
            mobileNo: mobileNo,
            address: "",
            type: Tokan_Type_RequestCall,
            status: Tokan_Status_In_Queue,
            comment: comment??"",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + GENERATEUSERTOKEN, userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });
      swal(Request_Call_Msg);
    } catch (err) {
      swal(err);
      console.log(err);
    }
  };
  return (
    <>
      <div className="card px-2 ">
        {/* <FontAwesomeIcon icon="fa-solid fa-phone" />
          <h5 className="text-dark text-center text-md-start">
            Request Call Back
          </h5> */}

        <div class="row bg-primary py-3 rounded-3 gap-3">
          <div class=" ps-3 col-1">
            <FontAwesomeIcon
              icon="fa-solid fa-phone"
              fade
              style={{ color: "white", width: 25, height: 25 }}
            />
          </div>
          <div class="col-9" style={{ color: "white", fontSize: 20 }}>
            Request Call Back
          </div>
        </div>

        <form className="  justify-content-center justify-content-md-start mb-3">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Enter name"
              value={user.name}
              onChange={(e) => handleChange(e)}
              className="form-control border border-primary my-3"
              required
            />
            <input
              name="mobileNo"
              onChange={(e) => handleChange(e)}
              value={user.mobileNo}
              className="form-control border border-primary my-3"
              placeholder=" Mobail No"
              required
              aria-required="true"
              type="tel"
              size="10"
              minlength="10"
              maxlength="12"
            />
            <button
              className="btn form-control btn-primary  text-light px-5"
              type="submit"
              onClick={(e) => savaUser(e)}
            >
              Request call
            </button>
            <br></br>
            {showEdit ? (
              <Link
                className="btn form-control btn-primary  text-light mt-2 px-5"
                to="/tokenPage"
              >
                View calls
              </Link>
            ) : null}
          </div>
        </form>
      </div>
    </>
  );
}

export default RequestCall;
