import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextCardItemView from "./TextCardItemView";
import { Link } from "react-router-dom";

function Headline({ item, navigate, showEdit, nama }) {
  const data = () => {
    //  navigate("/itemsviewmore");
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    <div class="d-flex bd-highlight mt-4 ">
      <div class="container overflow-hidden">
        <div class="row ">
          <div class="col">
            <div class="h3  bg-light">{item?.title}</div>
          </div>
        </div>
      </div>
      {/* <div class="me-auto p-2 bd-highlight">
        <h3 className="d-inline-block text-truncate">{item?.title}</h3>
      </div> */}

      <div>
        <Link
          to={"/itemsviewmore"}
          className="btn  btn-light   text-primary text-truncate"
          style={{
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            // display: "-webkit-box",
            // lineClamp: 2,
            // WebkitLineClamp: 2,
            // WebkitBoxOrient: "vertical",
            fontWeight: 700,
          }}
          onClick={() => data()}
        >
          See all
          <FontAwesomeIcon
            icon="fa-solid fa-greater-than  "
            className="ps-2"
            fade
          />
        </Link>
        {showEdit ? (
          <Link
            to={"/addcat"}
            className="btn btn-outline-primary "
            onClick={() =>
              navigate(localStorage.setItem("catData", JSON.stringify(item)))
            }
            type="submit"
          >
            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            Edit
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default Headline;
