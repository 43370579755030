import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CustomTooltip from "../../CustomTooltip";
import {
  convertCmedicinToServerFormat,
  ConvertObj,
  DataConverte,
  filterNewOptions,
  InvestServerConverter,
  removeDuplicateIds,
} from "../../DataConverte/DataConverte";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";
import TextModal from "../../modal/TestMOdal";
import Categories from "../../visitConstant/Categories";
import ToastFist from "../../Toast/ToastFist";
import CustomDropdown from "../../CustomDropdown/CustomDropdown";
import { addItemToServer } from "../../Utils/addItemToServer";
import useDebounce from "../../useDebounce/useDebounce";
import { DROPDOWNSARCHYNAME } from "../../visitConstant/ApiConstant";
import apiHelper from "../../ApiHelpar/apiHelper";
import "../../ui/CSS/focusinput.css";
import PlusButton from "../../ui/PlusButton";
import moment from "moment";
import { ERROR_MSG } from "../../../Constant";
// Function to flatten the investigations data
const flattenInvestigations = (data) => {
  return data.reduce((acc, category) => {
    const tests = Object.values(category)[0]; // Extract the array of tests
    return [
      ...acc,
      ...tests.map(
        (test) => test
        // { value: test.id, label: test.name }
      ),
    ];
  }, []);
};

const Investigations = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = useState("");
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const [searchTerms, setSearchTerms] = useState([]);
  const defaultOptions = Array.isArray(visitPadData?.investigations) ? visitPadData?.investigations[0]?.Hematology : []
  const [options, setOptions] = useState(defaultOptions);
  const currentDate = moment().format('YYYY-MM-DD');
  const [selectedDates, SetSelctedDate] = useState([{ date: currentDate }]);
  const [isLoading, setIsLoading] = useState(false);

  const [rows, setRows] = useState([
    {
      id: "",
      testsOrInvestigations: "",
      unit: "",
      type: "",
      investigationsDates: [
        {
          investigationsDate: currentDate,
          value: "",
        }
      ],
    },
  ]);

  const handleInputChanges = (value, index) => {
    const newSearchTerms = [...searchTerms];
    newSearchTerms[index] = value;
    setSearchTerms(newSearchTerms);
    setSearchKey(newSearchTerms[index] = value)

  };

  const onSelect = (value, index, newEntry = false) => {
    // console.log(value, " value fromtemp")
    const unitsName = value?.units?.name;
    const isDuplicate = rows.some(
      (row) => row?.testsOrInvestigations === value?.name
    );

    if (isDuplicate) {
      setShowToast(true);
      setToastMsg("Item has already been selected.")
      setColorStatus(3)
      setSearchKey("");
      return;
    }

    const newRows = [...rows];
    const currentRow = newRows[index];
    const existingDates = currentRow?.investigationsDates?.length > 0
      ? currentRow.investigationsDates
      : [{ investigationsDate: currentDate, value: "" }];

    if (newEntry) {
      newRows[index] = {
        ...currentRow,
        id: value?.value,
        testsOrInvestigations: value?.label,
        type: { id: "", name: "" },
        unit: { id: "", name: "" },
        investigationsDates: existingDates,
      };

    } else {
      newRows[index] = {
        ...currentRow,
        id: value?.id,
        testsOrInvestigations: value?.name,
        type: { id: value?.type?.id, name: value?.type?.name },
        unit: { id: value?.units?.id, name: unitsName ? unitsName : "" },
        investigationsDates: existingDates,
      };
    }

    setRows(newRows);
    setFocusedRowIndex(null)
    // const newOptions = filterNewOptions(newRows, defaultOptions)
    setOptions(defaultOptions);


    const formatedData = InvestServerConverter(newRows);
    dispatch(updateField("investigationsData", formatedData));
    const newSearchTerms = [...searchTerms];
    newSearchTerms[index] = "";
    if (index === rows.length - 1) {
      newSearchTerms.push("");
    }
    // Update the search terms state
    setSearchTerms(newSearchTerms);
    setSearchKey("");

    if (index == rows.length - 1) {
      addRow();
    }
    // console.log("server data ", formatedData);
  };

  const handleValueChange = (rowIndex, fieldType, dateIndex = null, value) => {
    const newRows = [...rows];


    if (fieldType === "investigationsDates" && dateIndex !== null) {
      if (newRows[rowIndex]?.investigationsDates?.[dateIndex]) {
        newRows[rowIndex].investigationsDates[dateIndex].value = value;
      }
    }

    setRows(newRows);

    if (rowIndex === rows.length - 1) {
      addRow();
    }

    const formatedData = InvestServerConverter(newRows);

    dispatch(updateField("investigationsData", formatedData));
  };

  const handleSlectDate = (sdate) => {
    if (sdate) {
      const isDateDuplicate = selectedDates.some((dateObj) => dateObj.date === sdate);

      if (isDateDuplicate) {
        return;
      }

      const newSelectedDates = [
        ...selectedDates,
        { date: sdate },
      ].sort((a, b) => new Date(a.date) - new Date(b.date));

      SetSelctedDate(newSelectedDates);

      const newRows = rows.map((row) => {
        const isDateInInvestigations = row.investigationsDates.some(
          (investigation) => investigation.investigationsDate === sdate
        );

        if (isDateInInvestigations) {
          return row;
        }

        const updatedInvestigationsDates = [
          ...(Array.isArray(row.investigationsDates) ? row.investigationsDates : []),
          {
            investigationsDate: sdate,
            value: "",
          },
        ].sort((a, b) => new Date(a.investigationsDate) - new Date(b.investigationsDate));

        return {
          ...row,
          investigationsDates: updatedInvestigationsDates,
        };

      });

      setRows(newRows);

      const formatedData = InvestServerConverter(newRows);
      dispatch(updateField("investigationsData", formatedData));
    }
  };


  const removedate = (sdate) => {
    const updatedDates = selectedDates.filter(
      (dateObj) => dateObj.date !== sdate
    );
    SetSelctedDate(updatedDates);
    const newRows = rows.map((row) => ({
      ...row,
      investigationsDates: row.investigationsDates
        ? row.investigationsDates.filter(
          (investigation) => investigation.investigationsDate !== sdate
        )
        : [],
    }));

    setRows(newRows);
    const formatedData = InvestServerConverter(newRows);
    dispatch(updateField("investigationsData", formatedData));

    // console.log("Rows after date removal:", newRows);
  };

  // console.log("selected data", rows)
  const addRow = () => {
    const existingInvestigationsDates =
      rows.length > 0 && rows[0].investigationsDates?.length > 0
        ? rows[0].investigationsDates.map((date) => ({
          investigationsDate: date.investigationsDate,
          value: "",
        }))
        : [];

    setRows((prevRows) => [
      ...prevRows,
      {
        id: "",
        testsOrInvestigations: "",
        unit: "",
        investigationsDates: existingInvestigationsDates,
      },
    ]);
    // setSearchKey("");
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    const newSearchTerms = [...searchTerms];
    newSearchTerms.splice(index, 1);
    setRows(newRows);
    const formatedData = InvestServerConverter(newRows);
    dispatch(updateField("investigationsData", formatedData));
    setSearchTerms(newSearchTerms);
    setSearchKey("");
  };


  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  const handleClearData = useCallback(() => {
    setRows([
      {
        id: "",
        testsOrInvestigations: "",
        unit: "",
        type: "",
        investigationsDates: [{ investigationsDate: currentDate, value: "" }],
      },
    ]);
    SetSelctedDate([{ date: currentDate }]);
    setTempletDataReserve([]);

    dispatch(updateField("investigationsData"));
    setSearchKey("");
    setSearchTerms([])
  }, [dispatch]);

  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  const [TempletDataReserve, setTempletDataReserve] = useState([]);
  const InvestigationsData = patientData?.investigationsData;

  useEffect(() => {

    if (TempletDataReserve?.length > 0) {
      setOpen(true)
      const serveData = TempletDataReserve.map((item) => ({
        id: item?.testsOrInvestigations?.id,
        testsOrInvestigations: item?.testsOrInvestigations?.name,
        type: { id: item?.testsOrInvestigations?.type?.id, name: item?.testsOrInvestigations?.type?.name } || "",
        unit: { id: item?.testsOrInvestigations?.units?.id, name: item?.testsOrInvestigations?.units?.name } || "",
        investigationsDates: [
          {
            investigationsDate: currentDate,
            value: "",
          }
        ],
      }));

      setRows(serveData)
      SetSelctedDate([{ date: currentDate }]);
      addRow();
      const formatedData = InvestServerConverter(serveData);
      dispatch(updateField("investigationsData", formatedData));
    }
  }, [TempletDataReserve]);

  useEffect(() => {
    setTempletDataReserve(patientData?.investigationsData);
  }, [patientData]);

  const TempletDataTransfer = (TempletData) => {
    // Ensure TempletDataReserve is always an array
    // console.log(TempletData, '  temp')
    if (TempletData?.investigationsData?.length == 0) {
      setColorStatus(3)
      setToastMsg("Data not available")
      setShowToast(true);
    }
    const currentTempletData = Array.isArray(TempletDataReserve)
      ? TempletDataReserve
      : [];

    // Combine current data with new data and remove duplicates
    const uniqueData = removeDuplicateIds([
      ...TempletData?.investigationsData,
      ...currentTempletData,
    ]);
    setTempletDataReserve(uniqueData);
  };

  const handleAddNewItem = async (newItem, index) => {

    const newData = await addItemToServer(newItem, Categories?.INVESTIGATIONS?.catID);

    if (newData == false) {
      setSearchTerms("")
      setShowToast(true);
      setToastMsg(ERROR_MSG);
      setColorStatus(0);
    } else {
      onSelect(newData, index, true)
    }


  };

  const debouncedInput = useDebounce(searchKey, 100);


  useEffect(() => {
    // const newOptions = filterNewOptions(rows, defaultOptions)
    if (debouncedInput && debouncedInput.length >= 1) {
      const inputExistsInDefault = defaultOptions.some(option => {
        return option?.name?.toString()?.toLowerCase()?.startsWith(debouncedInput.toString().toLowerCase());
      }
      );
      // console.log(inputExistsInDefault, ' is searchablefor', debouncedInput)
      if (!inputExistsInDefault) {
        const CategoriesID = Categories?.INVESTIGATIONS?.catID;
        setIsLoading(true);
        fetchData(CategoriesID, debouncedInput);
      } else {
        setOptions(defaultOptions);
      }
    } else {
      setOptions(defaultOptions);
    }
  }, [debouncedInput]);

  const fetchData = async (CategoriesID, debouncedInput) => {


    if (debouncedInput) {
      try {
        const endpoint = DROPDOWNSARCHYNAME;
        const data = {
          categoryId: CategoriesID,
          name: debouncedInput,
        };
        const response = await apiHelper(endpoint, data);


        // const newOptions = filterNewOptions(rows, response.respData.respMsg)
        const newOptions = response.respData.respMsg;
        if (
          response?.respData?.respMsg ===
          "No specific work defined for category: INVESTIGATIONS"
        ) {
          setOptions([]);
        } else {
          if (newOptions.length > 0) {
            setOptions(newOptions);
          } else {
            setOptions([]);
          }
        }

        setIsLoading(false); // Show loader
      } catch (error) {
        // console.log("Error fetching data:", error);
        // alert("Error fetching data:");
        setIsLoading(false); // Show loader
      } finally {
        setIsLoading(false); // Hide loader
      }
    }
  };


  // console.log("TEPM DATA ", rows);

  return (
    <>
      <div className="d-flex justify-content-between ">
        <div className="d-flex">

          <IconFunctionality
            catID={Categories?.INVESTIGATIONS?.catID}
            fieldName={Categories?.INVESTIGATIONS?.name}
            name="Investigations"
            iconSave
            iconRefresh
            iconErase
            iconPlus
            onClick={handleClearData}
            setTempData={TempletDataTransfer}
          />

          <PlusButton setOpen={setOpen} open={open} />
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">

            {/* <div>
              <TextModal />
            </div> */}
          </div>
          {rows[0].testsOrInvestigations && (
            <div
              className=" rounded d-flex justify-content-center align-items-center text-white fw-medium  col-xxl-mt-2"
              style={{
                height: 32,
                paddingLeft: 4,
                marginLeft: 20,
                marginRight: 50,
              }}
            >
              {/* Add */}
              <input
                type="date"
                aria-label="Date"
                className="form-control float-end   "
                placeholder="Date"
                style={{
                  border: 0,
                  width: 44,
                  height: 32,
                  cursor: "pointer",
                  marginLeft: 4,
                }}
                onChange={(e) => handleSlectDate(e?.target?.value)}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          )}
        </div>

      </div>

      <div className="w-100">
        {/* <div className="d-flex justify-content-between">
          <div className="d-flex">
            
         
          </div>
          {rows[0].testsOrInvestigations && (
            <div
              className="bg-primary rounded d-flex justify-content-center align-items-center text-white fw-medium mt-2"
              style={{
                height: 32,
                paddingLeft: 4,
                marginLeft: 20,
                marginRight: 50,
              }}
            >
              Add
              <input
                type="date"
                aria-label="Date"
                className="form-control float-end"
                placeholder="Date"
                style={{
                  border: 0,
                  width: 44,
                  height: 32,
                  cursor: "pointer",
                  marginLeft: 4,
                }}
                onChange={(e) => handleSlectDate(e?.target?.value)}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          )}
        </div> */}
        <div className="col-12 col-xxl-8 mx-xxl-auto">
          {open && (
            <table className="table table-sm table-bordered ">
              <thead className="table-primary" style={{ fontSize: 13, }}>
                <tr>
                  <th scope="col" style={{ textAlign: "center", width: 30, verticalAlign: "middle" }}>
                    #
                  </th>
                  <th scope="col" style={{ paddingLeft: 14, }}>Tests/Investigations</th>
                  <th scope="col" style={{ width: 90, paddingLeft: 14, }}>
                    Units
                  </th>
                  <th scope="col" style={{ width: 90, paddingLeft: 14, }}>
                    Type
                  </th>
                  {selectedDates.length > 0 &&
                    selectedDates.map((dte, index) => (
                      <th scope="col" style={{ width: 200, }} key={index}>
                        <div className="d-flex justify-content-between aling-items-center">
                          <p
                            style={{
                              margin: 0,
                              paddingRight: 10,
                              paddingLeft: 10,


                            }}
                          >{dte?.date === currentDate ? "Today" : moment(dte?.date).format("DD-MMM-YYYY")}</p>
                          {dte?.date !== currentDate && (
                            <p
                              className="float-end hover-bg-light"
                              onClick={() => removedate(dte?.date)}
                              style={{
                                margin: 0,
                                paddingRight: 10,
                                paddingLeft: 10,
                                borderRadius: 20,
                                cursor: "pointer"
                              }}
                            >
                              x
                            </p>
                          )}
                        </div>
                      </th>
                    ))}
                  <th scope="col" style={{ width: 30 }}>
                  </th>
                </tr>
              </thead>
              <tbody>
                {rows?.map((row, index) => (
                  <tr style={{ fontSize: 13, alignContent: "center", verticalAlign: 'middle' }} key={row?.id}>
                    <th scope="row" style={{ alignContent: "center", textAlign: "center", }} >{index + 1} </th>
                    <td style={{ alignContent: "center" }} >
                      <CustomDropdown
                        options={options}
                        handleInputChange={(value, rowIndex) => handleInputChanges(value, rowIndex)}
                        onFocus={() => setFocusedRowIndex(index)}
                        onBlur={() => setFocusedRowIndex(null)}
                        onSelect={onSelect}
                        value={
                          focusedRowIndex === index
                            ? searchTerms[index]?.toUpperCase() || ""
                            : row?.testsOrInvestigations || searchTerms[index]?.toUpperCase()
                        }
                        rowIndex={index}
                        placeHolder="Add Test"
                        loading={isLoading}

                        searchTerm={
                          focusedRowIndex === index // Track specific search term for each row
                            ? searchTerms[index]?.toUpperCase() || ""
                            : ""
                        }
                        onAddNewItem={handleAddNewItem}
                      />
                    </td>

                    <td style={{ alignContent: "center" }} >
                      {row?.testsOrInvestigations &&
                        <input
                          type="text"
                          className="form-control border-0"
                          style={{ border: 0, width: 120, borderRadius: 0, fontSize: 13 }}
                          value={row?.unit?.name || ""}
                          name="units"
                          readOnly
                        />
                      }
                    </td>
                    <td style={{ alignContent: "center" }} >
                      {row?.testsOrInvestigations &&
                        <input
                          type="text"
                          className="form-control border-0"
                          style={{ border: 0, width: 120, borderRadius: 0, fontSize: 13 }}
                          value={row?.type?.name || ""}
                          name="type"
                          readOnly
                        />
                      }
                    </td>
                    {Array.isArray(row?.investigationsDates) &&
                      row?.investigationsDates.map((dte, dateIndex) => (
                        <td style={{ alignContent: "center" }} >
                          {row?.testsOrInvestigations &&
                            <input
                              type="text"
                              className="form-control"
                              style={{ border: 0, borderRadius: 0, fontSize: 13 }}
                              value={dte?.value}
                              name="value"
                              onChange={(e) =>
                                handleValueChange(
                                  index,
                                  "investigationsDates",
                                  dateIndex,
                                  e.target.value
                                )
                              }
                            />
                          }
                        </td>
                      ))}


                    <td
                      className="text-center"
                      onClick={() =>
                        index !== rows.length - 1 && removeRow(index)
                      }
                    >
                      {index !== rows.length - 1 && (
                        <CustomTooltip
                          icon="fa-solid fa-trash-can"
                          tooltipText="Delete"
                          onClick={() => removeRow(index)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default Investigations;
