// import React, { useState, useEffect, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { updateField } from "../ReduxApi/ReduxApi";
// import IconFunctionality from "../IconFunctionality";
// import { iconColor } from "../visitConstant/ApiConstant";

// const Vitals = () => {
//   const dispatch = useDispatch();
//   const [ClearData, setClearData] = useState("");
//   const [values, setValues] = React.useState({
//     bloodPressure: "",
//     pulse: null,
//     height: null,
//     weight: null,
//     temperature: null,
//     bmi: null,
//     waistHip: null,
//     spo2: null,
//     fetalHeartSound: "",
//     fetalMovement: "",
//     hgt: null,
//     lastMenstrualPeriod: "",
//     estimatedDueDate: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     const updatedValues = {
//       ...values,
//       [name]: value,
//     };

//     setValues(updatedValues);
//     // console.log(updatedValues);

//     dispatch(updateField("vitalsData", updatedValues));

//     if (name === "weight" || name === "height") {
//       calculateBMI(updatedValues);
//     }
//   };

//   const calculateBMI = (updatedValues) => {
//     const { weight, height } = updatedValues;

//     if (weight && height) {
//       const bmi = (weight / (height / 100) ** 2).toFixed(2);

//       const updatedWithBMI = { ...updatedValues, bmi };

//       setValues(updatedWithBMI);
//       dispatch(updateField("vitalsData", updatedWithBMI));
//     }
//   };

//   const [systolic, setSystolic] = useState("");
//   const [diastolic, setDiastolic] = useState("");

//   // Function to calculate EDD based on LMP
//   const calculateEDD = (lmpDate) => {
//     if (!lmpDate) return ""; // Return empty if LMP is not set

//     const lmp = new Date(lmpDate);
//     lmp.setFullYear(lmp.getFullYear() + 1);
//     lmp.setMonth(lmp.getMonth() - 3);
//     lmp.setDate(lmp.getDate() + 7);

//     return lmp.toISOString().split("T")[0]; // Return date in YYYY-MM-DD format
//   };

//   // Update blood pressure value
//   useEffect(() => {
//     const bpValue = `${systolic}/${diastolic}`;
//     handleChange({
//       target: { name: "bloodPressure", value: bpValue },
//     });
//   }, [systolic, diastolic]);

//   // Update EDD when LMP changes
//   useEffect(() => {
//     const edd = calculateEDD(values.lastMenstrualPeriod);
//     handleChange({
//       target: { name: "estimatedDueDate", value: edd },
//     });
//   }, [values.lastMenstrualPeriod]);

//   const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

//   // Handle clearing all data
//   const handleClearData = useCallback(() => {
//     setValues({
//       bloodPressure: "",
//       pulse: null,
//       height: null,
//       weight: null,
//       temperature: null,
//       bmi: null,
//       waistHip: null,
//       spo2: null,
//       fetalHeartSound: "",
//       fetalMovement: "",
//       hgt: null,
//       lastMenstrualPeriod: "",
//       estimatedDueDate: "",
//     });
//     setSystolic("");
//     setDiastolic("");
//     dispatch(updateField("vitalsData", ""));
//   }, [dispatch]);

//   useEffect(() => {
//     if (clearAllData) {
//       handleClearData();
//     }
//   }, [ClearData, clearAllData]);

//   return (
//     <>
//       <IconFunctionality
//         name="Vitals"
//         iconErase
//         onClick={() => handleClearData()}
//         catID={""}
//         fieldName={""}
//       />

//       <div className="col-12      col-xxl-9 mx-4   ">
//         <div className="row g-3">
//           {[
//             { label: "BP", name: "bloodPressure", unit: "mmhg", type: "text" },
//             { label: "Pulse", name: "pulse", unit: "apm" },
//             { label: "Height", name: "height", unit: "cm" },
//             { label: "Weight", name: "weight", unit: "kg" },
//             { label: "Temperature", name: "temperature", unit: "°F" },
//             { label: "BMI", name: "bmi", unit: "kg/m²" },
//             { label: "Waist/Hip", name: "waistHip" },
//             { label: "SPO2", name: "spo2", unit: "%" },
//             { label: "FHS", name: "fetalHeartSound", type: "text" },
//             { label: "FM", name: "fetalMovement", type: "text" },
//             { label: "HGT", name: "hgt" },
//             { label: "LMP", name: "lastMenstrualPeriod", type: "date" },
//             { label: "EDD", name: "estimatedDueDate", type: "date" },
//           ].map((item, index) => (
//             <div
//               className="col-auto "
//               key={index}
//               style={
//                 item.label === "BP"
//                   ? { maxWidth: 220 }
//                   : item.label === "FHS" ||
//                     item.label === "FM" ||
//                     item.label === "HGT"
//                     ? { maxWidth: 120 }
//                     : { maxWidth: 180 }
//               }
//             >
//               {item.label === "BP" ? (
//                 <>
//                   <label style={{ paddingBottom: 3, fontWeight: 500, color: iconColor }}>{item.label}</label>


//                   <div className="input-group border p-0 rounded">
//                     <input
//                       type="text"
//                       className="form-control border-0"
//                       style={{ height: "32px" }} // Set the height here
//                       value={systolic}
//                       onChange={(e) => setSystolic(e.target.value)}
//                       maxLength="3"
//                       aria-label="Systolic"
//                     />
//                     <span
//                       className="input-group-text bg-white border-0 text-primary"
//                       style={{
//                         height: "32px",
//                         display: "flex",
//                         alignItems: "center",
//                       }} // Set the height here
//                     >
//                       /
//                     </span>
//                     <input
//                       type="text"
//                       className="form-control border-0"
//                       style={{ height: "32px" }} // Set the height here
//                       value={diastolic}
//                       onChange={(e) => setDiastolic(e.target.value)}
//                       maxLength="3"
//                       aria-label="Diastolic"
//                     />
//                     <span
//                       className="input-group-text text-primary"
//                       style={{
//                         height: "32px",
//                         display: "flex",
//                         alignItems: "center",
//                         border: "none",
//                       }} // Set the height here
//                     >
//                       mmHg
//                     </span>
//                   </div>
//                 </>
//               ) : (
//                 <>
//                   <label style={{ paddingBottom: 3, fontWeight: 500, color: iconColor }}>{item.label}</label>
//                   <div className="input-group">
//                     <input
//                       type={item.type || "number"}
//                       className="form-control "
//                       name={item.name}
//                       value={values[item.name] || ""}
//                       onChange={handleChange}
//                       style={{ height: "32px" }}
//                     />
//                     {item.unit && (
//                       <span
//                         className="input-group-text  text-primary "
//                         style={{ height: "32px" }}
//                       >
//                         {item.unit}
//                       </span>
//                     )}
//                   </div>
//                 </>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Vitals;



import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../ReduxApi/ReduxApi";
import IconFunctionality from "../IconFunctionality";
import { iconColor } from "../visitConstant/ApiConstant";

const Vitals = () => {
  const dispatch = useDispatch();
  const [ClearData, setClearData] = useState("");
  const [values, setValues] = useState({
    bloodPressure: "",
    pulse: null,
    height: null,
    weight: null,
    temperature: null,
    bmi: null,
    waistHip: null,
    spo2: null,
    fetalHeartSound: "",
    fetalMovement: "",
    hgt: null,
    lastMenstrualPeriod: "",
    estimatedDueDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    const updatedValues = {
      ...values,
      [name]: value,
    };

    setValues(updatedValues);
    dispatch(updateField("vitalsData", updatedValues));

    if (name === "weight" || name === "height") {
      calculateBMI(updatedValues);
    }
  };

  const calculateBMI = (updatedValues) => {
    const { weight, height } = updatedValues;

    if (weight && height) {
      const bmi = (weight / (height / 100) ** 2).toFixed(2);
      const updatedWithBMI = { ...updatedValues, bmi };
      setValues(updatedWithBMI);
      dispatch(updateField("vitalsData", updatedWithBMI));
    }
  };

  const [systolic, setSystolic] = useState("");
  const [diastolic, setDiastolic] = useState("");

  // Function to calculate EDD based on LMP
  const calculateEDD = (lmpDate) => {
    if (!lmpDate) return ""; // Return empty if LMP is not set

    const lmp = new Date(lmpDate);
    lmp.setFullYear(lmp.getFullYear() + 1);
    lmp.setMonth(lmp.getMonth() - 3);
    lmp.setDate(lmp.getDate() + 7);

    return lmp.toISOString().split("T")[0]; // Return date in YYYY-MM-DD format
  };

  // Update blood pressure value
  useEffect(() => {
    let bpValue = `${systolic}/${diastolic}`;
    bpValue = bpValue !== '/' ? bpValue : "";
    handleChange({
      target: { name: "bloodPressure", value: bpValue },
    });
  }, [systolic, diastolic]);

  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setValues({
      bloodPressure: "",
      pulse: null,
      height: null,
      weight: null,
      temperature: null,
      bmi: null,
      waistHip: null,
      spo2: null,
      fetalHeartSound: "",
      fetalMovement: "",
      hgt: null,
      lastMenstrualPeriod: "",
      estimatedDueDate: "",
    });
    setSystolic("");
    setDiastolic("");
    dispatch(updateField("vitalsData", ""));
  }, [dispatch]);

  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [ClearData, clearAllData]);

  // New function to handle EDD calculation on focus
  const handleEDDFocus = () => {
    const edd = calculateEDD(values.lastMenstrualPeriod);
    handleChange({
      target: { name: "estimatedDueDate", value: edd },
    });
  };

  return (
    <>
      <IconFunctionality
        name="Vitals"
        iconErase
        onClick={() => handleClearData()}
        catID={""}
        fieldName={""}
      />

      <div className="col-12 col-xxl-9 mx-4">
        <div className="row g-3">
          {[
            { label: "BP", name: "bloodPressure", unit: "mmhg", type: "text" },
            { label: "Pulse", name: "pulse", unit: "apm" },
            { label: "Height", name: "height", unit: "cm" },
            { label: "Weight", name: "weight", unit: "kg" },
            { label: "Temperature", name: "temperature", unit: "°F" },
            { label: "BMI", name: "bmi", unit: "kg/m²" },
            { label: "Waist/Hip", name: "waistHip" },
            { label: "SPO2", name: "spo2", unit: "%" },
            { label: "FHS", name: "fetalHeartSound", type: "text" },
            { label: "FM", name: "fetalMovement", type: "text" },
            { label: "HGT", name: "hgt" },
            { label: "LMP", name: "lastMenstrualPeriod", type: "date" },
            { label: "EDD", name: "estimatedDueDate", type: "date", onFocus: handleEDDFocus },
          ].map((item, index) => (
            <div
              className="col-auto"
              key={index}
              style={
                item.label === "BP"
                  ? { maxWidth: 220 }
                  : item.label === "FHS" ||
                    item.label === "FM" ||
                    item.label === "HGT"
                    ? { maxWidth: 120 }
                    : { maxWidth: 180 }
              }
            >
              {item.label === "BP" ? (
                <>
                  <label style={{ paddingBottom: 3, fontWeight: 500, color: iconColor }}>{item.label}</label>
                  <div className="input-group border p-0 rounded">
                    <input
                      type="text"
                      className="form-control border-0"
                      style={{ height: "32px" }}
                      value={systolic}
                      onChange={(e) => setSystolic(e.target.value)}
                      maxLength="3"
                      aria-label="Systolic"
                    />
                    <span className="input-group-text bg-white border-0 text-primary" style={{ height: "32px", display: "flex", alignItems: "center" }}>
                      /
                    </span>
                    <input
                      type="text"
                      className="form-control border-0"
                      style={{ height: "32px" }}
                      value={diastolic}
                      onChange={(e) => setDiastolic(e.target.value)}
                      maxLength="3"
                      aria-label="Diastolic"
                    />
                    <span className="input-group-text text-primary" style={{ height: "32px", display: "flex", alignItems: "center", border: "none" }}>
                      mmHg
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <label style={{ paddingBottom: 3, fontWeight: 500, color: iconColor }}>{item.label}</label>
                  <div className="input-group">
                    <input
                      type={item.type || "number"}
                      className="form-control"
                      name={item.name}
                      value={values[item.name] || ""}
                      onChange={handleChange}
                      onFocus={item.onFocus} // Add the focus event handler here
                      style={{ height: "32px" }}
                    />
                    {item.unit && (
                      <span className="input-group-text text-primary" style={{ height: "32px" }}>
                        {item.unit}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Vitals;

