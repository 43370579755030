function TermsAndConditions (){



    return (
      <div className="container">
        <div class="row g-0  mt-5 pt-4">
          <div class="col p-3 md-5">
            <div class="card-body">
              <h1> Terms And Conditions:</h1>

              <p class="fs-10 lh-base bold">
                Welcome to Jivdani Hospital! These Terms & Conditions outline
                the rules and regulations governing your use of our services. By
                accessing or using Jivdani Hospital's facilities, website, and
                services, you agree to comply with these terms. Please read them
                carefully before proceeding. If you do not agree with any part
                of these terms, please refrain from using our services.
              </p>
              <p class="fs-10">
                <h4>1. Patient Care : </h4>
                Jivdani Hospital is committed to providing quality healthcare
                services. However, the hospital will not be liable for any
                unforeseen medical complications or adverse outcomes that may
                arise during or after treatment.
              </p>
              <p class="fs-10">
                <h4>2. Appointment and Scheduling: </h4>
                Appointments are recommended for all medical services. Jivdani
                Hospital will make reasonable efforts to adhere to scheduled
                appointments, but unforeseen circumstances might cause delays.
                We appreciate your understanding and patience.
              </p>
              <p class="fs-10">
                <h4>3. Confidentiality: </h4> Patient information and medical
                records will be treated with strict confidentiality. We adhere
                to all applicable laws and regulations regarding patient
                privacy.
              </p>
              <p class="fs-10">
                <h4> 4. Cancellation and Refund Eligibility : </h4>
                4.1. Full Refund: You are eligible for a full refund of your
                payment if you cancel your appointment at least 24 hours before
                the scheduled appointment time. Refunds will be processed using
                the same payment method you used for booking the appointment.
              </p>

              <p class="fs-10">
                4.2. Partial Refund: If you cancel your appointment less than 24
                hours but at least 2 hours before the scheduled time, you may be
                eligible for a partial refund. The amount refunded will be
                determined on a case-by-case basis, taking into account
                administrative and scheduling costs.
              </p>

              <p class="fs-10">
                4.3. No Refund: No refunds will be provided for appointments
                canceled less than 2 hours before the scheduled time, or for
                no-shows on the appointment day.
              </p>

              <p class="fs-10">
                <h4> 4.2.1. Refund Process: </h4>
                4.2.1. To request a refund, please contact our customer support
                team via email at jivdanihospital1@gmail.com or by phone at
                +9193217 75857. You must provide your appointment booking
                details, including your name, appointment date, and reference
                number.
              </p>

              <p class="fs-10">
                4.2.2. Our customer support team will verify the eligibility of
                your refund request based on the criteria mentioned in section 1
                above.
              </p>

              <p class="fs-10">
               4.2.3. Once eligibility is confirmed, refunds will be processed
                within 3 to 5 business days. Please note that the time it takes
                for the refund to reflect in your account may vary depending on
                your payment provider.
              </p>

              <p class="fs-10">
                <h4> 5. Insurance: </h4>
                Visitors must adhere to the hospital's visiting hours and
                policies. Children under a certain age may not be allowed in
                certain areas for safety reasons.
              </p>
              <p class="fs-10">
                <h4> 6. Visitor Policy : </h4> By the usage of shreesarvshakti
                for any intention by any means, you believe the company law that
                you'll now not strive to utilize different consumer names,
                passwords, or account records without authorization from us.
              </p>
              <p class="fs-10">
                <h4> 7. Code of Conduct:</h4>Patients and visitors are expected
                to conduct themselves in a respectful and courteous manner.
                Disruptive behavior, violence, or harassment towards staff,
                patients, or visitors will not be tolerated.
              </p>
              <p class="fs-10">
                <h4> 8. Intellectual Property:: </h4> The content on Jivdani
                Hospital's website, including text, images, and logos, is
                protected by intellectual property laws. You may not use,
                reproduce, or distribute this content without explicit
                permission.
              </p>
              <p class="fs-10">
                <h4> 9. External Links:: </h4>
                Jivdani Hospital's website may contain links to external
                websites. These links are provided for informational purposes
                only, and the hospital does not endorse or guarantee the content
                or security of these sites.
              </p>
              <p class="fs-10">
                <h4> 10. Limitation of Liability: </h4>
                Jivdani Hospital will not be liable for any direct, indirect,
                incidental, consequential, or punitive damages arising out of
                your use of our services, website, or facilities.
              </p>
              <p class="fs-10">
                <h4> 11. Governing Law: </h4>
                These Terms & Conditions are governed by the laws of
                [Jurisdiction], and any disputes will be subject to the
                exclusive jurisdiction of the courts in that jurisdiction.
              </p>
              <p class="fs-10">
                <h4> 12. Modifications: </h4>
                Jivdani Hospital reserves the right to modify or update these
                Terms & Conditions at any time. Changes will be effective upon
                posting on the hospital's website. By using Jivdani Hospital's
                services, you acknowledge that you have read, understood, and
                agreed to these Terms & Conditions. If you have any questions or
                concerns, please contact our administration department for
                clarification. Last updated: [Date] Thank you for choosing
                Jivdani Hospital for your healthcare needs!
              </p>
              <p class="fs-10">
                <h4> 13. Reschedule Policy : </h4>
                1) Appointments can be rescheduled 24 hours prior to the date of
                the appointment & refund will be according to the refund policy.
              </p>
              <p class="fs-10">
                2) Appointment cannot be rescheduled if the request is made on
                the day of the appointment, however if the patient is unable to
                receive the video conference call for any technical reason and
                the call converts to a ‘No-show’, the patient will be allowed to
                reschedule it one time only. All no-show will be linked to the
                Cancellation policy.
              </p>
              <p class="fs-10 fw-bold">
                Jivdani hospital,
                <br /> Dombivali Multispeciality Hospital |<br /> ICU, General,
                Surgical, Maternity,
                <br /> Panchkarma Kalyan - Shilphata Road Desai <br /> Village
                Sagarli Gaon, Maharashtra 421204 India
                <br />
                jivdanihospital1@gmail.com <br /> (+91) 093217 75857
              </p>
            </div>
          </div>
        </div>
      </div>

      // <div style="box-sizing: border-box; font-family: 'gillroy';">
        
      //   <div>
          
      //     <div
      //       id="head"
      //       style="margin-top: 5rem; padding-top: 4rem; background-color: #f1fcff;"
      //     >
            
      //       <div style="margin: 0 auto; max-width: 900px;">
              
      //         <h1 style="font-weight: bold;">Neurologist</h1> <br />
      //         <div
      //           id="line"
      //           style="width: 40px; height: 5px; background-color: #038edf;"
      //         ></div>
      //         <br />
      //         <div style="font-size: 1.25rem;" class="col-lg-8 fs-5">
                
      //           A neurologist is a medical doctor who specializes in diagnosing
      //           and treating diseases of the brain, spinal cord, and nerves.
      //           Neurological diseases and conditions can affect nearly every
      //           part of your body and affect both adults and children.
      //         </div>
      //       </div>
      //     </div>
      //     <br />
         
      //     <br /> <br />
      //     <div style="margin: 0 auto; max-width: 900px;">
            
      //       <div
      //         className="col-md-10 scrollspy-example"
      //         data-bs-spy="scroll"
      //         data-bs-target="#ll"
      //         data-bs-smooth-scroll="true"
      //         tabindex="0"
      //       >
              
      //         <h2 className="fw-bolder" id="scrollspyOver">
                
      //           Overview
      //         </h2>
      //         <br /> <h3 className="fw-bolder">
                
      //           What is a neurologist ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
                
      //           A neurologist is a medical doctor who diagnoses, treats and
      //           manages disorders of the brain and nervous system (brain, spinal
      //           cord and nerves). A neurologist knows the anatomy, function and
      //           conditions that affect your nerves and nervous system. Your
      //           nervous system is your body’s command center. It controls
      //           everything you think, feel and do — from moving your arm to the
      //           beating of your heart.
      //         </div>
      //         <br />
      //         <h3 className="fw-bolder">
                
      //           What is a pediatric neurologist ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
                
      //           A pediatric neurologist is a medical doctor who diagnoses,
      //           treats and manages disorders of the brain and nervous system in
      //           children — from newborn to adolescent. Many of the conditions
      //           they treat are the same as those seen in adults, in addition to
      //           inherited and developmental conditions.
      //         </div>
      //         <br /> <h3 className="fw-bolder">
                
      //           What is a neurosurgeon ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
      //           A neurosurgeon is a medical doctor who performs surgery on the
      //           brain, spinal cord and nerves.
      //         </div>
      //         <br />
      //         <h3 className="fw-bolder">
                
      //           What diseases and conditions does a neurologist treat ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
                
      //           Some of the most common neurologic disorders a neurologist may
      //           treat include: <br /> <br /> ● Alzheimer’s disease and other
      //           dementias. <br /> <br /> ● Amyotrophic lateral sclerosis (also
      //           called ALS or Lou Gehrig’s disease). <br /> <br /> ● Brain
      //           injury, spinal cord injury or vascular malformations. <br />
      //           <br /> ● Cerebral aneurysms and arteriovenous malformations.
      //           <br /> <br /> ● Cerebral palsy and spasticity. <br /> <br /> ●
      //           Concussion. <br /> <br /> ● Encephalitis. <br /> <br /> ●
      //           Epilepsy. <br /> <br /> ● Facial pain syndromes. <br /> <br /> ●
      //           Headache/migraine. <br /> <br /> ● Hydrocephalus. <br /> <br />
      //           ● Meningitis. <br /> <br /> ● Mental and behavioral health
      //           disorders. <br /> <br /> ● Multiple sclerosis. <br /> <br /> ●
      //           Myasthenia gravis and myopathies. <br /> <br /> ● Pain in your
      //           neck, back and spine. <br /> <br /> ● Parkinson’s disease.
      //           <br /> <br /> ● Peripheral neuropathy. <br /> <br /> ● Sleep
      //           disorders. <br /> <br /> ● Stroke. <br /> <br /> ● Tremor,
      //           dystonia. <br /> <br /> ● Tremor, dystonia. <br /> <br /> ●
      //           Tumors of the brain, spine and nerves.
      //         </div>
      //         <br /> <br />
      //         <h3 className="fw-bolder">
      //           How do neurologists diagnose conditions ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
                
      //           Your neurologist will ask about your medical history, family
      //           history, medication history and any current symptoms. They’ll
      //           also conduct a neurologic examination, including tests of your:
      //           <br /> <br /> ● Coordination, balance, reflexes and gait. <br />
      //           <br /> ● Muscle strength. <br /> <br /> ● Mental health. <br />
      //           <br /> ● Vision, hearing and speech. <br /> <br /> ● Sensation.
      //           <br /> <br /> Your neurologist may also order blood, urine or
      //           other fluid tests in order to help understand condition severity
      //           or check on medication levels. Genetic testing may be ordered to
      //           identify inherited disorders. Imaging studies of your nervous
      //           system might also be ordered to aid in diagnosis. <br /> <br />
      //           Neurologists treat people with medications, physical therapy or
      //           other approaches. <br /> <br />
      //         </div>
      //         <h3 className="fw-bolder">
                
      //           What types of tests does a neurologist order ?
      //         </h3>
      //         <br />
      //         <div className="fs-5">
                
      //           Common neurologic tests include: <br />
      //           <br /> ● Angiography: Angiography can show if blood vessels in
      //           your brain, head or neck are blocked, damaged or abnormal. It
      //           can detect such things as aneurysms and blood clots. <br />
      //           <br /> ● Biopsy : A biopsy is the removal of a piece of tissue
      //           from your body. Biopsies may be taken of muscle, nerve or brain
      //           tissue. <br />
      //           <br /> ● Cerebrospinal fluid analysis. This test involves the
      //           removal of a sample of the fluid that surrounds your brain and
      //           spinal cord. The test can detect evidence of a brain bleed,
      //           infection, multiple sclerosis and metabolic diseases.
      //           <br />
      //           <br /> ● Computed tomography (CT), magnetic resonance imaging
      //           (MRI), X-raysandultrasound.
      //           <br />
      //           <br /> ● Electroencephalography (EEG). This test measures your
      //           brain’s electrical activity and is used to help diagnose
      //           seizures and infections (such as encephalitis) brain injury and
      //           tumors. <br />
      //           <br /> ● Electromyography (EMG). This test records the
      //           electrical activity in muscles and is used to diagnose nerve and
      //           muscle disorders, spinal nerve root compression and motor neuron
      //           disorders such as amyotrophic lateral sclerosis. <br />
      //           <br /> ● Electronystagmography (ENG). This group of tests is
      //           used to diagnose involuntary eye movement, dizziness and balance
      //           disorders. <br />
      //           <br /> ● Evoked potentials. This test measures how quickly and
      //           completely electrical signals reach your brain from your eyes,
      //           ears or touch to your skin. The test can help diagnose multiple
      //           sclerosis, acoustic neuroma and spinal cord injury. <br />
      //           <br /> ● Myelography. This test helps diagnose spinal and spinal
      //           cord tumors and herniated disks and fractures. <br />
      //           <br /> ● Polysomnogram. This test measures brain and body
      //           activity during sleep and helps diagnose sleep disorders. <br />
      //           <br /> ● Positron emission tomography (PET). This imaging test
      //           can show tumors or be used to evaluate epilepsy, brain tumors,
      //           dementia and Alzheimer’s disease. <br />
      //           <br /> ● Single-photon emission computed tomography (SPECT).
      //           This imaging test can diagnose tumors, infections and assess the
      //           location of seizures, degenerative spine disease and stress
      //           fractures. <br />
      //           <br /> ● Thermography. This test measures temperature changes
      //           within your body or specific organs and is used to evaluate pain
      //           syndromes, peripheral nerve disorders and nerve root
      //           compression. <br /> <br />
      //         </div>
      //         <h3 className="fw-bolder">
                
      //           When should I make an appointment with a neurologist?
      //         </h3>
      //         <div className="fs-5">
                
      //           Some of the more common symptoms for which you may want to see a
      //           neurologist (or be referred to one) include: <br /> <br /> ●
      //           Memory disturbances, forgetfulness.
      //           <br /> <br /> ● Loss of consciousness. <br />
      //           <br /> ● Seizures. <br /> <br /> ● Taste or smell disturbances.
      //           <br /> <br /> ● Vision problems. <br /> <br /> ● Numbness and
      //           tingling sensations. <br /> <br /> ● Facial asymmetries (one
      //           side of your face doesn’t match the other [eyelid droops, can’t
      //           fully smile]). <br /> <br /> ● Vertigo, ringing in the ears
      //           (tinnitus) and deafness. <br /> <br /> ● Difficulty swallowing,
      //           hoarseness in voice, difficulty in shrugging your shoulders or
      //           turning your neck, difficulty with tongue movements. <br /> ●
      //           Muscle weakness, cramps, spasms and twitching. <br /> <br /> ●
      //           Burning or electrical shock-like pain in any body part. <br />
      //           <br /> ● Neck or back pain, headache. <br /> <br /> ● Imbalance
      //           in gait. <br /> <br /> ● Tremors. <br /> <br /> ● Slowness in
      //           movement.
      //         </div>
      //         <br /> <br />
      //         <h3 className="fw-bolder">
                
      //           How should I prepare for my first neurologist appointment?
      //         </h3>
      //         <div className="fs-5">
                
      //           To get the most out of your neurologist visit, it’s helpful to
      //           be prepared. Ways to prepare include: <br /> <br /> ● Bring a
      //           list of the most important issues you want to discuss with your
      //           neurologist. <br /> <br /> ● Discuss any changes in your overall
      //           health. <br /> <br /> ● Discuss your new symptoms or changes in
      //           existing or prior symptoms. Keep a symptom diary (and bring it
      //           with you) and record events, including day and time they
      //           occurred, how long the event lasted, severity, triggers,
      //           symptoms and any action you took to end the event. This is
      //           especially useful if you have a condition in which symptoms
      //           aren’t constant, such as epilepsy, sleep apnea, headaches or
      //           Parkinson’s disease. <br /> <br /> ● Bring copies of test
      //           results, including a CD of images and lab work ordered by other
      //           healthcare providers outside of your neurologist’s health care
      //           network. <br /> <br /> ● Bring a list of all current products
      //           you take. Include prescription medications, over-the-counter
      //           medications and any vitamins, supplements and herbal products.
      //           Also, let your neurologist know about any previous medications
      //           that didn’t work or that caused side effects. <br /> <br /> ●
      //           Bring a list of your known allergies. <br /> <br /> ● Bring a
      //           friend or relative with you to the appointment to take notes and
      //           be another set of ears and eyes. This person can help review
      //           your neurologist’s discussion, ask questions and remind you
      //           about scheduling tests and follow-up appointments. <br /> <br />
      //           ● Ask if you should schedule another appointment to discuss any
      //           additional concerns. <br /> <br />
      //         </div>
      //         <br />
      //         <h2 className="fw-bolder" id="scrollspyAdditionascroll">
                
      //           Additional Common Questions
      //         </h2>
      //         <br />
      //         <h3 className="fw-bolder">
                
      //           How much schooling does it take to become a neurologist?
      //         </h3>
      //         <div className="fs-5">
                
      //           To become a neurologist, doctors must complete: <br />
      //           <br /> ● Four years of college. <br /> <br /> ● Four years of
      //           medical school. <br /> <br /> ● One year of an internship
      //           (training in neurology and other fields). <br /> <br /> ● Three
      //           years of residency (continued training concentrating on the
      //           field of neurology). <br /> <br /> ● Up to three years of a
      //           fellowship. This isn’t mandatory, but a fellowship offers
      //           additional training in a neurology subspecialty. This training
      //           time may be longer if the neurologist chooses to pursue multiple
      //           fellowships. <br /> <br />
      //         </div>
      //         <br />
      //         <h3 className="fw-bolder">
                
      //           What are some neurology subspecialty fields ?
      //         </h3>
      //         <div className="fs-5">
                
      //           Some neurology subspecialty fields include: <br /> <br /> ●
      //           Brain injury medicine. <br /> <br /> ● Child neurology. <br />
      //           <br /> ● Clinical neurophysiology. <br /> <br /> ● Epilepsy.
      //           <br /> <br /> ● Headache medicine.
      //           <br /> <br /> ● Geriatric neurology.
      //           <br /> <br /> ● Neurodevelopmental disabilities.
      //           <br /> <br /> ● Neuroimaging.
      //           <br /> <br /> ● Neuro-oncology.
      //           <br /> <br /> ● Pain medicine.
      //           <br /> <br /> ● Sleep medicine.
      //           <br /> <br /> ● Vascular neurology.
      //           <br /> <br />
      //         </div>
      //         <div className="fw-bold fs-5">
                
      //           A note from Cleveland Clinic
      //         </div>
      //         <br />
      //         <div className="fs-5">
                
      //           A neurologist is a medical doctor who specializes in diseases
      //           and conditions affecting your brain, spinal cord and nerves.
      //           Your neurologist will examine you, order tests, make a
      //           diagnosis, treat your condition with medication or physical
      //           therapy or refer you to and work together with other
      //           specialists, such as a neurosurgeon or neuro-oncologist, if
      //           appropriate. Come prepared with your notes, share your health
      //           information and never hesitate to ask questions. Your
      //           neurologist is here to help diagnose your condition, treat or
      //           manage it as best as possible and support you along the way.
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
}

export default TermsAndConditions;