import React, { useState, useEffect } from "react";
import { ROLE, SUPAR_ADMIN, USER_ROLE } from "../Constant";
import swal from "sweetalert";
import moment from "moment";

const TokenModal = ({ token, onUpdate, onClose }) => {
  const [tokenData, setTokenData] = useState(token);
  const [amounts, setAmounts] = useState({
    cash: 0,
    upi: 0,
    card: 0,
    other: 0,
  });
  const [PaymentSplit, setPaymentSplit] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setTokenData(token);
    // if (token.paymentMode) {
    //   setAmounts(JSON.parse(token.paymentMode));
    // }
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTokenData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setPaymentsInputs((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [selectedOption, setSelectedOption] = useState(token?.paymentMode);

  const handleOptionChange = (option) => {
    if (selectedOption === option) {
      setSelectedOption("");
    } else {
      setSelectedOption(option);
    }
  };

  const Submit = (data) => {

    if (data.status === '3') {
      if (data?.amount) {
        handleSubmit();
      } else if (selectedOption) {
        handleSubmit();
      } else {
        swal("Amount", "Amount information is missing", "error");

      }
    } else {
      handleSubmit();
    }

  };

  const handleSubmit = () => {
    const updatedTokenData = {
      ...tokenData,
      paymentMode: selectedOption
      // paymentMode: JSON.stringify(amounts),
      // date:tokenData.date,
    };
    onUpdate(updatedTokenData);
    onClose();

  };

  const [paymentInputs, setPaymentsInputs] = useState({
    cash: token?.cash,
    upi: token?.upi,
    card: token?.card,
    other: token?.other,
  });

  const dataCalulet = () => {
    let totalAmount = 0;
    totalAmount += parseInt(paymentInputs.cash) || 0;
    totalAmount += parseInt(paymentInputs.upi) || 0;
    totalAmount += parseInt(paymentInputs.card) || 0;
    totalAmount += parseInt(paymentInputs.other) || 0;
    setTokenData((prevState) => ({ ...prevState, amount: totalAmount, paymentMode: selectedOption }));
    isSubmitDisabled(totalAmount)
  };
  useEffect(() => {
    if (onClose) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '6px';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    };
  }, [onClose]);
  useEffect(() => {
    dataCalulet();
  }, [paymentInputs]);

  const isSubmitDisabled = (amount) => {
    console.log(amount)
    if (amount) {
      return false;
    }
    return !selectedOption;
  };


  const ColingButton = (tokenData) => {
    onClose()
    Submit(tokenData)
  }
  const handleCloseOutsideClick = (e) => {
    // Check if the click is outside the modal content
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div
      className="modal fade show "
      style={{
        display: "block", backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
      tabIndex="-1"
      role="dialog"
      onClick={handleCloseOutsideClick}
    >
      {/* modal-dialog modal-xl */}
      <div className="modal-dialog modal-dialog-centered  modal-xl ">
        <div className="modal-content bg-light">
          <div className="modal-header">
            <h1 className="modal-title fs-6" id="exampleModalLabel">
              {tokenData?.name}   ( {moment(tokenData?.date).format("DD-MMM-YYYY")} )
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            // onClick={() => ColingButton(tokenData)}
            />
          </div>
          <div className="modal-body">
            <form>
              <div className="row row-cols-3">
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="patientName">
                    Patient name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="patientName"
                    onChange={handleChange}
                    value={tokenData?.name}
                    className="form-control mt-1"
                    placeholder="Enter patient name for prescriptions"
                    required
                  />
                </div>
                <div className="col">
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="mobileNo">
                      Mobile number
                    </label>
                    <input
                      name="mobileNo"
                      id="mobileNo"
                      onChange={handleChange}
                      value={tokenData?.mobileNo}
                      className="form-control mt-1"
                      placeholder="Enter Mobile No"
                      required
                      type="tel"
                      size="10"
                      minLength="10"
                      maxLength="12"
                    />
                  </div>
                </div>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="age">
                    Age
                  </label>
                  <input
                    name="age"
                    id="age"
                    onChange={handleChange}
                    value={tokenData?.age}
                    className="form-control mt-1"
                    placeholder="Enter Age"
                    required
                    type="number"
                    min="1"
                    max="120"
                  />
                </div>
              </div>
              <div className="row row-cols-3">
                <div className="col">
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="form2Example22">
                      Gender
                    </label>
                    <div class="align-self-center ">
                      <select
                        className="form-select"
                        id="inputGroupSelect02"
                        defaultValue=""
                        name="gender"
                        onChange={handleChange}
                        value={tokenData?.gender}
                      >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="T">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="status">
                      Token Status
                    </label>
                    <select
                      className="form-select fw-bold"
                      id="status"
                      name="status"
                      onChange={handleChange}
                      value={tokenData?.status}
                    >
                      <option value="1">In Queue</option>
                      <option value="2">In Progress</option>
                      <option value="4">Payment pending</option>
                      <option value="3">Complete</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="amount">
                      {PaymentSplit ? "Total Amount" : "Amount"}
                    </label>
                    <input
                      name="amount"
                      id="amount"
                      // onChange={handleChange}
                      value={tokenData?.amount}
                      // {tokenData.amount ?tokenData.amount :totalAmount}
                      className="form-control mt-1"
                      placeholder="Enter Amount"
                      required
                      type="number"
                      min="0"
                      max="50"
                      disabled={PaymentSplit}
                    />
                  </div>
                </div>
              </div>
              <div className="container text-start">

                <div className="row row-cols-2  ">
                  <div className="col border p-2">
                    <div
                      className={`form-check mb-2   ${selectedOption === "IPD" ? "checked" : ""
                        }`}
                      onClick={() => handleOptionChange("IPD")}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="1"
                        checked={selectedOption === "IPD"}
                        readOnly
                      />
                      <label className="form-check-label" htmlFor="1" onClick={() => handleOptionChange("IPD")}>
                        IPD
                      </label>
                    </div>
                  </div>

                  <div className="col border p-2 ">
                    <div
                      className={`form-check mb-2   ${selectedOption === "FREE" ? "checked" : ""
                        }`}
                      onClick={() => handleOptionChange("FREE")}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="2"
                        checked={selectedOption === "FREE"}
                        readOnly
                      />
                      <label className="form-check-label" htmlFor="2" onClick={() => handleOptionChange("FREE")}>
                        FREE
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 ">
                  <div className="col border">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="cash">
                        Cash
                      </label>
                      <input
                        name="cash"
                        id="cash"
                        onChange={handleChange}
                        value={tokenData?.cash}
                        className="form-control mt-1"
                        placeholder="Amount"
                        required
                        type="number"
                        min="1"
                        max="50"
                      />
                    </div>
                  </div>
                  <div className="col border">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="upi">
                        upi
                      </label>
                      <input
                        name="upi"
                        id="upi"
                        onChange={handleChange}
                        value={tokenData?.upi}
                        className="form-control mt-1"
                        placeholder="Amount"
                        required
                        type="number"
                        min="1"
                        max="50"
                      />
                    </div>
                  </div>
                  <div className="col border">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="card">
                        card
                      </label>
                      <input
                        name="card"
                        id="card"
                        onChange={handleChange}
                        value={tokenData?.card}
                        className="form-control mt-1"
                        placeholder="Amount"
                        required
                        type="number"
                        min="1"
                        max="120"
                      />
                    </div>
                  </div>
                  <div className="col border">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="other">
                        other
                      </label>
                      <input
                        name="other"
                        id="other"
                        onChange={handleChange}
                        value={tokenData?.other}
                        className="form-control mt-1"
                        placeholder="Amount"
                        required
                        type="number"
                        min="1"
                        max="50"
                      />
                    </div>
                  </div>
                </div>


              </div>
              <div className="row row-cols-2 mt-2">
                <div className="form-outline ">
                  <label htmlFor="comment" className="col-form-label">
                    Mark
                  </label>
                  <textarea
                    name="comment"
                    className="form-control"
                    id="comment"
                    onChange={handleChange}
                    value={tokenData?.comment}
                  />
                </div>
                <div className="form-outline ">
                  <label htmlFor="address" className="col-form-label">
                    Address
                  </label>
                  <textarea
                    name="address"
                    className="form-control"
                    id="address"
                    onChange={handleChange}
                    value={tokenData?.address}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary"
              // onClick={handleSubmit}

              onClick={() => Submit(tokenData)}
              disabled={tokenData?.status === '3' && isSubmitDisabled(tokenData?.amount)}
            >
              {/* Update Data */}
              {tokenData?.amount
                ? ` Update Data (₹ ${tokenData?.amount})`
                : selectedOption === "FREE"
                  ? " Update Data (FREE)"
                  : !selectedOption
                    ? "Update Data"
                    : " Update Data (IPD)"}

            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TokenModal;
