import React from 'react'
import {  IMG_BASE_URL } from '../Constant';

function NoteBoard({ Text, Text_02 }) {
  return (
  
    <div className="card container justify-content-center bg-info p-1 my-5">
      <div className="row gap-3  ">
        <div className="col  " style={{ maxWidth: 50 }}>
          <img
            src= { IMG_BASE_URL +"icons/Text.png" } 
           
            style={{ maxWidth: 50 }}
            alt="IMG"
          />
        </div>
        <div className="col ">
          <small class=" text-white d-inline-block   ">{Text}</small>
          <br></br>
          <small class=" text-white d-inline-block   ">{Text_02}</small>
        </div>
      </div>
    </div>
  );
}

export default NoteBoard