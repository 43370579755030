import axios from "axios";
import { ACCESS_TOKEN, BASE_URL, DOCTOR_VISITPAD, SESSION_ID } from "../../Constant";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

// Action Types
const FETCH_VISIT_PAD_REQUEST = "FETCH_VISIT_PAD_REQUEST";
const FETCH_VISIT_PAD_SUCCESS = "FETCH_VISIT_PAD_SUCCESS";
const FETCH_VISIT_PAD_FAILURE = "FETCH_VISIT_PAD_FAILURE";

// Action Creators
const fetchVisitPadRequest = () => ({
  type: FETCH_VISIT_PAD_REQUEST,
});

const fetchVisitPadSuccess = (data) => ({
  type: FETCH_VISIT_PAD_SUCCESS,
  payload: data,
});

const fetchVisitPadFailure = (error) => ({
  type: FETCH_VISIT_PAD_FAILURE,
  payload: error,
});

// Thunk Action for API Call
export const fetchVisitPad = (navigate) => {
  const section = localStorage.getItem(SESSION_ID);
  return async (dispatch) => {
    dispatch(fetchVisitPadRequest());
    try {
      const response = await axios.post(
        BASE_URL + DOCTOR_VISITPAD,

        {
          pubInfo: {
            sessionId: section ? section : "3762D32E2F01170A2D5B50B5C74F48C7",
          },
          request: {
            busiParams: {},
            isEncrypt: false,
            transactionId: "897987987989",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
          },
        }
      );

      const respMsg = response.data.respData.respMsg;

      dispatch(fetchVisitPadSuccess(respMsg));
      // console.log("respMsg", respMsg);
      // Optionally, store data in localStorage if needed
      // localStorage.setItem(DOCTOR_VISIT_DATA, respMsg);
      // alert("data visit pad")
    } catch (error) {
      console.error("Error making the API call:", error.request.status);

      if (error.request.status == 401) {
        // navigate("/login");
        swal(
          "Session Expired",
          "Your login session has expired. Please log in again.",
          "warning"
        ).then(() => {
          navigate("/login"); // Redirect to the login page after acknowledging the message
        });
      } else {
        dispatch(fetchVisitPadFailure(error.message));
        console.error("Error making the API call:", error);
      }
    }
  };
};

// Initial State
const initialState = {
  loading: false,
  data: null,
  error: null,
};

// Reducer
const visitPadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VISIT_PAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VISIT_PAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_VISIT_PAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default visitPadReducer;
