import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Placeholder from "../../src/default_img/placeholder.png";
import DefaultImage from  "../Images/doctor.png"

// import PropTypes from "prop-types";
import {
  // DEBUG_LOG_ENABLED,
  // SESSION_ID,
  // ADD_DOCTOR,
  // BASE_URL,
  // ACCESS_TOKEN,
  USER_ROLE,
  ROLE,
  IMG_BASE_URL,

} from "../Constant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
// import axios from "axios";
import unavailableImage from "../Images/unavailable.png"

function DoctorDetail() {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  // const [confirmPassword, setConfirmPassword] = useState("");
  const myData = localStorage.getItem("myProduct");
  const product = JSON.parse(myData);
  let [showEdit, setShowEdit] = useState(false);

  // const [customer, setCustomer] = useState({
  //   // doctorId: 0,
  //   name: "",
  //   mobileNo: "",
  //   emailId: "",
  //   password: "",
  //   type: 0,
  //   specialization: "",
  //   registrationNo: "",
  //   registrationDate: "",
  //   dateOfBirth: "",
  //   // role: ""
  // });

  // const handleChange = (e) => {
  //   const value = e.target.value;
  //   setCustomer({ ...customer, [e.target.name]: value });
  // };

  // const saveCustomer = async (e) => {
  //   e.preventDefault();
  //   if (
  //     customer.name === "" ||
  //     customer.password === "" ||
  //     customer.emailId === "" ||
  //     customer.mobileNo === "" ||
  //     customer.dateOfBirth === "" ||
  //     customer.registrationDate === "" ||
  //     customer.registrationNo === "" ||
  //     customer.specialization === "" ||
  //     customer.type === ""
  //   ) {
  //     alert(" Please enter all fields ");
  //   } else if (confirmPassword !== customer.password) {
  //     alert(" password not matched ");
  //   } else {
  //     const customerData = JSON.stringify({
  //       pubInfo: {
  //         sessionId: localStorage.getItem(SESSION_ID),
  //       },
  //       request: {
  //         busiParams: {
  //           name: customer.name,
  //           emailId: customer.emailId,
  //           mobileNo: customer.mobileNo,
  //           password: customer.password,
  //           type: customer.type,
  //           specialization: customer.specialization,
  //           registrationNo: customer.registrationNo,
  //           registrationDate: customer.registrationDate,
  //           dateOfBirth: customer.dateOfBirth,
  //         },
  //         isEncrypt: false,
  //         transactionId: "897987987989",
  //       },
  //     });

  //     try {
  //       let response = await axios.post(BASE_URL + ADD_DOCTOR, customerData, {
  //         headers: {
  //           "Access-Control-Allow-Origin": "*",
  //           "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  //           "Access-Control-Allow-Headers": "Content-Type",
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
  //         },
  //       });

  //       alert(response.data.respData.message);
  //       if (DEBUG_LOG_ENABLED) {
  //         console.log(response.data.respData);
  //       }
  //       navigate("/login");
  //     } catch (error) {
  //       alert("error : " + error);
  //       if (DEBUG_LOG_ENABLED) {
  //         console.log(error);
  //       }
  //     }
  //   }
  // };
  // let color1 = "btn btn-outline-success me-3  ";
  // let color2 = "btn bg-primary me-3 ";

  // var [color, setcolor] = useState(color1);

  // var buttonColor = () => {
  //   if (color == color1) {
  //     setcolor(color2);
  //   } else {
  //     setcolor(color1);
  //   }
  // };

  useEffect(() => {
    //fileContent();
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);

  return (
    <div className="align-items-center container  py-4 px-xl-4  mt-5">
      {/* <div className="wrapper"> */}
      {/* <div className="col-lg-9"> */}
      {/* <div className="d-flex flex-column  align-items-center"> */}
      <ScrollToTopOnMount />

      <form className="  rounded-top bg-primary card-body">
        <div className="card bg-primary">
          {product?.iconURL ? (
            <img
              src={IMG_BASE_URL + product?.iconURL}
              style={{ maxHeight: "100px", width: "100px" }}
              class="rounded-circle align-self-center mt-5"
              alt="Cinque Terre"
              onError={(event) => {
                event.target.src = DefaultImage;
              }}
            ></img>
          ) : (
            <img
              src={unavailableImage}
              style={{ maxHeight: "100px", width: "100px" }}
              class="rounded-circle align-self-center mt-5"
              alt="Cinque Terre"
            ></img>
          )}
          <h4 className="align-self-center pt-2">{product.title}</h4>
          <p className="align-self-center fw-bold ">{product.titleID}</p>

          {showEdit ? (
            <p className="align-self-center  fw-bold">
              Reg.ID -{product.doctorId}
            </p>
          ) : null}

          <div class="d-grid gap-2 col-6 mx-auto">
            <button
              className="btn btn-light m-3"
              onClick={() => navigate("/slotbooking/" + product?.itemId)}
              // to={"/slotbooking"}
              // onClick={() =>
              //   localStorage.setItem("catItem", JSON.stringify(showEdit))
              // }
              replace
              type="submit"
            >
              Book
            </button>
            {showEdit ? (
              <>
                <Link
                  className="btn btn-light m-3"
                  to={"/doctorcatitem"}
                  // onClick={() => navigate("/doctorcatitem")}
                  replace
                  onClick={() =>
                    localStorage.setItem("catItem", JSON.stringify(product))
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit
                </Link>

                <Link
                  className="btn btn-light m-3"
                  to={"/adddate"}
                  replace
                  onClick={() =>
                    localStorage.setItem("catItem", JSON.stringify(product))
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Date Slot
                </Link>
              </>
            ) : null}
          </div>
        </div>
        <div class="card rounded-top ">
          <div class="card-body ">
            <ol class="list-group list-group-numbered">
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Qualification detaiis </div>
                  {product.subTitle}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Experience</div>
                  {product.subTitleID}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Languages known</div>
                  {product.viewMoreTitle}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Consultation fees</div>
                  {product.price}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Email</div>
                  {product.emailId}
                </div>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                  <div class="fw-bold">Contact</div>
                  Jivdani hospital <br></br>(+91) 93217 75857
                </div>
              </li>
            </ol>
          </div>
        </div>
      </form>
    </div>
    // </div>
    // </div>
    // </div>
  );
}
export default DoctorDetail;
