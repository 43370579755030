import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import unavailableImage from "../../Images/unavailable.png";
import {
  ROLE,
  USER_ROLE,
  CUSTOMER_ID,
  ACCESS_TOKEN,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  IMG_BASE_URL,

} from "../../Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import swal from "sweetalert";
import { increment, decrement, handleLoggin } from "../../actions";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
export default function Xray() {
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState([]);

  const navigate = useNavigate();
  const [slots, setSlots] = useState([]);
  const [timeslots, setTimeSlots] = useState([]);

  let [showEdit, setShowEdit] = useState(false);
  let [Buttonactiv, setButtonActiv] = useState(0);
  const [Activ, setActiv] = useState(true);
  let [Loader, setLoader] = useState(false);
  const isLogged = useSelector((state) => state.isLogged);
    const dispatch = useDispatch();
  useEffect(() => {
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }

    const filteredSearchList = catItemList.filter((item) => {
      return item.viewType === "x_ray_view";
    });
    setCatListData(filteredSearchList);
    timeSet(filteredSearchList);
  }, []);

    const timeSet = (slots) => {
      {
        slots.map((item, index) => (index === 0 ? setTimeSlots(item) : null));
      }
    };


    const addToMycart = (item) => {
      // e.preventDefault();
      const id = localStorage.getItem(CUSTOMER_ID);
      const token = localStorage.getItem(ACCESS_TOKEN);

      if (DEBUG_LOG_ENABLED) {
        console.log("CartService : id:" + id + "  token:" + token);
      }
      if (isLogged && id != null && id != "") {
        addToCart(item);
      } else {
        navigate("/login");
      }
    };
    const addToCart = async (item) => {
      try {
        const itemData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              id: item.itemId,
              customerId: localStorage.getItem(CUSTOMER_ID),
              itemId: item.itemId,
              price: item.price,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });
        if (DEBUG_LOG_ENABLED) {
          console.log("CartService : " + itemData);
        }

        // const response = await CartService.addToCart(itemData);

        let response = await axios.post(BASE_URL + ADD_TO_CART, itemData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        });

        //dispatch({type:ADD_CART, payload: 10})
        dispatch(increment(response.data.respData.respMsg.cartCount));
        swal(response.data.respData.message);
      } catch (err) {
        if (err.response.status == 401) {
          localStorage.setItem(CUSTOMER_ID, "");
          navigate("/login");
        } else {
          swal("data : " + err);
          if (DEBUG_LOG_ENABLED) {
            console.log(err);
          }
        }
      }
    };


 const [openIndex, setOpenIndex] = useState(null);
  const [openItem, setOpenItem] = useState("collapseOne");

   const handleToggle = (itemId, item) => {
    setTimeSlots(item); 
     setOpenItem((prevItem) => (prevItem === itemId ? null : itemId));
   };

 const toggleAccordion = (index) => {
   setOpenIndex(openIndex === index ? null : index);
 };
    

  return (
    <div className="align-items-center container  py-4 px-xl-4  "
    
    style={{marginTop:80}}>
      <ScrollToTopOnMount/>
      <div className=" flex-column  align-items-center">
        <form className="  rounded-top bg-primary card-body">
          <div className="card bg-primary">
            <div className="text-light ps-4 mt-4 ">Book appointment </div>
            <div class="row p-4">
              <div class="col-3">
                <img
                  src="./../logo192.png"
                  style={{ maxHeight: "70px", width: "70px" }}
                  // class="rounded-circle "
                  alt="Cinque Terre"
                ></img>
              </div>
              <div class="col align-self-center ">
                <h4 className=" text-light ">JIVDANI HOSPITAL X-RAYS</h4>
              </div>
            </div>
          </div>
          <div class="card rounded-top p-3">
            {catListData?.length === 0 ? (
              <div class="d-flex justify-content-center m-5">
                <div className=" text-center">
                  <img src={unavailableImage} style={{ width: "18rem" }} />
                </div>
              </div>
            ) : (
              <div>
                {/* <div className="d-flex flex-row flex-nowrap overflow-auto pb-4"> */}
                <div>
                  {Loader ? (
                    <div
                      className="spinner-border text-primary mx-2"
                      style={{ width: 20, height: 20 }}
                      role="status"
                    ></div>
                  ) : (
                    <div className="accordion" id="accordionExample">
                      {catListData.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <h2
                            className="accordion-header"
                            id={`heading${index}`}
                          >
                            <Link
                              className={`accordion-button  fw-bold ${
                                openItem === `collapse${index}`
                                  ? ""
                                  : "collapsed"
                              }`}
                              type="button"
                              onClick={() =>
                                handleToggle(`collapse${index}`, item)
                              }
                              aria-expanded={openItem === `collapse${index}`}
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index}`}
                              aria-controls={`collapse${index}`}
                              style={{ fontSize: 30, textDecoration: "none" }}
                            >
                              <img
                                src={IMG_BASE_URL+item.iconURL}
                                style={{ maxHeight: "60px", width: "60px" }}
                                class="rounded-circle me-5"
                                alt="Cinque Terre"
                              ></img>
                              {item.title}
                            </Link>
                            {showEdit ? (
                              <Link
                                to={"/addcat"}
                                className="btn btn-light ms-5 "
                                style={{ width: 180 }}
                                onClick={() =>
                                  navigate(
                                    localStorage.setItem(
                                      "catData",
                                      JSON.stringify(item)
                                    )
                                  )
                                }
                                type="submit"
                              >
                                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                                Edit
                              </Link>
                            ) : null}
                          </h2>

                          <div
                            id={`collapse${index}`}
                            className={`accordion-collapse collapse ${
                              openItem === `collapse${index}` ? "show" : ""
                            }`}
                            aria-labelledby={`heading${index}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {timeslots.items?.map((item, idx) => (
                                <div className="card border-0 shadow-sm mb-3">
                                  <div className="card-header py-3 bg-white">
                                    <div className="row justify-content-between">
                                      <div className="col-6 col-md-auto ">
                                        <img
                                          src={IMG_BASE_URL+item.iconURL}
                                          className="card-img-top"
                                          style={{ width: 120 }}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="col-6 col-md-8">
                                        <h1
                                          style={{
                                            fontSize: 19,
                                            fontWeight: 900,
                                          }}
                                        >
                                          {item.title}
                                        </h1>

                                        <div className="row justify-content-between">
                                          <div className="col-12 col-md-auto">
                                            <h1
                                              style={{
                                                fontSize: 20,
                                                fontWeight: 900,
                                              }}
                                            >
                                              {"₹" + item.price}
                                            </h1>
                                          </div>
                                          <div className="col-12 col-md-auto">
                                            {showEdit && (
                                              <Link
                                                className="btn btn-outline-primary"
                                                to={`/addcatitem/`}
                                                replace
                                                onClick={() =>
                                                  localStorage.setItem(
                                                    "catItem",
                                                    JSON.stringify(item)
                                                  )
                                                }
                                                type="submit"
                                              >
                                                Edit
                                              </Link>
                                            )}

                                            <Link
                                              className="btn btn-outline-primary"
                                              onClick={() => addToMycart(item)}
                                              style={{
                                                width: 120,
                                                alignSelf: "flex-end",
                                              }}
                                            >
                                              Add to cart
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
