// src/icons/icons.js

import whatsapp from "./Icons/whatsapp.png"
import gmail from './Icons/gmail.png'
import print from './Icons/printer.png'
import search from './Icons/search.png'
import add from './Icons/add.png'
import disk from './Icons/floppy-disk.png'
import erase from './Icons/eraser.png'

import slider1 from './Icons/1slider.jpg'
import slider2 from './Icons/2slider.jpg'
import slider3 from './Icons/3slider.jpg'


const icons = {
  gmail,
  whatsapp,
  print,
  search,
  erase,
  add,
  disk,
  slider1,
  slider2,
  slider3
  // Add more icons here
};

export default icons;




