import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import  { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Heade from "./Heade.css";



const BottomNavigation = () => {
  // const {state, dispatch} = useContext(UserContext);
  const counter = useSelector((state) => state.counter);
  const isLogged = useSelector((state) => state.isLogged);
  const navigate = useNavigate();
  const [catItemList, setCartItemList] = useState([]);

  const RenderMenu = () => {

    
    if (isLogged) {
      return (
        <>
          <button
            className="navbar-toggler "
            type="button"
            onClick={() => navigate("/profile")}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-user"
              className="text-primary "
              data-bs-placement="top"
              title="Profile"
            />
            <br />
            <small className="text-primary">Profile</small>
          </button>

         
        </>
      );
    } else {
      return (
        <>
          <button
            className="navbar-toggler "
            type="button"
            onClick={() => navigate("/login")}
          >
            <FontAwesomeIcon
              icon="fa-solid fa-user"
              className="text-primary "
              data-bs-placement="top"
              title="Profile"
            />
            <br />
            <small className="text-primary">Login</small>
          </button>
        
        </>
      );
    }
  };

  const [openedDrawer, setOpenedDrawer] = useState(false);

  function toggleDrawer() {
    setOpenedDrawer(!openedDrawer);
  }

  function changeNav(event) {
    if (openedDrawer) {
      setOpenedDrawer(false);
    }
  }

  return (
    <>
      {/* <header>
        <nav className="navbar navbar-expand-sm bg-primary fixed-bottom ">
          <div className="container ">
            
            <button
              className="navbar-toggler  border border-primary "
              type="button"
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-house"
                className="text-primary "
                data-bs-placement="top"
                title="Home"
              />
              <br />
              <small className="text-primary ">Home</small>
            </button>
           
            <button
              className="navbar-toggler border border-primary "
              type="button"
              onClick={() => navigate("/article")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-list-ol"
                className="me-3 text-primary"
              />

              <br />
              <small className="text-primary"> Token</small>
            </button>

            <button
              className="navbar-toggler border border-primary  position-relative  "
              type="button"
              onClick={() => navigate("/addtocart")}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-truck-fast"
                className="  text-primary "
                data-bs-placement="top"
                title="Order"
              />

              <br />
             
              <small className="text-primary">Order History</small>
            </button>
            <RenderMenu />
          </div>
        </nav>
      </header> */}

      <nav className="d-block d-sm-none   navbar-expand-sm bg-light fixed-bottom m-2 border border-primary rounded">
        <div className="container">
          <div className="row row-cols-4  py-2">
            <div className="col">
              {" "}
              <button
                className="navbar-toggler   "
                type="button"
                onClick={() => navigate("/")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-house"
                  className="text-primary "
                  data-bs-placement="top"
                  title="Home"
                />
                <br />
                <small className="text-primary ">Home</small>
              </button>
            </div>
            <div className="col">
              <button
                className="navbar-toggler "
                type="button"
                onClick={() => navigate("/article")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-newspaper"
                  className="  text-primary "
                  data-bs-placement="top"
                  title="Article"
                />
                <br />
                <small className="text-primary">Article</small>
              </button>
            </div>
            <div className="col">
              <button
                className="navbar-toggler position-relative  "
                type="button"
                onClick={() => navigate("/addtocart")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-truck-fast"
                  className="  text-primary "
                  data-bs-placement="top"
                  title="Order"
                />

                <br />

                <small className="text-primary">Order</small>
              </button>
            </div>
            <div className="col">
              {" "}
              <RenderMenu />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default BottomNavigation;
