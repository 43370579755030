// PreviewSection.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GOOGLE_DRIVE_PREVIEW_URL } from "../report/visitConstant/ApiConstant";

const PreviewSection = ({ item }) => {
  const fileId = item?.labTestRecord?.reportUrl || "";
  const previewableUrl = fileId
    ? GOOGLE_DRIVE_PREVIEW_URL.replace("{fileId}", fileId)
    : "";
  const handlePrint = () => {
    if (previewableUrl) {
      const printWindow = window.open(previewableUrl);

    } else {
      console.error("No file URL available to open.");
    }
  };

  return (
    <div className=" d-flex  gap-3 ms-4" style={{ alignItems: "center" }}>
      {/* <label className="form-label text-black">Preview</label> */}
      <div>
        <FontAwesomeIcon
          icon="fa-solid fa-file-image"
          onClick={(e) => {
            e.stopPropagation();
            handlePrint();
          }}
          style={{ fontSize: 20, cursor: "pointer" }}
          className="text-primary p-2 bg-white rounded p-1"
        />
      </div>
    </div>
  );
};

export default PreviewSection;
