
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCircleCheck } from '@fortawesome/free-solid-svg-icons';  // Importing icons
import "../ui/CSS/focusinput.css"
import ToastFist from '../Toast/ToastFist';
import swal from 'sweetalert';
import { AddEditMedicine } from '../master/masterApi/AddEditMedicine';
const ContentsEdtiable = ({ row }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState(row?.medicineId?.contents || "UNKNOWN");
    const [ltext, setlText] = useState(row?.medicineId?.contents || "");
    const [loading, setLoading] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [colorStatus, setColorStatus] = useState(3);
    const [showToast, setShowToast] = useState(false);
    const textareaRef = useRef(null);
    const saveButtonClicked = useRef(false);
    const handleIconClick = () => {
        setIsEditing(true);
        setlText(text)
    };

    const handleChange = (e) => {
        setlText(e.target.value);
    };


    const handleSaveClick = async () => {
        saveButtonClicked.current = true;
        setLoading(true);
        const response = await AddEditMedicine({ data: row?.medicineId, content: ltext });
        if (response) {
            setText(ltext);
            setlText(ltext);
            setShowToast(true);
            setLoading(false);
            setIsEditing(false);
            setColorStatus(1);
            setToastMsg("Content saved successfully");

        } else {
            setIsEditing(false);
            setShowToast(true);
            setLoading(false);
            setColorStatus(0);
            setToastMsg("Content not saved");
        }
    };



    useEffect(() => {
        if (isEditing && textareaRef.current) {
            const length = ltext.length;
            textareaRef.current.setSelectionRange(length, length); // Move cursor to the end
            textareaRef.current.focus(); // Keep the textarea focused
        }
    }, [isEditing]);

    const handleBlur = () => {
        setTimeout(() => {
            if (!saveButtonClicked.current) {
                setIsEditing(false);
            } else {
                saveButtonClicked.current = false;
            }
        }, 500);
    };

    return (
        <div className="d-flex " style={{ alignItems: 'center', marginLeft: 6 }}>
            {!isEditing ? (
                <>
                    <FontAwesomeIcon
                        icon={faPencil}
                        className="text-primary"
                        style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            marginRight: "3px",
                            fontSize: 10,
                        }}
                        onClick={handleIconClick}
                    />

                    <p
                        className="col-10"
                        style={{
                            fontSize: 11,
                            fontWeight: "normal",
                            color: "gray",
                            margin: 0,
                            width: "300px",
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </p>
                </>
            ) : (
                <>
                    <textarea
                        ref={textareaRef}
                        type="ltext"
                        value={ltext}
                        onChange={handleChange}
                        autoFocus
                        rows="2"
                        className='form-control border-0 '
                        style={{
                            fontSize: 11,
                            fontWeight: "normal",
                            color: "gray",
                            margin: 0,
                            marginTop: 8,
                            width: "334px",
                        }}
                        onBlur={handleBlur}
                    />

                    {loading ? <span
                        style={{ marginLeft: "14px", fontSize: 8, width: 12, height: 12 }}
                        class="spinner-border spinner-border-sm text-primary"
                        role="status"
                        aria-hidden="true"
                    ></span>
                        :
                        <div className="mb-1 " onClick={handleSaveClick}>
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                className="text-primary"
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "14px",
                                    fontSize: 14,
                                }}

                            />
                        </div>
                    }
                </>
            )
            }
            <ToastFist
                showToast={showToast}
                setShowToast={setShowToast}
                title="Indication"
                message={toastMsg}
                duration="Just now"
                status={colorStatus}
            />
        </div >
    );
};

export default ContentsEdtiable;
