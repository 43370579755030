import React from 'react'

export default function Loader() {
  return (
    // <div class="align-items-center container  py-4 px-xl-4  mt-5">
    //   <div class="position-absolute top-30 start-50 mt-5 pt-5">
    //     <div class="spinner-border" role="status">
    //       <span class="visually-hidden">Loading...</span>
    //     </div>
    //   </div>
    // </div>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
