import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CustomTooltip from "../../CustomTooltip";
import customStyles from "../../ui/CSS/customStyles.js";
import PlusButton from "../../ui/PlusButton.js";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi.js";
import IconFunctionality from "../../IconFunctionality.js";
import Categories from "../../visitConstant/Categories.js";
import { DataConverte, ReversObj } from "../../DataConverte/DataConverte.js";

const ObstetricH = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [rows, setRows] = useState([createEmptyRow()]);
  const [firstTableData, setFirstTableData] = useState(
    createInitialTableData()
  );

  // Utility function to create an empty row
  function createEmptyRow() {
    return {
      id: "",
      durationOfPregnancy: "",
      pregnancyOutcome: "",
      birthWeight: "",
      dob: "",
      sex: "",
      anomalies: "",
      remarks: "",
    };
  }

  // Initial data structure for the first table
  function createInitialTableData() {
    return {
      gravida: "",
      termBirths: "",
      preterm: "",
      livingChildren: "",
      abortions: "",
      deaths: "",
    };
  }

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const sexOptions = [
    { value: "F", label: "F" },
    { value: "M", label: "M" },
    { value: "T", label: "T" },
  ];

  const CustomDropdownIndicator = () => null;

  // Handles updating a specific row field
  const handleRowUpdate = (index, field, value) => {
    if (field == "pregnancyOutcome") {
      const newdata = {
        id: value.value,
        name: value.label,
      };
      const updatedRows = rows.map((row, i) =>
        i === index ? { ...row, [field]: newdata } : row
      );
      setRows(updatedRows);
      updateReduxStore(firstTableData, updatedRows);
      if (index === updatedRows.length - 1) addRow();
    } else {
      const updatedRows = rows.map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      );
      setRows(updatedRows);
      updateReduxStore(firstTableData, updatedRows);
      if (index === updatedRows.length - 1) addRow();
    }
  };

  // Handles updating the first table data
  const handleFirstTableChange = (field, value) => {
    const updatedFirstTableData = { ...firstTableData, [field]: value };
    setFirstTableData(updatedFirstTableData);
    updateReduxStore(updatedFirstTableData, rows, "1");
  };

  // Updates the Redux store with the current data
  const updateReduxStore = (firstTableData, additionalData, number) => {
    const EmptyDataRemover = additionalData?.filter(
      (item) => item?.durationOfPregnancy
    );
    // console.log(firstTableData, EmptyDataRemover)

    if (number == "1") {
      dispatch(
        updateField("obstetricHistoryData", {
          ...firstTableData,
        })
      );
    } else {
      dispatch(updateField("obstetricAdditionalDatas", EmptyDataRemover));
    }
  };

  // Adds a new empty row
  const addRow = () => {
    setRows((prevRows) => [...prevRows, createEmptyRow()]);
  };

  // Removes a row by index
  const removeRow = (index) => {
    if (rows.length > 1) {
      const updatedRows = rows.filter((_, i) => i !== index);
      setRows(updatedRows);
      updateReduxStore(firstTableData, updatedRows);
    }
  };

  // Access the data from Redux state
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    setRows([createEmptyRow()]);
    setFirstTableData(createInitialTableData());
    dispatch(updateField("obstetricHistoryData", {}));
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  // useEffect(() => {
  //   const fistData = patientData?.obstetricHistoryData;
  //   const secondaryData =
  //     patientData?.obstetricHistoryData?.obstetricAdditionalDatas;
  //   const data = {
  //     gravida: fistData?.gravida,
  //     termBirths: fistData?.termBirths,
  //     preterm: fistData?.preterm,
  //     livingChildren: fistData?.livingChildren,
  //     abortions: fistData?.abortions,
  //     deaths: fistData?.deaths,
  //   };

  //   setFirstTableData(data);

  //   const initializedRows = secondaryData?.map((item) => ({
  //     obstetricAdditionalDataID: item?.obstetricAdditionalDataID,
  //     durationOfPregnancy: item?.durationOfPregnancy,
  //     pregnancyOutcome: item?.pregnancyOutcome,
  //     birthWeight: item?.birthWeight,
  //     dob: item?.dob,
  //     sex: item?.sex,
  //     anomalies: item?.anomalies,
  //     remarks: item?.remarks,
  //   }));

  //   // console.log("initializedRows", initializedRows);
  //   const currentTempletData = Array.isArray(initializedRows)
  //   ? initializedRows
  //   : [];

  //   setRows([ ...currentTempletData,createEmptyRow()]);

  //   updateReduxStore(data, initializedRows);
  // }, [patientData]);

  useEffect(() => {
    // Safely access the obstetricHistoryData and obstetricAdditionalDatas
    const fistData = patientData?.obstetricHistoryData || {};
    const secondaryData =
      patientData?.obstetricAdditionalData || [];

    if (Object.keys(fistData)?.length != 0 || secondaryData?.length != 0) setIsOpen(true);
    const data = {
      gravida: fistData.gravida || "",
      termBirths: fistData.termBirths || "",
      preterm: fistData.preterm || "",
      livingChildren: fistData.livingChildren || "",
      abortions: fistData.abortions || "",
      deaths: fistData.deaths || "",
    };
    setFirstTableData(data);

    const initializedRows = Array.isArray(secondaryData)
      ? secondaryData.map((item) => ({
        obstetricAdditionalDataID: item?.obstetricAdditionalDataID || "",
        durationOfPregnancy: item?.durationOfPregnancy || "",
        pregnancyOutcome: item?.pregnancyOutcome || "",
        birthWeight: item?.birthWeight || "",
        dob: item?.dob || "",
        sex: item?.sex || "",
        anomalies: item?.anomalies || "",
        remarks: item?.remarks || "",
      }))
      : [];
    setRows([...initializedRows, createEmptyRow()]);

    updateReduxStore(data, initializedRows);
  }, [patientData]);


  return (
    <>
      <div className="d-flex">
        <IconFunctionality
          name="Obstetric History"
          // iconSave
          // iconRefresh
          iconErase
          onClick={handleClearData}
          catID={""}
          fieldName={""}
        />
        <PlusButton setOpen={setIsOpen} open={isOpen} />
      </div>

      {/* <div className="col-12  col-xxl-9 px-4"> */}
      <div
        className="col-12 col-xxl-8  mx-xxl-auto"
        style={{ paddingLeft: 40 }}
      >
        {isOpen && (
          <>
            {/* First Table */}
            <div className="table-responsive">
              <table
                className="table table-sm table-bordered"
                style={{ width: "100%" }}
              >
                <thead className="table-primary">
                  <tr style={{ fontSize: 13 }}>
                    {Object.keys(firstTableData).map((key) => (
                      <th key={key} scope="col">
                        {capitalizeFirstLetter(key)}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ fontSize: 13 }}>
                  <tr>
                    {Object.keys(firstTableData).map((key) => (
                      <td key={key}>
                        <input
                          type="number"
                          className="form-control"
                          style={{ border: 0, borderRadius: 0 }}
                          name={key}
                          value={firstTableData[key]}
                          onChange={(e) =>
                            handleFirstTableChange(key, e.target.value)
                          }
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Additional Data Table */}
            <div className="table">
              <table
                className="table table-sm table-bordered"
                style={{ width: "100%" }}
              >
                <thead className="table-primary" style={{ fontSize: 13 }}>
                  <tr>
                    <th scope="col" style={{ width: 30, textAlign: "center" }}>
                      #
                    </th>
                    <th scope="col">Duration of Pregnancy</th>
                    <th scope="col">Pregnancy Outcome</th>
                    <th scope="col">Birth Weight</th>
                    <th scope="col">DOB</th>
                    <th scope="col">Sex</th>
                    <th scope="col">Anomalies</th>
                    <th scope="col">Remarks</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, index) => (
                    <tr
                      key={row.id}
                      style={{ fontSize: 13, verticalAlign: "middle" }}
                    >
                      <th
                        scope="row"
                        style={{ width: 30, textAlign: "center" }}
                      >
                        {index + 1}
                      </th>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          style={{ border: 0, borderRadius: 0 }}
                          value={row.durationOfPregnancy}
                          onChange={(e) =>
                            handleRowUpdate(
                              index,
                              "durationOfPregnancy",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td style={{ minWidth: 200 }}>
                        {row.durationOfPregnancy &&
                          <Select
                            value={DataConverte(
                              visitPadData?.pregnancyOutcomes
                            )?.find(
                              (option) => option?.value === row?.pregnancyOutcome
                            )}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            options={DataConverte(
                              visitPadData?.pregnancyOutcomes
                            )}
                            placeholder=""
                            onChange={(selectedOption) =>
                              handleRowUpdate(
                                index,
                                "pregnancyOutcome",
                                selectedOption || ""
                              )
                            }
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                          />
                        }
                      </td>
                      <td>
                        {row.durationOfPregnancy &&
                          <input
                            type="text"
                            className="form-control"
                            style={{ border: 0, borderRadius: 0 }}
                            value={row.birthWeight}
                            onChange={(e) =>
                              handleRowUpdate(
                                index,
                                "birthWeight",
                                e.target.value
                              )
                            }
                          />
                        }
                      </td>
                      <td>
                        {row.durationOfPregnancy &&
                          <input
                            type="date"
                            className="form-control"
                            style={{ border: 0, borderRadius: 0 }}
                            value={row.dob}
                            name="dob"
                            onChange={(e) =>
                              handleRowUpdate(index, "dob", e.target.value)
                            }
                          />
                        }
                      </td>
                      <td style={{ width: 70 }}>
                        {row.durationOfPregnancy &&
                          <Select
                            value={sexOptions?.find(
                              (option) => option.value === row.sex
                            )}
                            closeMenuOnSelect={false}
                            styles={customStyles}
                            options={sexOptions}
                            placeholder=""
                            onChange={(selectedOption) =>
                              handleRowUpdate(
                                index,
                                "sex",
                                selectedOption.value || ""
                              )
                            }
                            components={{
                              DropdownIndicator: CustomDropdownIndicator,
                            }}
                          />
                        }
                      </td>
                      <td>
                        {row.durationOfPregnancy &&
                          <input
                            type="text"
                            className="form-control"
                            style={{ border: 0, borderRadius: 0 }}
                            value={row.anomalies}
                            onChange={(e) =>
                              handleRowUpdate(index, "anomalies", e.target.value)
                            }
                          />
                        }
                      </td>
                      <td>
                        {row.durationOfPregnancy &&
                          <input
                            type="text"
                            className="form-control"
                            style={{ border: 0, borderRadius: 0 }}
                            value={row.remarks}
                            name="remarks"
                            onChange={(e) =>
                              handleRowUpdate(index, "remarks", e.target.value)
                            }
                          />
                        }
                      </td>
                      {/* <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => removeRow(index)}
                        >
                          Delete
                        </button>
                      </td> */}

                      <td onClick={() => removeRow(index)}>
                        {index !== rows.length - 1 && (
                          <CustomTooltip
                            icon="fa-solid fa-trash-can"
                            tooltipText="Delete"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
};

// Helper function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default ObstetricH;
