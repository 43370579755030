import React, { useState } from "react";
import IconFunctionality from "../../IconFunctionality";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import customStyles, {
  SinglecustomSelectStyles,
} from "../../ui/CSS/customStyles";
import DietChartPdf from "../../Utils/DietChart.pdf"; // Your local PDF path
import PregnancyEnglish from "../../Utils/PregnancyEnglish.pdf"

import PregnancyMarathi from '../../Utils/PregnancyMarathi.pdf'
import { updateField } from "../../ReduxApi/ReduxApi";
import { useDispatch } from "react-redux";

const options = [
  {
    value: "Weight Loss",
    label: "Weight Loss",
  },
  {
    value: "Paralysis",
    label: "Paralysis",
  },
  {
    value: "Hypertension",

    label: "Hypertension",
  },
  {
    value: "Skin Diseases",
    label: "Skin Diseases",
  },
  {
    value: "Diabetes",
    label: "Diabetes",
  },
  {
    value: "pregnancy",
    label: "Pregnancy-Marathi",
  },
  {
    value: "Pregnancy",
    label: "Pregnancy-English",
  },
];

const DietPrint = () => {
  const [toPrint, setToPrint] = useState(null);
  const dispatch = useDispatch();

  const handleSelectChange = (selectedOption) => {
    setToPrint(selectedOption);

    console.log("DietPlan", selectedOption?.value);

    dispatch(updateField("dietPlan", selectedOption?.value));
  };

  const clearSelection = () => {
    setToPrint(null);

    dispatch(updateField("dietPlan", ""));
  };

  // const handlePrint = () => {
  //   var printWindow;
  //   if (toPrint.label == "Pregnancy-Marathi") {
  //     printWindow = window.open(PregnancyMarathi);
  //   } else if (toPrint.label == "Pregnancy-English") {
  //     printWindow = window.open(PregnancyEnglish);
  //   } else {
  //     printWindow = window.open(DietChartPdf);
  //   }

  //   printWindow.onload = () => {
  //     printWindow.print();
  //   };
  // };



  const handlePrint = () => {
    // Map labels to their corresponding file paths
    const labelToPath = {
      "Pregnancy-Marathi": PregnancyMarathi,
      "Pregnancy-English": PregnancyEnglish,
      "Diet Chart": DietChartPdf
    };
  
    // Determine the file path based on the selected label
    const filePath = labelToPath[toPrint.label] || DietChartPdf;
  
    // Open a new window
    const printWindow = window.open(filePath);
    
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    } else {
      console.error("Failed to open print window.");
    }
  };

  
  return (
    <>
      <div className="d-flex non-printable">
        <IconFunctionality
          name="Diet Plan"
          iconErase
          onClick={clearSelection}
        />

        <div className="col-12 col-xxl-9 mx-2">
          <div className="d-flex ms-1">
            <Select
              className="w-50"
              isClearable
              value={toPrint}
              //   closeMenuOnSelect={true}
              options={options}
              placeholder="Select Diet Plan"
              onChange={handleSelectChange}
              styles={SinglecustomSelectStyles}
              //  styles={customStyles}
            />

            {toPrint?.label && (
              <button
                type="button"
                className="btn btn-primary text-light ms-2"
                onClick={handlePrint}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-print"
                  className="text-light"
                />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DietPrint;
