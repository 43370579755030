import React from "react";
import { CARD_VIEW_WITH_TEXT, IMG_BASE_URL } from "../Constant";
import { Link } from "react-router-dom";
import Headline from "../landing/ Headline";
import "./Minicard.css";
import SimpalText from "../landing/SimpalText";

function Minicard({ Text, item, rowItem, navigate, showEdit, nama }) {
  const imgwidth = 350;

  const data = (item) => {
    //  navigate("/itemsviewmore");
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    // <div className="container text-center">
    //   <div
    //     className={`row row-cols-3 row-cols-sm-${(rowItem) =>
    //       3 ? 1 : rowItem - 1} row-cols-md-${rowItem}`}
    //   >
    //     <>
    //       {item.items.map((item, index) => (
    //         <div className="col">
    //           <div className="card " style={{ maxWidth: 160 }}>
    //             <div className="row g-0">
    //               <div className="col text-center">
    //                 <img
    //                   src={IMG_BASE_URL + item.iconURL}
    //                   className="img-fluid rounded-5 "
    //                   alt="..."
    //                 />
    //                 <p className="fw-bolder">{item.title}</p>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </>
    //   </div>
    // </div>
    <>
      <SimpalText item={Text} navigate={navigate} showEdit={showEdit} />
      <h3>{item.title}</h3>
      <div className=" text-center">
        <div
          className={` row row-cols-3 row-cols-sm-${(rowItem) =>
            3 ? 1 : rowItem - 1} row-cols-md-${rowItem}`}
        >
          <>
            {item.map((item, index) => (
              // <div className="col card-group">
              //   <Link to={"/itemsviewmore"} onClick={() => data(item)} style={{ textdecoration: "none"}}>
              //     <div
              //       className="card "
              //       style={{ maxWidth: 160, opacity: 0.9 }}
              //     >
              //       <div className="row g-0">
              //         <div className="col text-center">
              //           <img
              //             src={IMG_BASE_URL + item.viewMoreTitleID}
              //             className="img-fluid p-3 "
              //             alt="..."
              //             // style={{
              //             //   filter: `invert(21%) sepia(81%) saturate(6828%) hue-rotate(244deg) brightness(98%) contrast(91%)`,
              //             // }}
              //           />
              //           {/* <p className="fw-bolder ">{item.title}</p> */}
              //         </div>
              //       </div>
              //       <div class="card-footer">
              //         <small class=" fw-bolder   ">
              //           {item.title}
              //         </small>
              //       </div>
              //     </div>
              //   </Link>
              // </div>
              <div className="col  " style={{ maxWidth: 200 }}>
                <Link
                  to={
                    item?.viewType === "Digital X-ray"
                      ? "/Xray"
                      : item?.viewType === "Specialised"
                      ? "/Dialysis"
                      : item?.viewType === "neurologist"
                      ? "/neurologist"
                      : "/itemsviewmore"
                  }
                 // className="btn btn-light text-primary text-truncate "
                  onClick={() => data(item, item.viewType)}
                 
                  style={{ textdecoration: "none" }}
                >
                  <div class="card-group">
                    <div class=" minicared card">
                      <img
                        src={IMG_BASE_URL + item.viewMoreTitleID}
                        class="m-3"
                        alt="..."

                        // style={{ alignSelf: "center", maxHeight: 150 }}
                      />
                    </div>
                  </div>
                </Link>
                <p
                  className="text-center  "
                  style={{ fontSize: 13, fontWeight: "bold" }}
                >
                  {" "}
                  {item.title}{" "}
                </p>
              </div>
            ))}
          </>
        </div>
      </div>
    </>
  );
}

export default Minicard;
