import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  ROLE,
  USER_ROLE,
  you_tube_view,
  BACKGROUND,
  CARD_VIEW,
  USER_ROLE_CHANGES_BUTTON,
  ROLEDOCTOR,
  ROLE_LAB,
} from "../Constant";
import { useState, useEffect } from "react";
import Heade from "./Heade.css";
import LanguageSelector from "./LanguageSelector";
import { Roles } from "../booking/Booking";

// import Image from "../images/logo.png";

const ScrollingText = ({ text }) => {
  return (
    <div className="scrolling-text">
      <span>{text}</span>
    </div>
  );
};

const Header = () => {
  //const {state, dispatch} = useContext(UserContext);
  const counter = useSelector((state) => state.counter);
  const isLogged = useSelector((state) => state.isLogged);
  const navigate = useNavigate();
  const [showEdit, setShowEdit] = useState();
  const [EditButton, setEditButton] = useState();
  const [EditButtonValue, setEditButtonValue] = useState(false);
  const [isLab, setIsLab] = useState(false);
  const Location = useLocation();
  const userRoleButton = localStorage.getItem(USER_ROLE_CHANGES_BUTTON);

  const userRole = localStorage.getItem(USER_ROLE);

  // A function to check if the path matches the current route
  // const isActive = (path) => Location.pathname === path;




  useEffect(() => {


    // // const userRole = Roles;
    // if (userRole?.includes(ROLE)) {
    //   setShowEdit(true);
    //   setEditButton(true);
    //   setEditButtonValue(true);
    // } else if (userRole?.includes(ROLE_LAB)) {
    //   setIsLab(true)
    // }

    const userRole = localStorage.getItem(USER_ROLE);
    if (userRoleButton?.includes(ROLE)) {
      setEditButton(true);
    }
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
      setEditButtonValue(true);
    }
    if (userRole?.includes(ROLE_LAB)) {
      setIsLab(true)
    }


    const filteredSearchList = catItemList?.filter((item) => {
      return (
        item.viewType === CARD_VIEW
        // item?.viewType === "FullBodyCheakup"
        // item.viewType === CARD_VIEW_WITH_TEXT ||
      );
    });
    if (filteredSearchList) {
      filteredSearchList.map((item) => setCatListData(item));
    }
  }, [isLogged]);

  const RenderMenu = () => {
    // console.log(Location);

    if (isLogged) {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0  d-none d-md-block">
            <li className="nav-item pb-3">
              <FontAwesomeIcon
                icon="fa-solid fa-right-from-bracket"
                className=" text-light"
              />
              <Link
                // to="/logout"
                className="btn text-light  d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/logout")}
              >
                Logout
              </Link>
            </li>
            <li className="nav-item">
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                className="text-light "
              />
              <Link
                to="/profile"
                className="btn text-light d-lg-inline"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/profile")}
              >
                Profile
              </Link>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="navbar-nav me-auto mb-lg-0 ">
            <li className="nav-item pb-3">
              {/* <img src="./../icon-2.png" class="text-white" alt="..." /> */}
              <FontAwesomeIcon
                icon="fa-solid fa-user"
                className="text-light me-2"
              />
              <Link
                to="/login"
                className="btn text-light px-1 me-3   d-lg-inline  d-none d-md-block"
                replace
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => navigate("/login")}
              >
                Login
              </Link>
            </li>
          </ul>
        </>
      );
    }
  };
  const myData = localStorage.getItem("cat_ascending_data");
  const catItemList = JSON.parse(myData);
  const [catListData, setCatListData] = useState({});



  const data = (item, nama) => {
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };

  const [scrollingText, setScrollingText] = useState([
    "Appointment",
    "Dr. Booking",
    "Lab Test",
  ]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % scrollingText.length);
    }, 2000); // Adjust the interval duration as needed

    return () => clearInterval(interval);
  }, [scrollingText.length]);

  const handleEditToggle = () => {
    if (EditButtonValue) {
      localStorage.setItem(USER_ROLE, userRoleButton);
    } else {
      localStorage.setItem(USER_ROLE, "ROLE_USER,ROLE_DOCTOR");
    }

    setEditButtonValue(!EditButtonValue);
  };

  useEffect(() => {
    if (userRoleButton?.includes(ROLEDOCTOR)) {
      if (EditButtonValue) {
        localStorage.setItem(USER_ROLE, userRoleButton);
      } else {
        localStorage.setItem(USER_ROLE, "ROLE_USER,ROLE_DOCTOR");
      }
    }
    //  alert("calling");
  }, [EditButtonValue]);

  const handleButtonClick = () => {
    setEditButtonValue(!EditButtonValue);
    // alert("Calling");
    window.location.reload();
  };
  const [hoveredLink, setHoveredLink] = useState(null);
  const isActive = (path, currentPath) => path === currentPath;

  const commonStyle = {
    textDecoration: "none",
    color: "#fff",
    paddingBottom: "1px",
    marginBottom: "0",
    transition: "all 0.1s ease", // Smooth transition for hover effects
  };

  // Function to get underline style and add margin on hover
  const getUnderlineStyle = (path, currentPath, isHovered) => ({
    ...commonStyle,
    borderBottom: isActive(path, currentPath) || isHovered ? "3px solid #cedff6" : "none",
    color: isActive(path, currentPath) || isHovered ? "#cedff6" : "#fff",
    marginLeft: "10px",
    marginRight: "10px",

  });

  return (
    <nav
      className="navbar navbar-dark  border-bottom fixed-top  bg-primary pt-3"
    // style={{
    //   background: BACKGROUND,
    // }}
    >
      <div className="container " >
        <Link
          className="navbar-brand text-center d-flex justify-content-center align-items-center"
          style={{ width: "28px", marginLeft: -28 }}
          onClick={() => navigate(-1)}
        >
          {Location.pathname === "/" ? (
            ""
          ) : (
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="text-center"
            />
          )}
        </Link>
        <div className="col overflow-auto  d-block d-lg-none ">
          <Link className="navbar-brand  " to="/">
            <img src="./../favicon.ico" class="img" alt="appLogo"></img>
            {/* <span> </span> */}

            {/* <small className="d-none d-md-block"> JIVDANI HOSPITAL</small> */}
            <small className="   fw-bold">JIVDANI HOSPITAL</small>
          </Link>
          {/* <LanguageSelector/> */}
        </div>
        <div className="d-none d-lg-block overflow-auto ">
          <Link className="navbar-brand  " to="/">
            <img src="./../favicon.ico" class="img" alt="appLogo"></img>
            <span> </span>

            {/* <small className="d-none d-md-block"> JIVDANI HOSPITAL</small> */}
            <small className="   fw-bold">JIVDANI HOSPITAL</small>
          </Link>
          {/* <LanguageSelector/> */}
        </div>

        <div
          className="d-none d-md-flex mx-auto align-items-center   position-sticky  "
          style={{ width: "42%", }}
        >
          <Link
            className="input-group mx-auto "
            style={{ width: "100%", textDecoration: "none" }}
            to="/products"
          >
            {
              // Location.pathname === "/products" ? null : 
              (
                <div className="input-group text-primary">
                  <input
                    className="form-control"
                    type="search"
                    placeholder={"Search  " + scrollingText[activeIndex]}
                    aria-label="Search"
                    size="32"
                    style={{ outline: "none", boxShadow: 'none' }}
                  />

                  {/* <button type="button" className="btn btn-light ">
                    <FontAwesomeIcon
                      icon={["fas", "search"]}
                      className="text-primary"
                    />
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-outline-primary  border-0 border-start-0 bg-white"
                  >
                    <FontAwesomeIcon
                      icon={["fas", "search"]}
                      className="text-primary"
                    />
                  </button>
                </div>
              )}
          </Link>
        </div>
        <div className="  px-0 d-none d-sm-block ">
          {isLogged ? null : ( // </button> //   <FontAwesomeIcon icon="fa-solid fa-user" /> // > //   onClick={() => navigate("/profile")} //   className="btn text-light " // <button
            <button
              className="btn text-light  "
              onClick={() => navigate("/login")}
            >
              {/* <FontAwesomeIcon
                icon="fa-solid fa-right-from-bracket"
                data-bs-placement="bottom"
                title="Login"
              /> */}
              Login
            </button>
          )}
        </div>

        {/* <div style={{ width: isLogged ? "100px" : "0px", }}>
          {(Location.pathname === "/" && isLogged) && (
            <>
              {EditButton && (
                <div className="row  ">
                  <div className="col">
                    <p className=" d-none d-md-block text-light " style={{ margin: 0 }}> Edit</p>
                  </div>

                  <div className="form-check form-switch col ">
                    <input
                      className="form-check-input  border border-white"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={EditButtonValue}
                      // onClick={() => setEditButtonValue(!EditButtonValue)}
                      onClick={handleButtonClick}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div> */}


{Location.pathname === "/" && (
          <>
            {EditButton && (
              <div className="row mt-3">
                <div className="col">
                  <p className=" d-none d-md-block text-light "> Edit</p>
                </div>

                <div className="form-check form-switch col ">
                  <input
                    className="form-check-input  border border-white"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    checked={EditButtonValue}
                    // onClick={() => setEditButtonValue(!EditButtonValue)}
                    onClick={handleButtonClick}
                  />
                </div>
              </div>
            )}
          </>
        )}



        <button
          to="/addtocart"
          className="btn text-light    position-relative  d-none d-md-block "
          replace
          // onClick={changeNav}
          onClick={() => navigate("/addtocart")}
        >
          <FontAwesomeIcon
            icon={["fas", "shopping-cart"]}
            data-bs-placement="bottom"
            title="Cart"
          />

          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
            {counter === 0 ? "" : counter}
          </span>
        </button>

        <div className="">
          <FontAwesomeIcon
            icon="fa-regular fa-user"
            type="button"
            className="  navbar-toggler "
            //  style={{height:30 ,width:13}}
            //border border-light rounded-5
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
          />
        </div>

        <div
          className="offcanvas offcanvas-end  bg-primary "
          // style={{
          //   background: BACKGROUND,

          // }}
          tabIndex={-1}
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5
              className="offcanvas-title text-light"
              id="offcanvasDarkNavbarLabel"
            >
              <img src="./../favicon.ico" class="img me-2" alt="appLogo"></img>
              JIVDANI HOSPITAL
            </h5>

            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>

          <div className="offcanvas-bodyn ">
            <ul className="navbar-nav justify-content-end flex-grow-1 px-4">
              <li className="nav-item text-truncate ">
                <Link
                  className="nav-link text-light "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/orderhistory")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-clock-rotate-left"
                    className="me-3"
                  />
                  Booking History
                </Link>
              </li>

              <li className="nav-item text-truncate">
                <Link
                  className="nav-link  text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/tokenbooking")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-list-ol"
                    className="me-3 text-light"
                  />
                  View all Tokens
                </Link>
              </li>

              {showEdit ? (
                <li className="nav-item text-truncate">
                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/createToken")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-list-ol"
                      flip
                      className="me-3 text-light"
                    />
                    Create Token
                  </Link>

                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/tokenPage")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      // flip
                      className="me-3 text-light"
                    />
                    Token report
                  </Link>
                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/drsignup")}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-user-doctor"
                      className="me-3 text-light"
                    />
                    Create new doctor
                  </Link>

                  <Link
                    className="nav-link  text-light"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    replace
                    onClick={() => navigate("/mastermedicine")}
                  >
                    <FontAwesomeIcon
                      icon="fas fa-file-medical"
                      className="me-3 text-light"
                    />
                    Master
                  </Link>
                </li>
              ) : null}

              {isLab && <Link
                className="nav-link  text-light"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                replace
                onClick={() => navigate("/tokenPage")}
              >
                <FontAwesomeIcon
                  icon="fa-solid fa-database"
                  // flip
                  className="me-3 text-light"
                />
                Lab report
              </Link>}

              <li className="nav-item ">
                <Link
                  // to="/contact"
                  className="nav-link  text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/contact")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    style={{ paddingRight: 11 }}
                  />
                  ContactUs
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  // to="/aboutgridcard"
                  className="nav-link text-light"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  replace
                  onClick={() => navigate("/aboutus")}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-address-card"
                    // className="me-2"
                    style={{ paddingRight: 11 }}
                  />
                  AboutUs
                </Link>
              </li>

              <li className="nav-item pb-2">
                <FontAwesomeIcon
                  icon={["fas", "shopping-cart"]}
                  className="pe-0  text-light"
                />
                <Link
                  to="/addtocart"
                  className="btn text-light  position-relative  d-lg-inline"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  onClick={() => navigate("/addtocart")}
                >
                  <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
                    {counter === 0 ? "" : counter}
                  </span>
                  Cart
                </Link>
              </li>
              <li className="nav-item pb-2">
                <FontAwesomeIcon
                  icon="fa-solid fa-video"
                  flip
                  className="text-light"
                />
                <Link
                  to="/video"
                  className="btn text-light  position-relative  d-lg-inline"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  onClick={() =>
                    navigate(
                      "/video"
                      //localStorage.setItem("myDataview", you_tube_view)
                    )
                  }
                >
                  Videos
                </Link>
              </li>
              <li className="nav-item pb-2">
                <FontAwesomeIcon
                  icon="fa-solid fa-newspaper"
                  beatFade
                  className="text-light"
                />
                <Link
                  to="/article"
                  className="btn text-light  position-relative  d-lg-inline"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  type="button"
                  replace
                  // onClick={() => navigate("/article")}
                  onClick={() => navigate("/reportMain")}
                >
                  Article
                </Link>
              </li>
              <li className="nav-item">
                <RenderMenu />
              </li>
            </ul>
          </div>
        </div>
        {/* 
        <div className="col-12   d-none d-md-block  gap-5 text-center fw-semibold text-white mt-3 ">
          <Link to="/" style={{ textDecoration: "none", marginBottom: "0" }}>
            <small className="p  mx-3 text-white "> Home </small>
          </Link>
          <Link
            to="/aboutus"
            style={{ textDecoration: "none", marginBottom: "0" }}
          >
            <small className="p  mx-3 text-white"> AboutUs </small>
          </Link>
          <Link
            to="/contact"
            style={{ textDecoration: "none", marginBottom: "0" }}
          >
            <small className="p  mx-3 text-white "> ContactUs </small>
          </Link>
         

          <Link
            to="/tokenbooking"
            style={{ textDecoration: "none", marginBottom: "0" }}
          >
            <small className="p  mx-3 text-white"> View all Tokens </small>
          </Link>

          {showEdit && (
            <Link
              to="/createToken"
              style={{ textDecoration: "none", marginBottom: "0" }}
            >
              <small className="p  mx-3 text-white">Create Token</small>
            </Link>
          )}

          <Link
            to="/itemsviewmore"
            onClick={() => data(catListData, CARD_VIEW)}
            style={{ textDecoration: "none", marginBottom: "0" }}
          >
            <small className="p  mx-3 text-white "> Doctors </small>
          </Link>
          <Link
            to="/orderhistory"
            style={{ textDecoration: "none", marginBottom: "0" }}
          >
            <small className="p  mx-3 text-white "> Booking History </small>
          </Link>

        </div> */}

        <div className="col-12 d-none d-md-block  text-center fw-semibold text-white mt-3 ms-4">

          <Link
            to="/"
            style={getUnderlineStyle("/", Location.pathname, hoveredLink === "/")}
            onMouseEnter={() => setHoveredLink("/")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Home</small>
          </Link>

          <Link
            to="/aboutus"
            style={getUnderlineStyle("/aboutus", Location.pathname, hoveredLink === "/aboutus")}
            onMouseEnter={() => setHoveredLink("/aboutus")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">About Us</small>
          </Link>

          <Link
            to="/contact"
            style={getUnderlineStyle("/contact", Location.pathname, hoveredLink === "/contact")}
            onMouseEnter={() => setHoveredLink("/contact")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Contact Us</small>
          </Link>

          <Link
            to="/tokenbooking"
            style={getUnderlineStyle("/tokenbooking", Location.pathname, hoveredLink === "/tokenbooking")}
            onMouseEnter={() => setHoveredLink("/tokenbooking")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">View All Tokens</small>
          </Link>

          {showEdit && (
            <Link
              to="/createToken"
              style={getUnderlineStyle("/createToken", Location.pathname, hoveredLink === "/createToken")}
              onMouseEnter={() => setHoveredLink("/createToken")}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <small className="mx-2 text-white">Creat Token</small>
            </Link>
          )}

          <Link
            to="/itemsviewmore"
            style={getUnderlineStyle("/itemsviewmore", Location.pathname, hoveredLink === "/itemsviewmore")}
            onMouseEnter={() => setHoveredLink("/itemsviewmore")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Doctors</small>
          </Link>
          <Link
            to="/orderhistory"
            style={getUnderlineStyle("/orderhistory", Location.pathname, hoveredLink === "/orderhistory")}
            onMouseEnter={() => setHoveredLink("/orderhistory")}
            onMouseLeave={() => setHoveredLink(null)}
          >
            <small className="mx-2 text-white">Booking History</small>
          </Link>
        </div>

        <div className="col-12  d-flex  d-block d-md-none  gap-1 text-center fw-semibold text-light ">
          <div className="input-group mx-auto ">
            <span
              className="input-group-text text-primary"
              id="inputGroup-sizing-default"
            >
              <FontAwesomeIcon
                icon={["fas", "search"]}
                data-bs-placement="bottom"
                title="Search"
              />
            </span>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              placeholder="Appointment, Dr.Booking, Lab test"
            />
          </div>

          <button
            to="/addtocart"
            className="btn text-light    position-relative border border-light p-2 rounded "
            replace
            // onClick={changeNav}
            onClick={() => navigate("/addtocart")}
          >
            <FontAwesomeIcon
              icon={["fas", "shopping-cart"]}
              data-bs-placement="bottom"
              title="Cart"
            />

            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              {counter === 0 ? "" : counter}
            </span>
          </button>
        </div>
      </div>
    </nav>

    // </div>
  );
};

export default Header;
