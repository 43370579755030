
import Headline from "../ Headline";
import FullBodyView from "./FullBodyView";


const FullBodyCheakup = ({ item, navigate, showEdit, nama ,detailed}) => {
  return (
    <>
      {/* <div class="d-flex bd-highlight m-3">
        <div class="me-auto p-2 bd-highlight">
          <h3>{item.title}</h3>
        </div>
        <div class="p-2 bd-highlight">
      
          <div className="">
            {showEdit ? (
              <div>
                <Link
                  // to={"/products"}
                  to={"/itemsviewmore"}
                  onClick={() =>
                    navigate(
                      localStorage.setItem("myData", JSON.stringify(item))
                    )
                  }
                  type="button"
                  class="btn btn-primary me-md-2"
                >
                  View all
                </Link>
                <Link
                  to={"/addcat"}
                  className="btn btn-primary "
                  onClick={() =>
                    navigate(
                      localStorage.setItem("catData", JSON.stringify(item))
                    )
                  }
                  type="submit"
                >
                  <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                  Edit
                </Link>
              </div>
            ) : null}
          </div>
        </div>
       
      </div> */}
      <Headline
        item={item}
        navigate={navigate}
        showEdit={showEdit}
        nama={nama}
      />

      <div className="d-flex flex-row flex-nowrap overflow-auto gap-2 ">
        {/* <div className="row row-cols-2 row-cols-md-2  row-cols-lg-4  row-cols-xl-4 g-1  mb-2"> */}
        {/* <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4 px-md-6 pt-1"> */}
        {item.items.map((it) => (
          // <div class="row gy-3">
          //   <div class="col-sm">
          <FullBodyView
            key={it.itemId}
            product={it}
            showEdit={showEdit}
            detailed={detailed}
          />
          //   </div>
          // </div>
        ))}
      </div>
    </>
  );
};

export default FullBodyCheakup;
