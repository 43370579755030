import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  BASE_URL,
  DeleteCategory,
  // AddCategory,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
  AddDoctorDates,
  GetDoctorDatesListsByDoctorIdAndDates,
  AddDoctorDatesList,
} from "../Constant";
import axios from "axios";
import moment from "moment";
import AddStyle from "./AddStyle.css";
import swal from "sweetalert";

export default function AddDate() {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };

  const myData = localStorage.getItem("catItem");
  const product = JSON.parse(myData);
  let [activ, setActiv] = useState(` `);
  const [slots, setSlots] = useState([]);
const [dateBooking,setdateBooking]=useState([])
  // let dateBooking = [];

  const totalSteps = 28;
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(!true);
  const [slotList, setSlotList] = useState([]);
  const [Checkbox, setCheckbox] = useState(false);

  
  const [category, setCategory] = useState({
    doctorId: JSON.stringify( product.doctorId),
    date: moment().format("YYYY-MM-DD"),
    startDate :"",
    endDate :"",
    week:"",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCategory({ ...category, [e.target.name]: value });
  };

  const addDateInList = (e) => {
    e.stopPropagation();
    const date_value = e.target.value;
    e.preventDefault();
    // category.subTitle === "" ||
    //   category.subTitleID === "" ||
    //   category.viewType === "" ||
    //   category.iconURL === ""
    if (
      category.doctorId === "" ||
      category.date === undefined ||
      category.date === ""
    ) {
      swal(" Please enter all fields ");
    } else {
      setSlotList();
      // const categoryData = JSON.stringify({
      //   pubInfo: {
      //     sessionId: localStorage.getItem(SESSION_ID),
      //   },
      //   request: {
      //     busiParams: {
      //       doctorId: category.doctorId,
      //       // date: moment(category.date).format("YYYY-MM-DD"),
      //       date: date_value,
      //     },
      //     isEncrypt: false,
      //     transactionId: "897987987989",
      //   },
      // });

      // {
      //   "busiCode": "string",
      //   "busiParams": {
      //     "doctorDateId": 0,
      //     "date": "string",
      //     "doctorId": 0,
      //     "slotList": [
      //       {
      //         "slotId": 0,
      //         "doctorDateId": 0,
      //         "startTime": "string",
      //         "endTime": "string",
      //         "slotStatus": 0
      //       }
      //     ]
      //   },
    }
  };
  const saveCategory = () => {
    //  e.stopPropagation();

    // const date_value = e.target.value;

    // dateBooking.sort().map((item) => {
    //  if (DEBUG_LOG_ENABLED) {
    //    console.log(item);
    //  }
    console.log(dateBooking);

    if (
      category.doctorId === "" ||
      category.date === undefined ||
      category.date === ""
    ) {
      swal(" Please enter all fields ");
    } else {
      const categoryData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: dateBooking,
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      // {
      //   "busiCode": "string",
      //   "busiParams": {
      //     "doctorDateId": 0,
      //     "date": "string",
      //     "doctorId": 0,
      //     "slotList": [
      //       {
      //         "slotId": 0,
      //         "doctorDateId": 0,
      //         "startTime": "string",
      //         "endTime": "string",
      //         "slotStatus": 0
      //       }
      //     ]
      //   },

      axios
        .post(
          BASE_URL + AddDoctorDatesList,
          // "https://jivdanihospital.com/jivdani-v1/addDoctorDatesList",
          categoryData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        )

        .then((response) => {
          swal(response.data.respData.message);

          // navigate("/");
          if (DEBUG_LOG_ENABLED) {
            console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 404) {
            navigate("/login");
          } else {
            swal("data : " + err);
            if (DEBUG_LOG_ENABLED) {
              console.log(err);
            }
          }
        });
    }
    // });
  };

  const removeCatData = async (e) => {
    e.stopPropagation();
    try {
      const removeCat = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            catId: category.catId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + DeleteCategory, removeCat, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      swal(response.data.respData.message);
      // navigate("/");
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 404) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeCat = () => {
    removeCatData();
  };

  //  let newdate=[];

  function dateSlotBoking(e) {
    e.stopPropagation();
    // handleClick()

    const date = e.target.value;
    const doctorId = category.doctorId;
     const Bookingdate = [];
    Bookingdate.push({ date, doctorId });
    setdateBooking(Bookingdate);

  
  }

  // const handleClick = () => {
  //   setActiv((current) => !current);
  // };

  const sumbit = () => {
    dateBooking.sort().map((item) => {
      console.log(item);
    });
  };

  useEffect(() => {
    getslot();
    Generatedate();
  }, []);

  const showDate = [];
  const [newdate, setNewdate] = useState([]);

  const Generatedate = () => {
    {
      [...Array(totalSteps)?.keys()].map((idx) =>
        showDate.push(
          moment(moment(new Date(), "YYYY-MM-DD").add(idx, "days")).format(
            "YYYY-MM-DD"
          )
        )
      );
    }
    setNewdate(showDate);
  };
  const getslot = async () => {
    try {
      var endDate = moment(new Date(), "YYYY-MM-DD").add(28, "days");
      const dateData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            doctorId: product?.doctorId,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + GetDoctorDatesListsByDoctorIdAndDates,
        dateData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal(response.data);
      //  const date = response.data.respData.respMsg.doctorDatesList;
      // setSlots( response.data.respData.respMsg.doctorDatesList.sort(
      //   (a, b) => a.date - b.date
      // ))

      const numAscending = [
        ...response.data.respData.respMsg.doctorDatesList,
      ].sort((a, b) => (a.date > b.date) - (a.date < b.date));
      setSlots(numAscending);
      console.log(numAscending);
      //  setSlots(date);

      if (DEBUG_LOG_ENABLED) {
        // console.log(response.data);
      }

      // console.log(response.data);
    } catch (error) {
      swal("error : " + error);
      if (DEBUG_LOG_ENABLED) {
        console.log(error);
      }
    }
  };

  const Compare = () => {
    // console.log(slots);
    // console.log(newdate);
    slots.map((item) => {
      console.log(item.date);
    });

    {
      JSON.stringify(slots).includes("2023-09-09")
        ? console.log("true")
        : console.log("false");
    }
  };

  const AllDateAdd = () => {
   if(!Checkbox){
    const doctorId = category.doctorId;
     const Booking =[];
    {
      newdate.map((date, index) => { Booking.push({ date, doctorId });
      setdateBooking(Booking);
    
    })  ;
    }
    setCheckbox(!Checkbox);
  }else{
    setdateBooking([])
     setCheckbox(!Checkbox);

  }
  };

  const ok =()=>{
    console.log(dateBooking);
  }

  const [Data, setdata] = useState(true); 

    const addDoctorDatesMonthWise = async () => {
      try {
       
        const dateData = JSON.stringify({
          pubInfo: {
            sessionId: localStorage.getItem(SESSION_ID),
          },
          request: {
            busiParams: {
              doctorId: category?.doctorId,
              startDate: category.startDate,
              endDate: category.endDate,
              // week: {
              //   1: category.week,
              // },
              // month: 12,
              days: category.week,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });
        let response = await axios.post(
          BASE_URL + "addDoctorDatesMonthWise",
          dateData,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
              "Access-Control-Allow-Headers": "Content-Type",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
            },
          }
        );

      const DATA=response.data.respData.respMsg.doctorDatesList;
      swal(response.data.respData.message);

       
      } catch (error) {
        swal("error : " + error);
        if (DEBUG_LOG_ENABLED) {
          console.log(error);
        }
      }
    };



  return (
    <>
      <div className="container">
        <ScrollToTopOnMount />

        <div className="card " style={{ marginTop: 120 }}>
          <div className="card-header ">
            <button
              type="button"
              class="btn btn-outline-primary me-3"
              onClick={() => setdata(true)}
            >
              date update
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={() => setdata(false)}
            >
              custom date update
            </button>
          </div>
        </div>
        {Data ? (
          <div className=" card p-2 ">
            <h4 className="Auth-form-title">Add Date Slot</h4>
            <div className="form-group mt-3">
              <label>Doctor Id</label>
              <input
                type="text"
                name="doctorId"
                value={category.doctorId}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>
            <div className="form-group mt-3">
              <label>Start Date</label>
              <input
                data-date-format="YYYY-MM-DD"
                type="date"
                name="startDate"
                value={category.startDate}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>
            <div className="form-group mt-3">
              <label>End Date</label>
              <input
                data-date-format="YYYY-MM-DD"
                type="date"
                name="endDate"
                value={category.endDate}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>
            <div className="form-group mt-3">
              <label>Days (ex. sunday=1,monday=2,3,)</label>
              <input
                type="text"
                name="week"
                value={category.week}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>

            <div className="d-grid gap-2 mt-3">
              <button
                // onClick={saveCategoryItem}
                type="submit"
                className="btn btn-primary   mt-3"
                onClick={removeCat}
              >
                <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
              </button>
              <button
                // onClick={ok}
                onClick={() => addDoctorDatesMonthWise()}
                type="submit"
                className="btn btn-primary   mt-3"
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className=" card p-2 ">
            <h4 className="Auth-form-title">Add Date Slot</h4>
            <div className="form-group mt-3">
              <label>Doctor Id</label>
              <input
                type="number"
                name="doctorId"
                value={category.doctorId}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>
            <div className="form-group mt-3">
              <label>Date for slot</label>
              <input
                data-date-format="YYYY-MM-DD"
                type="date"
                name="date"
                value={category.date}
                onChange={(e) => handleChange(e)}
                className="form-control mt-1"
              />
            </div>
            <button
              // onClick={saveCategoryItem}
              type="submit"
              className={`btn  h3 btn-${
                Checkbox ? "primary mt-3" : "outline-primary mt-3"
              }`}
              onClick={AllDateAdd}
            >
              Select all
            </button>
            {/* <input
            type="checkbox"
            name="myCheckbox"
            className="p-5"
          
            checked={Checkbox}
            onClick={() => setCheckbox(!Checkbox)}
          />
          Checkbox */}
            {/* <div>
            {dateBooking.map((item, index) => (
              <div> {item.date} </div>
            ))}
          </div> */}
            <div class="  py-5 row row-cols-3 row-cols-sm-5 row-cols-md-8">
              {newdate.map((item) => (
                <div>
                  <div class="col my-2">
                    {/* {"date "+  moment( moment(new Date(), "YYYY-MM-DD").add(idx, 'days')).format("YYYY-MM-DD")} */}
                    {/* <button
                    id="inx"
                    // onClick={saveCategory}
                    onClick={(e) => {
                      dateSlotBoking(e);
                    }}
                    // disabled={idx ? true : false}
                    type="submit"
                    className="btn btn-outline-primary"
                    value={moment(
                      moment(new Date(), "YYYY-MM-DD").add(idx, "days")
                    ).format("YYYY-MM-DD")}
                    // className="btn btn-outline-primary "
                  >
                    {moment(
                      moment(new Date(), "YYYY-MM-DD").add(idx, "days")
                    ).format("YYYY-MM-DD")}
                  </button> */}

                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <input
                        disabled={
                          JSON.stringify(slots).includes(item) ? true : false
                        }
                        type="checkbox"
                        className="btn-check"
                        id={item}
                        autoComplete="off"
                        value={item}
                        onClick={(e) => {
                          dateSlotBoking(e);
                        }}
                      />
                      <label
                        // className=  {'btn        btn-${outline-primary }   '}
                        className={`btn  h3 btn-${
                          Checkbox ? "primary" : "outline-primary"
                        }`}
                        htmlFor={item}
                        value={item}
                      >
                        {item}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-grid gap-2 mt-3">
              <button
                // onClick={saveCategoryItem}
                type="submit"
                className="btn btn-primary   mt-3"
                onClick={removeCat}
              >
                <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
              </button>
              <button
                // onClick={ok}
                onClick={saveCategory}
                type="submit"
                className="btn btn-primary   mt-3"
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
