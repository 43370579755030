import React from "react";

const PlusButton = ({ setOpen, open }) => {
  return (
    <div className="card d-flex justify-content-center align-items-center   col-xxl-mt-2"
      style={{ height: "32px", width: "35px", cursor: "pointer" }}
      onClick={() => setOpen(!open)}>
      {open ? "-" : "+"}
    </div>

  );
};

export default PlusButton;

///  const [isOpen, setIsOpen] = useState(false);
////  <PlusButton setOpen={setIsOpen} open={isOpen} />
