import React, { useState, useRef, useEffect } from "react";
import CustomTooltip from "../CustomTooltip";

import {
  customSelectStyles,
  DropdownIndicator,
  IconFunctionalityOption,
  IndicatorSeparator,
} from ".././ui/CSS/customStyles";
import Select, { components, MenuListProps } from "react-select";
import apiHelper from "../ApiHelpar/apiHelper";
import { SERCH_TEMPLATE_BY_CAT_ID_AVD_TEMPNAME } from "../visitConstant/ApiConstant";
import useDebounce from "../useDebounce/useDebounce";
import { DataConvertecheck } from "../DataConverte/DataConverte";
//from "../../ui/CSS/customStyles";

function DropdownComponent({
  icon,
  tooltipText,
  options,
  handleOnChange,
  onSubmit,
  templateName,
  medicineText,
  catID,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  // Handle the click on the icon to toggle dropdown
  const handleIconClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close the dropdown when focus is lost
  const handleBlur = (e) => {
    // Check if the new focused element is outside the dropdown
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setIsDropdownOpen(false);
    }
  };
  // Handle option selection
  const handleOptionChange = (option) => {
    setSelectedOption(option); // Update the selected option
    if (handleOnChange) {
      handleOnChange(option); // Notify parent about the change
    }
    setIsDropdownOpen(false);
    setSelectedOption(null);
  };

  const handleSubmit = async () => {

    const returdata = await onSubmit();
    if (returdata) {
      setIsDropdownOpen(false);
    }
  };
  const menuHeaderStyle = {
    padding: "2px 10px",
    fontSize: "15px",
    fontWeight: 500,

    // background: '#177ee0', // Custom header background color
    // color: 'white',
  };
  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {/* <div style={menuHeaderStyle}>Saved Templates</div>
        {props.children} */}
      </components.MenuList>
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#f5f8ff",
      borderColor: "#177ee0",
      "&:hover": {
        borderColor: "#0056b3",
      },
      boxShadow: "none",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#f9fafb",
      borderRadius: 0,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? "#177ee0"
        : isFocused
          ? "#cce0ff"
          : "#f5f8ff",
      color: isSelected ? "#fff" : "#177ee0",
      padding: "10px",
      fontWeight: isSelected ? "bold" : "normal",
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#cce0ff",
      color: "#177ee0",
      borderRadius: "5px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#177ee0",
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: "#177ee0",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#0056b3",
        color: "white",
      },
    }),
  };

  const [Loading, setLoading] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [DropdownData, setDropdownData] = useState(options);
  const debouncedInput = useDebounce(inputValue);

  useEffect(() => {
    if (debouncedInput) onInputChange(debouncedInput);
  }, [debouncedInput]);

  const onInputChange = async (SaechText) => {
    console.log(" serchapi icon   calling");
    setLoading(true);
    if (SaechText) {
      try {
        const busiParams = {
          categoryId: catID,
          name: SaechText,
        };

        const response = await apiHelper(
          SERCH_TEMPLATE_BY_CAT_ID_AVD_TEMPNAME,
          busiParams
        );
        const dataConvert = response?.respData?.respMsg;
        console.log("dataConvert", dataConvert);
        setDropdownData(DataConvertecheck(dataConvert));
        setLoading(false);
        // return { value: dataConvert.id, label: dataConvert.name };
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        console.log("ok");
        setLoading(false);
      }
    }
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <span className="" onClick={handleIconClick}>
        <CustomTooltip
          icon={icon}
          tooltipText={tooltipText}
          mainText={medicineText ? medicineText : null}
        />
      </span>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          onBlur={handleBlur}
          tabIndex={-1}
          style={{
            position: "absolute",
            top: "100%", // Position below the icon
            // left: "-100%",
            backgroundColor: "#fff",
            border: options ? "" : "1px solid #ccc",
            padding: "10px",
            zIndex: 1,
            justifyContent: "center",
            justifyItems: "center",
            width: "auto",
            borderRadius: 10,
          }}
        >
          {options ? (
            <div style={{ width: "195px", justifyContent: "center", alignItems: "center" }}>
              <Select
                options={DropdownData?.length > 0 ? DropdownData : options}
                // options={options}
                value={selectedOption}
                autoFocus
                onChange={handleOptionChange}
                onInputChange={(item) => {
                  setInputValue(item);
                }}
                openMenuOnFocus={true}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator, IndicatorSeparator }}
                styles={IconFunctionalityOption}
                isLoading={Loading}
              />
            </div>
          ) : (
            <div className="">
              <input
                type="text"
                className="form-control"
                placeholder="Template name"
                autoFocus
                value={templateName}
                onChange={(e) => handleOnChange(e.target.value)}
                style={{ width: 200 }}
              />
              <button
                className="btn btn-primary mt-2"
                type="button"
                style={{ width: 200 }}
                //  onClick={onSubmit}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DropdownComponent;
