import React, { useEffect, useState } from "react";
import UserSearchDropdown from "../report/CustomDropdown/UserSearchDropdown";
import apiHelper from "../report/ApiHelpar/apiHelper";
import useDebounce from "../report/useDebounce/useDebounce";
import { SUPER_ADNIM, USER_ROLE } from "../Constant";

const TokenForm = ({
  user,
  handleChange,
  saveUser,
  onSelect,
  inputValue,
  setInputValue,
  currentDate,
  setCurrentDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  // Use the debounced input outside the event handler
  const debouncedInput = useDebounce(inputValue, 1000);

  const [SuprAdmin, setSuprAdmin] = useState(false);
  const userRole = localStorage.getItem(USER_ROLE);

  useEffect(() => {
    if (userRole?.includes(SUPER_ADNIM)) {
      setSuprAdmin(true);
    }
  }, []);

  // Effect to handle debounced input change
  useEffect(() => {
    if (debouncedInput) {
      if (!isNaN(debouncedInput) && debouncedInput.trim() !== "") {
        // console.log("Yes, I am a number", Number(debouncedInput));
        fetchData({ mobileNo: debouncedInput }); // Call fetchData with mobileNo
      } else {
        //  console.log("String", debouncedInput);
        fetchData({ name: debouncedInput }); // Call fetchData with name
      }
    }
  }, [debouncedInput]); // Trigger this effect whenever the debounced input changes

  // Input change handler
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Update state with input value
  };

  const fetchData = async (SerchingValue) => {
    setLoading(true);
    setOptions("");
    try {
      const endpoint = "getDataByNameOrMobileNo";

      const data = SerchingValue; // Customize this based on your needs
      const response = await apiHelper(endpoint, data);
      console.log("API Response:", response.respData.respMsg);
      setOptions(response.respData.respMsg);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //   const handleAddNewItem = (name) => {
  //     // setLoading(true);
  //  //   alert(newItemName);

  //  handleChange("name", name);

  //   };

  const handleAddNewItem = (name) => {
    // Call handleChange with a synthetic event object
    // Create a fake event object to simulate `onChange` behavior
    const fakeEvent = {
      target: {
        name: "name", // Name should match the input's name attribute
        value: name,
      },
    };

    handleChange(fakeEvent);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    saveUser(); // Call the saveUser function to submit the form
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between align-items-center mb-3 pb-1">
        <span className="h1 fw-bold mb-0">Create Token</span>
        {SuprAdmin && (
          <input
            type="date"
            name="currentDate"
            onChange={(e) => setCurrentDate(e.target.value)}
            value={currentDate}
            className="form-control mt-1 me-3"
            style={{ maxWidth: "20%" }}
            required
          />
        )}
      </div>

      <div className="container form-outline mb-4">
        <label className="form-label" htmlFor="form2Example11">
          Patient Name
        </label>
        <input
          type="text"
          name="name"
          onChange={handleChange}
          value={user.name}
          className="form-control mt-1"
          placeholder="Enter patient name "
          required
        />

        {/* <UserSearchDropdown
          options={options}
          handleInputChange={setInputValue}
          onSelect={onSelect}
          value={user}
          rowIndex={1}
          loading={loading}
          Placeholder={"Enter patient name & mobile number"}
          onAddNewItem={handleAddNewItem}
          searchTerm={inputValue}
          newEntry={true}
        /> */}
      </div>

      <div className="container text-start">
        <div className="row row-cols-3">
          <div className="col">
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form2Example22">
                Mobile Number
              </label>
              <input
                name="mobileNo"
                onChange={handleChange}
                value={user.mobileNo}
                className="form-control mt-1"
                placeholder="Enter Mobile No"
                required
                type="tel"
                minLength="10"
                maxLength="12"
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form2Example22">
                Age
              </label>
              <input
                name="age"
                onChange={handleChange}
                value={user.age}
                className="form-control mt-1"
                placeholder="Enter Age"
                required
                type="number"
                min="1"
                max="120"
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline mb-4">
              <label className="form-label" htmlFor="form2Example22">
                Gender
              </label>
              <div class="align-self-center ">
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  defaultValue=""
                  name="gender"
                  onChange={handleChange}
                  value={user.gender}
                >
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="T">Other</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <label htmlFor="message-text" className="col-form-label">
          Address
        </label>
        <textarea
          name="address"
          className="form-control"
          id="message-text"
          onChange={handleChange}
          value={user.address}
        />
      </div>

      <div className="d-grid gap-2 mt-3">
        <button type="submit" className="btn btn-primary">
          Continue
        </button>
      </div>
    </form>
  );
};

export default TokenForm;
