import { Link } from "react-router-dom";
import parse from "html-react-parser";
// import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import DoctorDetail from "../slots_booking/DoctorDetail";
import Placeholder from "../../src/default_img/placeholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IMG_BASE_URL } from "../Constant";
import img from "../Images/doctor.png"
// import {
//   ACCESS_TOKEN,
//   CUSTOMER_ID,
//   SESSION_ID,
//   BASE_URL,
//   ADD_TO_CART,
//   DEBUG_LOG_ENABLED,
// } from "../Constant";

// import {  useDispatch } from "react-redux";
// import { increment } from "../actions";
// import axios from "axios";
// import ProductItem from "./../landing/ProductItem.css"





// const iconPath =
//   "M18.571 7.221c0 0.201-0.145 0.391-0.29 0.536l-4.051 3.951 0.96 5.58c0.011 0.078 0.011 0.145 0.011 0.223 0 0.29-0.134 0.558-0.458 0.558-0.156 0-0.313-0.056-0.446-0.134l-5.011-2.634-5.011 2.634c-0.145 0.078-0.29 0.134-0.446 0.134-0.324 0-0.469-0.268-0.469-0.558 0-0.078 0.011-0.145 0.022-0.223l0.96-5.58-4.063-3.951c-0.134-0.145-0.279-0.335-0.279-0.536 0-0.335 0.346-0.469 0.625-0.513l5.603-0.815 2.511-5.078c0.1-0.212 0.29-0.458 0.547-0.458s0.446 0.246 0.547 0.458l2.511 5.078 5.603 0.815c0.268 0.045 0.625 0.179 0.625 0.513z";
// function changeRating(newRating) {}

const imgg =
  "https://storage.googleapis.com/jivdanihospital.com/jivdani_img/Article/treatement.jpg";

function ProductItemCart({ product,showEdit }) {

  const navigate = useNavigate();
  

  return (
  

    <div className="card me-2" style={{ width: "15rem" }}>
      <Link
        to={"/doctordetail/" + product?.itemId}
        // onClick={()=>navigate("/about")

        onClick={() =>
          navigate(
            localStorage.setItem("myProduct", JSON.stringify(product, showEdit))
          )
        }
      >
      <img
        src={IMG_BASE_URL + product.iconURL}
        alt="A description of the image"
        onError={(event) => {
          event.target.src = img;
        }}
        className="card-img-top"
        style={{ height: 150, objectFit: "contain" }}
      />
     </Link>
      <div className="card-body">
        <h5 className="card-title">{product.title}</h5>

        <small className="card-text"> {parse(`${product.subTitle}`)}</small>
        <p className="card-text fw-bold">{"₹" + product.price}</p>

        <Link
          className="btn btn-primary px-4   "
          // style={{alignSelf:'end'}}
          // to={"/slotbooking"}
          to={
            product?.callingURL === "" || product?.callingURL === null
              ? "/slotbooking/" + product?.itemId
              : product.callingURL + product?.itemId
          }
          onClick={() =>
            navigate(
              localStorage.setItem(
                "myProduct",
                JSON.stringify(product, showEdit)
              )
            )
          }
          replace
        >
          Book now
        </Link>
      </div>
    </div>
  );
}

export default ProductItemCart;
