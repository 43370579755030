import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../ReduxApi/ReduxApi";
import IconFunctionality from "../IconFunctionality";
import Categories from "../visitConstant/Categories";
import PlusButton from "../ui/PlusButton";
import ToastFist from "../Toast/ToastFist";

const PhysicalExamination = ({ patientData }) => {
  const [selectValues, setSelectValues] = useState({
    breastExamination: "",
    perSpeculum: "",
    perAbdominalExamination: "",
    perVaginalExamination: "",
  });

  const dispatch = useDispatch();
  const [nadValue, setNadValue] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [TempletDataReserve, setTempletDataReserve] = useState();
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const categories = [
    { key: "breastExamination", placeholder: "Breast Examination..." },
    { key: "perSpeculum", placeholder: "Per Speculum..." },
    { key: "perAbdominalExamination", placeholder: "Abdominal Examination..." },
    { key: "perVaginalExamination", placeholder: "Vaginal Examination..." },
  ];

  // Effect to handle NAD checkbox change
  useEffect(() => {
    const newValues = categories.reduce((acc, category) => {
      acc[category.key] = nadValue ? "NAD" : "";
      return acc;
    }, {});

    setSelectValues(newValues);
    // Dispatch updated values to Redux
    dispatch(updateField("physicalExamination", newValues));

    // console.log("NAD Values Set: ", newValues);
  }, [nadValue, dispatch]);

  // Handle changes in textarea inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValues = {
      ...selectValues,
      [name]: value,
    };
    setSelectValues(newValues);
    dispatch(updateField("physicalExamination", newValues));
    // console.log("Handle Change: ", newValues);
  };

  // Access the data from Redux state to clear data
  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  // Handle clearing all data
  const handleClearData = useCallback(() => {
    const resetValues = {
      breastExamination: "",
      perSpeculum: "",
      perAbdominalExamination: "",
      perVaginalExamination: "",
    };
    setSelectValues(resetValues);
    dispatch(updateField("physicalExamination", resetValues));
  }, [dispatch]);

  // Effect to reset data when clearAllData is true
  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  // Effect to update state from patientData
  useEffect(() => {
    if (TempletDataReserve) {

      const formattedDoctors = {
        breastExamination: TempletDataReserve.breastExamination || "",
        perSpeculum: TempletDataReserve.perSpeculum || "",
        perAbdominalExamination:
          TempletDataReserve.perAbdominalExamination || "",
        perVaginalExamination: TempletDataReserve.perVaginalExamination || "",
      };

      if (formattedDoctors?.breastExamination ||
        formattedDoctors?.perSpeculum ||
        formattedDoctors?.perAbdominalExamination ||
        formattedDoctors?.perVaginalExamination) {
        setIsOpen(true);
      } else {
        setColorStatus(3)
        setToastMsg("Data not available")
        setShowToast(true);
      }

      setSelectValues(formattedDoctors);
      dispatch(updateField("physicalExamination", formattedDoctors));
    }
  }, [TempletDataReserve]);

  useEffect(() => {
    if (patientData?.physicalExamination != undefined || patientData?.physicalExamination != null) setIsOpen(true)
    setTempletDataReserve(patientData?.physicalExamination);
  }, [patientData?.physicalExamination]);



  const TempletDataTransfer = (TempletData) => {
    // console.log("TempletData", TempletData);
    setTempletDataReserve(TempletData);
  };

  return (
    <>
      <div className="d-flex">
        <IconFunctionality
          catID={Categories?.PHYSICAL_EXAMINATION?.catID}
          fieldName={Categories?.PHYSICAL_EXAMINATION?.name}
          name="Physical Examination"
          iconSave
          iconRefresh
          iconErase
          onClick={handleClearData}
          setTempData={TempletDataTransfer}
        />
        <PlusButton setOpen={setIsOpen} open={isOpen} />

        {isOpen && (
          <div className="form-check ms-3" style={{ marginTop: 2 }}>
            <input
              className="form-check-input"
              type="checkbox"
              id="markNAD"
              checked={nadValue}
              style={{ width: 22, height: 22 }}
              onChange={() => setNadValue(!nadValue)}
            />
            <label className="form-check-label ms-2" htmlFor="markNAD">
              Mark all fields as NAD
            </label>
          </div>
        )}
      </div>

      {/* <div className="col-12 col-md-10"> */}
      <div className="col-12 col-xxl-8   mx-xxl-auto" style={{ paddingLeft: 40 }}>
        {isOpen && (
          <div className="container mb-1">
            <div className="row row-cols-2 gx-5">
              {categories.map((category) => (
                <div className="col" key={category.key}>
                  <label htmlFor={category.key}>{category.placeholder}</label>
                  <textarea
                    className="form-control me-2 mb-2"
                    id={category.key}
                    name={category.key}
                    placeholder={category.placeholder}
                    value={selectValues[category.key]}
                    onChange={handleChange}
                    rows={1}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default PhysicalExamination;
