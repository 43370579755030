import React, { useEffect, useState } from "react";
import ReportHeader from "./ReportHeader";
import ReportFormDetail from "./ReportFormDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportHistory from "./ReportHistory";
import ReportFooter from "./ReportFooter";
import LeftMenuBar from "./LeftMenuBar";
import axios from "axios";

import {
  ACCESS_TOKEN,
  BASE_URL,
  DOCTOR_VISIT_DATA,
  DOCTOR_VISITPAD,
} from "../Constant";
import { Reactnativedatahshow } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchVisitPad } from "./ReduxApi/VistPadApiCall";
import { allVisitPadBackground } from "./visitConstant/repotOtherConstant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import moment from "moment";

const ReportMain = () => {
  const dispatch = useDispatch();
  const [DropdownData, setDropdownData] = useState([]);

  // const location = useLocation();
  // const receivedData = location.state;

  const location = useLocation();
  const [receivedData, setReceivedData] = useState(location.state || {});

  useEffect(() => {
    // If you expect location.state to change, you can set it in state here
    setReceivedData(location.state);
  }, [location.state]); // Update whenever location.state changes

  const lastHistory = useSelector((state) => state?.visit?.lastHistory);
  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const loading = useSelector((state) => state?.visitPad?.loading);
  const error = useSelector((state) => state?.visitPad?.error);


  const token = useSelector((state) => state.userToken.userToken);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchVisitPad(navigate));

    dispatch(Reactnativedatahshow("DATASHOW"));

    return () => {
      dispatch(Reactnativedatahshow(""));
    };
  }, [dispatch]);

  // console.log(lastHistory, ' MONKEY ')
  const [RootTemplateData, setRootTemplateData] = useState("");
  const { loadings, rootTemplateData, rootTemplateError } = useSelector(
    (state) => state.rootTemplate
  );

  // useEffect(() => {
  //   console.log("lastHistory===>>>>>>>======>>>>", lastHistory);
  //   // setRootTemplateData(rootTemplateData)
  //   const TokanData = location.state;
  //   console.log(TokanData, "TokanData");

  //  if(TokanData.name === lastHistory.customerName ||TokanData.mobileNo === lastHistory.phoneNo){
  //   if (lastHistory) {
  //     setRootTemplateData(lastHistory);
  //   }

  //  } 


  // }, [lastHistory]);


  useEffect(() => {
    // Ensure both TokanData and lastHistory are valid objects before proceeding
    if (lastHistory && location.state) {
      const { name, mobileNo } = location.state; // Destructure to clean up code

      // console.log("lastHistory:", lastHistory);
      // console.log("TokanData:", { name, mobileNo });

      // Check if the customerName or phoneNo matches the TokanData
      const isMatchingData =
        name === lastHistory?.customerName || mobileNo === lastHistory?.phoneNo;

      // If matching data, set root template data
      if (isMatchingData) {
        setRootTemplateData(lastHistory);
      }
    }
  }, [lastHistory, location.state]); // Add all necessary dependencies




  useEffect(() => {
    // console.log("Rootloading===>>>>>>>======>>>>", rootTemplateData)
    // setRootTemplateData(rootTemplateData)

    if (rootTemplateData) {
      setRootTemplateData(rootTemplateData);
    }
  }, [rootTemplateData]);

  const patient = ""; //jsonData?.respData?.respMsg[0];

  // {lastHistory?.date === moment().format("YYYY-MM-DD") ? (
  //   ""
  // ) : (
  //   <NewVisitCaed />
  // )}

  // console.log(
  //   "receivedData",
  //   receivedData
  // );
  // console.log(receivedData?.date, " lastHistory?.date");
  return (
    <div
      className=""
      style={{
        overflow: "hidden",
        backgroundColor: allVisitPadBackground,
        marginTop: 100,
      }}
    >
      <ScrollToTopOnMount />
      <div className="w-100">
        <ReportHeader receivedDatas={receivedData} />
      </div>

      <div className="row  ">
        <div
          className="col bg-white d-flex flex-column align-items-center py-3"
          style={{ maxWidth: 80, overflow: "auto", }}
        >
          <LeftMenuBar />
        </div>

        {/* <div className="col-11 overflow-auto" style={{ height: 'calc(100vh - 60px)' }}> */}
        <div
          className="parttwo col "
          style={{
            // height: "calc(98vh - 60px)",
            height: "calc(85vh - 85px)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          {receivedData &&
            receivedData?.date &&
            receivedData?.date !== moment().format("YYYY-MM-DD") ? (receivedData?.tokenId == token && <div className="m-3">
              {!visitPadData ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ minHeight: "100vh" }}
                >
                  <div
                    className="spinner-border text-primary fs-3"
                    role="status"
                    style={{
                      alignItems: "center",
                      alignSelf: "center",
                      marginBottom: 120,
                      height: 50,
                      width: 50,
                    }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <ReportFormDetail patientData={RootTemplateData} />
              )}

              <ReportFooter
                receivedDatas={receivedData}
                patientData={RootTemplateData}
              />
            </div>) : (
            <div className="m-3">
              {!visitPadData ? (
                <div
                  className="d-flex justify-content-center"
                  style={{ minHeight: "100vh" }}
                >
                  <div
                    className="spinner-border text-primary fs-3"
                    role="status"
                    style={{
                      alignItems: "center",
                      alignSelf: "center",
                      marginBottom: 120,
                      height: 50,
                      width: 50,
                    }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <ReportFormDetail patientData={RootTemplateData} />
              )}

              <ReportFooter
                receivedDatas={receivedData}
                patientData={RootTemplateData}
              />
            </div>
          )}

          <div className="mt-5">
            <ReportHistory receivedDatas={receivedData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportMain;
