import Ratings from "react-ratings-declarative";
import { Link } from "react-router-dom";

import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { useState, useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import {
  ACCESS_TOKEN,
  CUSTOMER_ID,
  SESSION_ID,
  BASE_URL,
  ADD_TO_CART,
  DEBUG_LOG_ENABLED,
  getCategoryItemByItemId,
  you_tube_view01,
  GENERATEUSERTOKEN,
  Tokan_Type_RequestCall,
  Tokan_Status_In_Queue,
  Request_Call_Msg,
  ROLE,
  USER_ROLE,
} from "../Constant";
import { useSelector, useDispatch } from "react-redux";

import { increment, decrement, handleLoggin } from "../actions";
import { useNavigate } from "react-router-dom";

import FeatureProduct from "../landing/FeatureProduct";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axios from "axios";

import { useParams } from "react-router-dom";
import swal from "sweetalert";
import "./Dialysis.css";

const Dialysis = () => {
  const [selectedOption, setSelectedOption] = useState("");
  let [showEdit, setShowEdit] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.terget.value;
    setSelectedOption(value);
  };

  const showAlert = () => {
    alert(`Please Select option : ${selectedOption}`);
    console.log(`selected : ${setSelectedOption}`);
  };
  // const

  let [hindi, setHindi] = useState(true);
  const changeHindi = () => {
    setHindi(false);
  };
  const changeEnglish = () => {
    setHindi(true);
  };

  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    address: "",
    date: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const savaUser = (e) => {
    e.preventDefault();
    if (user.name === "" || user.mobileNo === "") {
      // props.showAlert("Please entery username and mobail no", "success");
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <svg
          class="bi flex-shrink-0 me-2"
          width="24"
          height="24"
          role="img"
          aria-label="Danger:"
        >
          <use class="#exclamation-triangle-fill" />
        </svg>
        <div>Please entery username and mobail no., success</div>
      </div>;

      //  alert("Please entery username and mobail no ");
      //  showAlert("Please entery username and mobail no", "success");
    } else {
      userData(user.name, user.mobileNo);
    }
  };
  const userData = async (name, mobileNo) => {
    try {
      const userData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            name: name,
            date: "" + moment(new Date()).format("YYYY-MM-DD"),
            mobileNo: mobileNo,
            address: "",
            type: Tokan_Type_RequestCall,
            status: Tokan_Status_In_Queue,
            comment: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + GENERATEUSERTOKEN, userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
        },
      });
      swal(Request_Call_Msg);
    } catch (err) {
      swal(err);
      console.log(err);
    }
  };
  useEffect(() => {
    //  if (itemList === null) {
    //    getCategoryItem();
    //  } else if (urlItemId != itemList?.itemId) {
    //    //  getCategoryItem();
    //  }

    //fileContent();
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    }
  }, []);

  return (
   

    <>
      <ScrollToTopOnMount />
      <div className=" d-flex justify-content-around  mt-5 ">
        <h1 className=" pt-4 fs-1 text-center ">Dialysis Cost at Jivdani</h1>
        <div className=" btn-group pb-3 mt-5  ">
          <button
            className=" rounded-start-pill btn btn-outline-primary "
            onClick={changeEnglish}
          >
            English
          </button>
          <button
            className="  rounded-end-pill btn btn-outline-primary"
            onClick={changeHindi}
          >
            hindi
          </button>
        </div>
      </div>

      <div className="ff d-flex justify-content-evenly  mt-2">
        <div className=" pt-5">
          <div className="bs d-flex   align-items-end  gap-3">
            <div className="text-center">
              <div className="box1  border rounded-top"></div>
              <h4>₹2200</h4>
              <p className="fw-light">Minimum cost</p>
            </div>
            <div className="text-center">
              <div className="box2 border rounded-top"></div>
              <h4>₹3000</h4>
              <p className="fw-light">Minimum cost</p>
            </div>
            <div className="text-center">
              <div className="box3  bg-primary border rounded-top"></div>
              <h4>₹8000</h4>
              <p className="fw-light">Minimum cost</p>
            </div>
          </div>
          <hr />
        </div>
        <div className="fro border rounded my-1 p-4 ">
          <div className="card-body">
            <h5 className="text-dark text-center text-md-start">
              Request Call Back
            </h5>

            <form className="  justify-content-center justify-content-md-start mb-3">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  value={user.name}
                  onChange={(e) => handleChange(e)}
                  className="form-control border border-primary my-3"
                  required
                />
                <input
                  name="mobileNo"
                  onChange={(e) => handleChange(e)}
                  value={user.mobileNo}
                  className="form-control border border-primary my-3"
                  placeholder=" Mobail No"
                  required
                  aria-required="true"
                  type="tel"
                  size="10"
                  minlength="10"
                  maxlength="12"
                />
                <button
                  className="btn form-control btn-primary  text-light px-5"
                  type="submit"
                  onClick={(e) => savaUser(e)}
                >
                  Request call
                </button>
                <br></br>
                {showEdit ? (
                  <Link
                    className="btn form-control btn-primary  text-light mt-2 px-5"
                    to="/tokenPage"
                  >
                    View calls
                  </Link>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      {hindi ? (
        <div className="card-body">
          <hr />
          <div className=" m-3 mt-5 ps-3">
            <h2>Dialysis: A Lifesaving Measure for Kidney Health</h2>
            Dialysis stands as a critical medical procedure, serving as a
            lifeline for individuals grappling with kidney dysfunction. This
            life-saving treatment mimics the vital functions of healthy kidneys,
            including the filtration of waste products and excess fluids from
            the bloodstream. Its primary goal is to maintain the equilibrium of
            essential substances in the blood, such as electrolytes and fluids,
            while simultaneously expelling waste products. This process is of
            utmost importance, as kidney failure can result in the accumulation
            of harmful substances within the body, leading to a spectrum of
            health complications. In Mumbai, a hub for skilled and experienced
            nephrology experts, individuals with kidney diseases have access to
            this essential treatment. <br /> <br />
            <h2>Types of Dialysis</h2>
            Dialysis can be done in two ways: hemodialysis and peritoneal
            dialysis. <br /> <br />
            <h2>Hemodialysis</h2>
            Hemodialysis stands as the prevailing form of dialysis. In this
            procedure, a machine known as a dialyzer takes on the role of an
            artificial kidney. The patient's blood is pumped through the
            dialyzer, where it undergoes filtration to eliminate waste products
            and surplus fluids. The purified blood is then returned to the
            patient's body. Hemodialysis is commonly administered at specialized
            dialysis centers and necessitates regular sessions, often occurring
            three times a week. Although it can be time-consuming, this method
            effectively manages kidney dysfunction. <br /> <br />
            <h2>Peritoneal Dialysis</h2>
            Peritoneal dialysis, conversely, offers a home-based approach. It
            entails the introduction of a specialized solution into the
            abdominal cavity through a catheter. This solution, referred to as
            dialysate, aids in the absorption of waste and excess fluids from
            the bloodstream through the peritoneal membrane, which lines the
            abdominal cavity. After a designated period, the used dialysate is
            drained, carrying away the accumulated waste products. Peritoneal
            dialysis affords greater flexibility, as it can be conducted daily,
            but it demands meticulous attention to hygiene and a steadfast
            commitment to the treatment regimen. <br /> <br />
            <h2>When is Dialysis Indispensable?</h2>
            Dialysis becomes imperative when an individual's kidneys can no
            longer perform their vital functions adequately. Kidney dysfunction
            can arise from various conditions, including chronic kidney disease,
            acute kidney injury, or end-stage renal disease (ESRD). The absence
            of dialysis allows toxins and excess fluids to accumulate within the
            body, precipitating severe health consequences, such as electrolyte
            imbalances, fluid overload, and potentially life-threatening
            complications. <br /> <br />
            <h2>The Significance of Dialysis</h2>
            Dialysis plays a pivotal role in prolonging and enhancing the
            quality of life for those afflicted by kidney dysfunction. It
            effectively manages the symptoms and complications associated with
            kidney disease, encompassing fatigue, swelling, and elevated blood
            pressure. By preserving the balance of essential substances in the
            bloodstream, dialysis guards against life-threatening complications,
            such as heart issues and pulmonary edema, which can arise from
            kidney dysfunction.
            <br /> <br />
            Furthermore, dialysis serves as a bridge to kidney transplantation.
            Patients awaiting a kidney transplant frequently rely on dialysis to
            sustain them while on the transplant waiting list. Even for those
            fortunate enough to receive a kidney transplant, dialysis remains a
            valuable resource during the immediate post-transplant period,
            supporting kidney function until the new organ attains full
            operational capacity.
            <br /> <br />
            Dialysis emerges as a vital medical intervention, acting as a
            lifeline for individuals grappling with kidney dysfunction. It comes
            in two primary forms, each with its own set of advantages and
            considerations: hemodialysis and peritoneal dialysis. By emulating
            the essential functions of the kidneys, dialysis effectively manages
            the complications of kidney disease and serves as a bridge to kidney
            transplantation when necessary. For countless individuals worldwide,
            dialysis transcends mere medical treatment; it stands as a lifeline
            that offers hope and the promise of an improved quality of life
            despite the challenges posed by kidney-related health issues. In
            Mumbai, several reputable and dependable hospitals offer safe and
            effective dialysis treatments.
          </div>
        </div>
      ) : (
        <div className="lead flex-shrink-0">
          <hr />
          <div className="m-3 mt-5 ps-3">
            <h2> डायलिसिस: किडनी के स्वास्थ्य के लिए एक जीवनरक्षक उपाय</h2>
            डायलिसिस एक महत्वपूर्ण चिकित्सा प्रक्रिया है, जो किडनी की समस्या से
            जूझ रहे लोगों के लिए जीवन रेखा के रूप में काम करती है। यह जीवन रक्षक
            उपचार स्वस्थ किडनी के महत्वपूर्ण कार्यों की नकल करता है, जिसमें
            रक्तप्रवाह से अपशिष्ट उत्पादों और अतिरिक्त तरल पदार्थों को छानना भी
            शामिल है। इसका प्राथमिक लक्ष्य रक्त में इलेक्ट्रोलाइट्स और तरल
            पदार्थ जैसे आवश्यक पदार्थों का संतुलन बनाए रखना है, साथ ही अपशिष्ट
            उत्पादों को बाहर निकालना है। यह प्रक्रिया अत्यंत महत्वपूर्ण है,
            क्योंकि गुर्दे की विफलता के परिणामस्वरूप शरीर के भीतर हानिकारक
            पदार्थ जमा हो सकते हैं, जिससे कई प्रकार की स्वास्थ्य जटिलताएँ पैदा
            हो सकती हैं। मुंबई में, जो कुशल और अनुभवी नेफ्रोलॉजी विशेषज्ञों का
            केंद्र है, किडनी रोग वाले व्यक्तियों को इस आवश्यक उपचार तक पहुंच
            प्राप्त है। <br /> <br />
            <h2> डायलिसिस के प्रकार</h2>
            डायलिसिस दो तरीकों से किया जा सकता है: हेमोडायलिसिस और पेरिटोनियल
            डायलिसिस। <br /> <br />
            <h2>हीमोडायलिसिस</h2>
            हेमोडायलिसिस डायलिसिस का प्रचलित रूप है। इस प्रक्रिया में, डायलाइज़र
            के नाम से जानी जाने वाली मशीन कृत्रिम किडनी की भूमिका निभाती है।
            रोगी के रक्त को डायलाइज़र के माध्यम से पंप किया जाता है, जहां
            अपशिष्ट उत्पादों और अतिरिक्त तरल पदार्थों को खत्म करने के लिए इसे
            फ़िल्टर किया जाता है। फिर शुद्ध रक्त रोगी के शरीर में वापस भेज दिया
            जाता है। हेमोडायलिसिस आमतौर पर विशेष डायलिसिस केंद्रों पर किया जाता
            है और इसके लिए नियमित सत्र की आवश्यकता होती है, जो अक्सर सप्ताह में
            तीन बार होता है। हालाँकि इसमें समय लग सकता है, यह विधि गुर्दे की
            शिथिलता को प्रभावी ढंग से प्रबंधित करती है। <br /> <br />
            <h2> पेरिटोनियल डायलिसिस</h2>
            इसके विपरीत, पेरिटोनियल डायलिसिस एक घर-आधारित दृष्टिकोण प्रदान करता
            है। इसमें एक कैथेटर के माध्यम से पेट की गुहा में एक विशेष समाधान की
            शुरूआत शामिल है। यह समाधान, जिसे डायलीसेट कहा जाता है, पेरिटोनियल
            झिल्ली के माध्यम से रक्त प्रवाह से अपशिष्ट और अतिरिक्त तरल पदार्थ के
            अवशोषण में सहायता करता है, जो पेट की गुहा को रेखांकित करता है। एक
            निर्दिष्ट अवधि के बाद, प्रयुक्त डायलीसेट को सूखा दिया जाता है, जिससे
            संचित अपशिष्ट उत्पाद बाहर निकल जाते हैं। पेरिटोनियल डायलिसिस अधिक
            लचीलापन प्रदान करता है, क्योंकि इसे दैनिक रूप से आयोजित किया जा सकता
            है, लेकिन इसमें स्वच्छता पर सावधानीपूर्वक ध्यान देने और उपचार के
            प्रति दृढ़ प्रतिबद्धता की आवश्यकता होती है। <br /> <br />
            <h2>डायलिसिस कब अपरिहार्य है?</h2>
            डायलिसिस तब अनिवार्य हो जाता है जब किसी व्यक्ति की किडनी अपना
            महत्वपूर्ण कार्य पर्याप्त रूप से नहीं कर पाती है। किडनी की शिथिलता
            विभिन्न स्थितियों से उत्पन्न हो सकती है, जिनमें क्रोनिक किडनी रोग,
            तीव्र किडनी की चोट, या अंतिम चरण की किडनी की बीमारी (ईएसआरडी) शामिल
            हैं। डायलिसिस की अनुपस्थिति से शरीर के भीतर विषाक्त पदार्थ और
            अतिरिक्त तरल पदार्थ जमा हो जाते हैं, जिससे गंभीर स्वास्थ्य परिणाम
            होते हैं, जैसे इलेक्ट्रोलाइट असंतुलन, द्रव अधिभार और संभावित
            जीवन-घातक जटिलताएं। <br /> <br />
            <h2>डायलिसिस का महत्व</h2>
            किडनी की खराबी से पीड़ित लोगों के जीवन की गुणवत्ता को बढ़ाने और
            बढ़ाने में डायलिसिस महत्वपूर्ण भूमिका निभाता है। यह गुर्दे की बीमारी
            से जुड़े लक्षणों और जटिलताओं को प्रभावी ढंग से प्रबंधित करता है,
            जिसमें थकान, सूजन और उच्च रक्तचाप शामिल हैं। रक्तप्रवाह में आवश्यक
            पदार्थों के संतुलन को संरक्षित करके, डायलिसिस जीवन-घातक जटिलताओं,
            जैसे हृदय संबंधी समस्याओं और फुफ्फुसीय एडिमा से बचाता है, जो गुर्दे
            की शिथिलता से उत्पन्न हो सकती हैं। <br /> <br />
            इसके अलावा, डायलिसिस किडनी प्रत्यारोपण के लिए एक पुल के रूप में
            कार्य करता है। किडनी प्रत्यारोपण की प्रतीक्षा कर रहे मरीज अक्सर
            प्रत्यारोपण प्रतीक्षा सूची में रहने के लिए डायलिसिस पर निर्भर रहते
            हैं। यहां तक कि उन लोगों के लिए भी जो किडनी प्रत्यारोपण कराने के लिए
            भाग्यशाली हैं, प्रत्यारोपण के तुरंत बाद की अवधि के दौरान डायलिसिस एक
            मूल्यवान संसाधन बना रहता है, जो किडनी के कार्य को तब तक समर्थन देता
            है जब तक कि नया अंग पूर्ण परिचालन क्षमता प्राप्त नहीं कर लेता।{" "}
            <br /> <br />
            डायलिसिस एक महत्वपूर्ण चिकित्सा हस्तक्षेप के रूप में उभरा है, जो
            किडनी की समस्या से जूझ रहे व्यक्तियों के लिए जीवन रेखा के रूप में
            कार्य करता है। यह दो प्राथमिक रूपों में आता है, प्रत्येक के अपने
            फायदे और विचार हैं: हेमोडायलिसिस और पेरिटोनियल डायलिसिस। किडनी के
            आवश्यक कार्यों का अनुकरण करके, डायलिसिस किडनी रोग की जटिलताओं को
            प्रभावी ढंग से प्रबंधित करता है और आवश्यक होने पर किडनी प्रत्यारोपण
            के लिए एक पुल के रूप में कार्य करता है। दुनिया भर में अनगिनत
            व्यक्तियों के लिए, डायलिसिस महज चिकित्सा उपचार से कहीं आगे है; यह एक
            ऐसी जीवन रेखा के रूप में खड़ी है जो किडनी से संबंधित स्वास्थ्य
            समस्याओं से उत्पन्न चुनौतियों के बावजूद जीवन की बेहतर गुणवत्ता की
            आशा और वादा करती है। मुंबई में, कई प्रतिष्ठित और भरोसेमंद अस्पताल
            सुरक्षित और प्रभावी डायलिसिस उपचार प्रदान करते हैं।
          </div>
        </div>
      )}
    </>

    // <div className="container">
    //   <div className=" d-flex justify-content-around  mt-5 ">
    //     <h1 className=" pt-4 fs-1 text-center ">Dialysis Cost at Jivdani</h1>
    //     <div className=" btn-group pb-3 mt-5  ">
    //       <button
    //         className=" rounded-start-pill btn btn-outline-primary "
    //         onClick={changeEnglish}
    //       >
    //         English
    //       </button>
    //       <button
    //         className="  rounded-end-pill btn btn-outline-primary"
    //         onClick={changeHindi}
    //       >
    //         hindi
    //       </button>
    //     </div>
    //   </div>
    //   <div className="row mt-5">
    //     <div className="col-sm-8">col-sm-8</div>
    //     <div className="col-sm-4">col-sm-4</div>
    //   </div>
    // </div>
  );
};

export default Dialysis;
