import React from "react";
import { BASE_URL, IMG_BASE_URL } from "../Constant";

export default function TestBySymptoms({column}) {
  return (
    <div className="container overflow-hidden">
      <div
        className={`row  row row-cols-${
          column - 1
        } row-cols-md-4 row-cols-lg-${column} g-2  `}
      >
        <div className="col  ">
          <div class="card   " style={{ maxWidth: 150 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
          </div>
        </div>
        <div className="col  ">
          <div class="card   " style={{ maxWidth: 150 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
          </div>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
       <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
        <div className="col  ">
          <div class="card   " style={{ maxWidth: 120 }}>
            <img
              src={IMG_BASE_URL + "topproduticon/Embryo.png"}
              class="m-2"
              alt="..."
              // style={{ alignSelf:'center'}}
            />
            <small
              className=" d-none d-sm-block"
              style={{ alignSelf: "center" }}
            >
              hello
            </small>
          </div>
          <small
            className=" text-center  d-block d-sm-none "
            style={{ alignSelf: "center" }}
          >
            hello
          </small>
        </div>
      </div>
    </div>
  );
}
