import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import CustomTooltip from "./CustomTooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetState, submitData } from "./ReduxApi/ReduxApi";

import swal from "sweetalert";
import ToastFist from "./Toast/ToastFist";
import icons from "./Utils/icons";
import { ENTER_DATA, NO_DATA_ENTERED, VISIT_ERROR_MSG, VISIT_SUCCESS_MSG } from "../Constant";

const ReportFooter = ({ receivedDatas, patientData }) => {


  const [receivedData, setReceiveData] = useState(receivedDatas);

  useEffect(() => {
    // Update the state whenever the receivedDatas prop changes
    setReceiveData(receivedDatas);
  }, [receivedDatas]); // Dependency array includes receivedDatas

  const reduxApiData = useSelector((state) => state.reduxApiData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");

  const [selectedLanguages, setSelectedLanguage] = useState("en");

  const handleSubmit = async (loderId) => {
    setLoading(loderId); // Start loading
    try {
      const res = await dispatch(submitData(reduxApiData, receivedData));

      if (res == NO_DATA_ENTERED) {
        setShowToast(true);
        setColorStatus(3);
        setToastMsg(ENTER_DATA);
      }
      else if (res) {
        setShowToast(true);
        setColorStatus(1);
        setToastMsg(VISIT_SUCCESS_MSG);
      }
      else {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(VISIT_ERROR_MSG);
      }
    } catch (error) {
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async (loderId) => {
    setLoading(loderId); // Start loading
    try {
      const res = await dispatch(submitData(reduxApiData, receivedData));

      if (res == NO_DATA_ENTERED) {
        setShowToast(true);
        setColorStatus(3);
        setToastMsg(ENTER_DATA);
      }
      else if (res) {
        setShowToast(true);
        // console.log("LOG DD1", res)
        setColorStatus(1);
        setToastMsg(VISIT_SUCCESS_MSG);
        navigate("/Report", { state: { receivedData, selectedLanguages, ageGender: { age: receivedData?.age, gender: receivedData?.gender } } });
      }
      else {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(VISIT_ERROR_MSG);
      }
      setLoading(false); // Stop loading
    } catch (error) {
      setLoading(false); // Stop loading
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    }
  };


  const ViewAllTokanButton = async (loderId) => {
    setLoading(loderId); // Start loading
    try {
      const res = await dispatch(submitData(reduxApiData, receivedData));

      if (res == NO_DATA_ENTERED) {
        setShowToast(true);
        setColorStatus(3);
        setToastMsg(ENTER_DATA);
      }
      else if (res) {
        setShowToast(true);
        setColorStatus(1);
        setToastMsg(VISIT_SUCCESS_MSG);
        navigate("/tokenbooking");
      } else {
        setShowToast(true);
        setColorStatus(0);
        setToastMsg(VISIT_ERROR_MSG);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false); // Stop loading
      setShowToast(true);
      setColorStatus(0);
      setToastMsg(VISIT_ERROR_MSG);
    }
  };

  const footerStyle = {
    backgroundColor: "#fffff",
    // color: "white",
    padding: "20px",
    position: "sticky",
    bottom: "0",
    width: "100%",
    height: 6,
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  return (
    <>
      <footer
        className=" d-flex justify-content-end border  rounded p-4 bg-light "
        style={footerStyle}
      >
        <div class="align-self-center ">
          <select
            className="form-select"
            id="inputGroupSelect02"
            defaultValue=""
            style={{ maxHeight: 38 }}
            value={selectedLanguages}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="hi">Hindi</option>
            <option value="mar">Marathi</option>
          </select>
        </div>

        <div class="align-self-center ms-4  " onClick={() => handlePrint("6")}>
          <img

            src={icons.print}
            alt={"print"}
            style={{ width: 25, height: 25, cursor: "pointer", }}
          />
        </div>
        <div class="align-self-center ms-4 " onClick={() => handleSubmit("5")}>
          <img
            src={icons.whatsapp}
            alt={"whatsapp"}
            style={{ width: 25, height: 25 }}
          />
          {/* <FontAwesomeIcon icon="fa-brands fa-whatsapp" style={{color: "#63E6BE",height:22}} /> */}
        </div>
        <div class="align-self-center ms-4 " onClick={() => handleSubmit("4")}>
          <img
            src={icons.gmail}
            alt={"gmail"}
            style={{ width: 25, height: 25 }}
          />
        </div>
        <div class="align-self-center ms-4 flex ">
          <button
            type="button"
            class="btn btn-outline-primary"
            onClick={() => ViewAllTokanButton("3")}
            style={{ width: 130, height: 38 }}
          >
            {loading == "3" && (
              <span
                class="spinner-border spinner-border-sm text-primary"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            View Tokens
          </button>
        </div>

        <div class="align-self-center ms-4 flex ">
          <button
            type="button"
            class="btn btn-light  border border-primary  text-centar "
            onClick={() => handleSubmit("2")}
            style={{ width: 130, height: 38 }}
          >
            {loading == "2" ? (
              <span
                class="spinner-border spinner-border-sm text-primary"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <CustomTooltip
                icon="fa-regular fa-floppy-disk"
                tooltipText="Save"
              />
            )}
            <span className="ms-2 md:fs-5 text-center text-primary"> Save</span>
          </button>
        </div>

        <div
          class="align-self-center ms-4 flex"
        // onClick={() => screenNavigtion()}
        >
          <button
            type="button"
            class="btn btn-danger  border   text-centar "
            style={{ width: 130, height: 38 }}
            //  onClick={ViewAllTokanButton}
            onClick={() => handlePrint("1")}
          >
            <span className="ms-2 md:fs-5 text-center "> End constant</span>
          </button>
        </div>

      </footer>

      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};
export default ReportFooter;
