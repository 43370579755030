import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { IMG_BASE_URL } from "../Constant";

function BannerImage(props, showEdit) {
  return (
    <>
      <div class=" text-center ">
        <div
          className={"carousel-item  " + (props.active ? "active  " : "")}
          data-bs-interval={2400}
        >
          <div className="text-centar ">
            <Link
              to={"/productdetail/" + props?.itemId}
              onClick={() =>
                localStorage.setItem("myProduct", JSON.stringify(props.item))
              }
            >
              <img
                className="rounded-circle shadow-1-strong mb-4 "
                src={IMG_BASE_URL+props.image}
                alt="avatar"
                style={{ width: 150 }}
                active={props === 0 ? true : false}
              />
            </Link>
            <div className="row d-flex justify-content-center ">
              <div className="col-lg-8">
                <h5 className="mb-3">{props.title}</h5>

                <p className="text-muted">
                 

                  {/* {parse(`${props.subTitle}`)} */}
                  {props.subTitle.length > 250
                    ? parse(`${props.subTitle.substring(0, 250)} ...`) 
                    : parse(props.subTitle)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={"carousel-item  " + (props.active ? "active " : "")}
        data-bs-interval={100}
      >
        {" "}
        <Link
          to={"/productdetail"}
          onClick={() =>
            localStorage.setItem("myProduct", JSON.stringify(props.item))
          }
        >
          <img
            className="rounded-circle shadow-1-strong mb-4"
            src={props.image}
            alt="avatar"
            style={{ width: 150 }}
          />
        </Link>
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-8">
            <h5 className="mb-3">{props.title}</h5>

            <p className="text-muted">
              <i className="fas fa-quote-left mb-5" />
              {props.subTitle}
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}



function BannerIncidator(props) {
  return (
    <button
      type="button"
      data-bs-target="#bannerIndicators"
      data-bs-slide-to={props.index}
      className={
        props.active ? "active btn btn-outline-info m-2 " : "btn btn-outline-info m-2"
      }
      aria-current={props.active}
    />
  );
}
function CustomerReviewCart({ product, showEdit }) {
  return (
    <div className="card-body my-5 mx-4 ">
      <div
        id="carouselExampleDark"
        className="carousel carousel-dark slide  "
        data-bs-ride="carousel"
      >
        <div className="  mb-5 position-absolute top-100 start-50 translate-middle">
          {/* {product?.items?.map((it, index) => (
            <BannerIncidator
              item={it}
              index={"" + index}
              active={index === 0 ? true : false}
            />
          ))} */}
        </div>
        <div className="carousel-inner  ">
          {product?.items?.map((it, index) => (
            <BannerImage
              item={it}
              image={it.iconURL}
              title={it.title}
              subTitle={it.subTitle}
              active={index === 0 ? true : false}
              showEdit={showEdit}
            />
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
export default CustomerReviewCart;
