

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

function SimpalText({ item, navigate, showEdit, nama }) {
  const data = () => {
    //  navigate("/itemsviewmore");
    localStorage.setItem("myData", JSON.stringify(item));
    localStorage.setItem("myDataview", nama);
  };
  return (
    <div class="d-flex bd-highlight mt-4 ">
      <div class="container overflow-hidden">
        <div class="row ">
          <div class="col">
            <div class="h3  bg-light">{item?.title}</div>
          </div>
        </div>
      </div>


      <div>
       
        {showEdit ? (
          <Link
            to={"/addcat"}
            className="btn btn-outline-primary "
            onClick={() =>
              navigate(localStorage.setItem("catData", JSON.stringify(item)))
            }
            type="submit"
          >
            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
            Edit
          </Link>
        ) : null}
      </div>
    </div>
  );
}

export default SimpalText;
