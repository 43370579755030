import counterReducer from './counter'
import loggedReducer from './isLogged'
import { combineReducers } from 'redux'
import reactnatviedata from './reactnatviedata'
import { dataReducer } from '../report/ReduxApi/ReduxApi'
import { isPlainObject } from '@reduxjs/toolkit'
import visitPadReducer from '../report/ReduxApi/VistPadApiCall'
import DataClearAll from "../report/ReduxApi/ClearAll"
import { templateNamesReducer } from '../report/ReduxApi/FetchTemplateApi'
import { RootTemplateData } from '../report/ReduxApi/RootTemplateData'
import { rootTemplateReducer } from '../report/ReduxApi/rootTemplateReducer'
import visitReducerCount from '../report/ReduxApi/VisitReducerCount'
import userTokenReducer from '../report/ReduxApi/UserToken'


const allReducers = combineReducers({
    //we access this by any key we want
    counter: counterReducer,
    isLogged: loggedReducer,
    recatData: reactnatviedata,
    reduxApiData: dataReducer,
    visitPad: visitPadReducer,
    ClearAllData: DataClearAll,
    templateNames: templateNamesReducer,
    RootTemplateData: RootTemplateData,
    rootTemplate: rootTemplateReducer,
    templateNames: templateNamesReducer,
    RootTemplateData: RootTemplateData,
    visit: visitReducerCount,
    userToken: userTokenReducer




})
export default allReducers