import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Color } from "../../visitConstant/Color"


export const TableFooter = ({
    rowsPerPage,
    handleRowsPerPageChange,
    medicinesLength,
    currentPage,
    handlePageChange
}) => {
    const indexOfLastMedicine = currentPage * rowsPerPage;
    const indexOfFirstMedicine = indexOfLastMedicine - rowsPerPage;

    return (
        <div className="d-flex justify-content-between align-items-center mt-1 bg-white  rounded py-1 px-2 " style={{ height: 48 }}>
            <div className="d-flex align-items-center">
                <label htmlFor="rowsPerPage" className="me-2" style={{ fontSize: 14, }}>
                    Rows per page:
                </label>
                <select
                    id="rowsPerPage"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    className="form-select"
                    style={{ width: "auto", }}
                >
                    {/* <option value={5}>5</option> */}
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                </select>
            </div>

            {/* Pagination Info and Buttons */}
            <div className="d-flex align-items-center">
                {/* Pagination Info */}
                <div className="me-4">
                    {medicinesLength > 0 ? (
                        <>
                            {indexOfFirstMedicine + 1} -{" "}
                            {indexOfFirstMedicine + medicinesLength}
                            {/* {Math.min(indexOfLastMedicine, medicinesLength * currentPage)} */}
                        </>
                    ) : (
                        "0 - 0"
                    )}
                </div>

                {/* Pagination Buttons */}
                <div className="d-flex align-items-center">
                    <button
                        className="btn btn-primary me-2 border-0"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-angle-left" />
                    </button>

                    <button
                        className="btn btn-primary border-0"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={medicinesLength < rowsPerPage}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                    </button>
                </div>
            </div>
        </div>

    )
}