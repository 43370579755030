import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { increment } from "../actions";
// import { useDispatch } from "react-redux";
import { useState } from "react";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import {
  SESSION_ID,
  BASE_URL,
  AddCategoryItem,
  ACCESS_TOKEN,
  DeleteItem,
  DEBUG_LOG_ENABLED,
} from "../Constant";
import axios from "axios";
import swal from "sweetalert";
export default function CatItem() {
  // let [authMode, setAuthMode] = useState("signin")
  // const changeAuthMode = () => {
  //   setAuthMode(authMode === "signin" ? "signup" : "signin")
  // };

  const catData = localStorage.getItem("catItem");
  const itemData = JSON.parse(catData);
  // const dispatch = useDispatch();

  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [categoryItem, setCategoryItem] = useState({
    itemId: itemData?.itemId,
    catId: itemData?.catId,
    orderNo: itemData?.catId,
    title: itemData?.title,
    titleID: itemData?.titleID,
    subTitle: itemData?.subTitle,
    subTitleID: itemData?.subTitleID,
    viewMoreTitle: itemData?.viewMoreTitle,
    viewMoreTitleID: itemData?.viewMoreTitleID,
    viewType: itemData?.viewType,
    iconURL: itemData?.iconURL,
    price: itemData?.price,
    mobileNo: itemData?.mobileNo,
    emailId: itemData?.emailId,
    doctorId: itemData?.doctorId,
    catType: itemData?.catType,
    callingURL: itemData?.callingURL,
    priceInDollar: itemData?.priceInDollar,
    //new
    discountPrice: itemData?.discountPrice,
    emailId: itemData?.emailId,
    mobileNo: itemData?.mobileNo,
    doctorId: itemData?.doctorId,
    catType: itemData?.catType,
    description: itemData?.description,
    bgcolor: itemData?.bgcolor,
    gridViewOn: itemData?.gridViewOn,
    layoutWidth: itemData?.layoutWidth,
    layoutHeight: itemData?.layoutHeight,
    headerColor: itemData?.headerColor,
    callingTag: itemData?.callingTag,
    callingLink: itemData?.callingLink,
    callingURL: itemData?.callingURL,
    appIconURL: itemData?.appIconURL,
    // panditPriceInDollar: itemData.panditPriceInDollar,
    // panditPrice: itemData.panditPrice,
    // panditCount: itemData.panditCount,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCategoryItem({ ...categoryItem, [e.target.name]: value });
  };

  const saveCategoryItem = (e) => {
    e.preventDefault();
    if (
      categoryItem?.catId === "" ||
      categoryItem?.catId === undefined ||
      categoryItem?.title === "" ||
      categoryItem?.title === undefined ||
      categoryItem?.titleID === ""
      // categoryItem.subTitle === "" ||
      // categoryItem.subTitleID === "" ||
      // categoryItem.viewType === "" ||
      // categoryItem.iconURL === "" ||
      // categoryItem.price === "" ||
      // categoryItem.mobileNo === "" ||
      // categoryItem.emailId === "" ||
      // categoryItem.doctorId === "" ||
      // categoryItem.catType === "" ||
      // categoryItem.viewMoreTitle === "" ||
      // categoryItem.callingURL===""
    ) {
      swal(" Please enter all fields ");
    } else {
      const categoryItemData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            itemId: categoryItem?.itemId,
            catId: categoryItem?.catId,
            orderNo: categoryItem?.orderNo,
            title: categoryItem?.title,
            titleID: categoryItem?.titleID,
            subTitle: categoryItem?.subTitle,
            subTitleID: categoryItem?.subTitleID,
            viewMoreTitle: categoryItem?.viewMoreTitle,
            viewMoreTitleID: categoryItem?.viewMoreTitleID,
            viewType: categoryItem?.viewType,
            iconURL: categoryItem?.iconURL,
            price: categoryItem?.price,
            mobileNo: categoryItem?.mobileNo,
            emailId: categoryItem?.emailId,
            doctorId: categoryItem?.doctorId,
            catType: categoryItem?.catType,
            callingURL: categoryItem?.callingURL,
            //new
            viewMoreTitleID: categoryItem?.viewMoreTitleID,
            iconURL: categoryItem?.iconURL,

            priceInDollar: categoryItem?.priceInDollar,
            viewMoreTitle: categoryItem?.viewMoreTitle,
            discountPrice: categoryItem?.discountPrice,

            description: categoryItem?.description,
            bgcolor: categoryItem?.bgcolor,
            gridViewOn: categoryItem?.gridViewOn,
            layoutWidth: categoryItem?.layoutWidth,
            layoutHeight: categoryItem?.layoutHeight,
            headerColor: categoryItem?.headerColor,
            callingTag: categoryItem?.callingTag,
            callingLink: categoryItem?.callingLink,
            callingURL: categoryItem?.callingURL,

            appIconURL: categoryItem?.appIconURL,
            //new
            // panditPriceInDollar: categoryItem.panditPriceInDollar,
            // panditPrice: categoryItem.panditPrice,
            // panditCount: categoryItem.panditCount,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      axios
        .post(BASE_URL + AddCategoryItem, categoryItemData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        })
        .then((response) => {
          swal(response.data.respData.message);
          navigate('/');
          if (DEBUG_LOG_ENABLED) {
            console.log(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            swal("data : " + err);
            if (DEBUG_LOG_ENABLED) {
              console.log(err);
            }
          }
        });
    }
  };

  const removeCatItemData = async (e) => {
    e.stopPropagation();
    try {
      const removeCat = JSON.stringify({
        pubInfo: {
          sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
        },
        request: {
          busiParams: {
            itemId: itemData.itemId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(BASE_URL + DeleteItem, removeCat, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });

      swal(response.data.respData.message);
      navigate("/");
    } catch (err) {
      if (err.response.status === 401) {
        navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const removeItem = () => {
    removeCatItemData();
  };

  return (
    <>
      <div className="align-items-center container  md:py-5 px-xl-5 mx-auto mt-5 ">
        <div className="">
          <ScrollToTopOnMount />
          {/* <div className="col-lg-9"> */}
          <div className="">
            <form className=" ">
              <div className=" shadow-lg p-3 mb-5 bg-body rounded">
                <h4 className="Auth-form-title">Add category item</h4>
                <div class="row row-cols-1 row-cols-lg-2 px-5">
                  <div class="col">
                    <div className="form-group mt-3">
                      <label>ItemId</label>
                      <input
                        type="number"
                        name="itemId"
                        value={categoryItem?.itemId}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Cat Id</label>
                      <input
                        type="number"
                        name="catId"
                        value={categoryItem?.catId}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>orderNo</label>
                      <input
                        type="number"
                        name="orderNo"
                        value={categoryItem?.orderNo}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-1">
                      <label>title</label>
                      <input
                        type="text"
                        name="title"
                        value={categoryItem?.title}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>titleID</label>
                      <input
                        type="text"
                        name="titleID"
                        value={categoryItem?.titleID}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>subTitle</label>
                      <input
                        type="text"
                        name="subTitle"
                        value={categoryItem?.subTitle}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>gridViewOn</label>
                      <input
                        type="text"
                        name="gridViewOn"
                        value={categoryItem?.gridViewOn}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>layoutWidth</label>
                      <input
                        type="numbar"
                        name="layoutWidth"
                        value={categoryItem?.layoutWidth}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>layoutHeight</label>
                      <input
                        type="numbar"
                        name="layoutHeight"
                        value={categoryItem?.layoutHeight}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>headerColor</label>
                      <input
                        type="numbar"
                        name="headerColor"
                        value={categoryItem?.headerColor}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>callingTag</label>
                      <input
                        type="text"
                        name="callingTag"
                        value={categoryItem?.callingTag}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>callingLink</label>
                      <input
                        type="numbar"
                        name="callingLink"
                        value={categoryItem?.callingLink}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>callingURL</label>
                      <input
                        type="numbar"
                        name="callingURL"
                        value={categoryItem?.callingURL}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div className="form-group mt-3">
                      <label>subTitleID</label>
                      <input
                        type="text"
                        name="subTitleID"
                        value={categoryItem?.subTitleID}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>No.Of Chants:</label>
                      <input
                        type="text"
                        name="viewMoreTitle"
                        value={categoryItem?.viewMoreTitle}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>ViewType</label>
                      <input
                        type="text"
                        name="viewType"
                        value={categoryItem?.viewType}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>iconURL</label>
                      <input
                        type="text"
                        name="iconURL"
                        value={categoryItem?.iconURL}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Price In INR (₹)</label>
                      <input
                        type="numbar"
                        name="price"
                        value={categoryItem?.price}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>Price In USD ($)</label>
                      <input
                        type="numbar"
                        name="priceInDollar"
                        value={categoryItem?.priceInDollar}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    {/* new */}
                    <div className="form-group mt-3">
                      <label>discountPrice</label>
                      <input
                        type="numbar"
                        name="discountPrice"
                        value={categoryItem?.discountPrice}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>emailId</label>
                      <input
                        //type="text"
                        name="emailId"
                        value={categoryItem?.emailId}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>mobileNo</label>
                      <input
                        type="numbar"
                        name="mobileNo"
                        value={categoryItem?.mobileNo}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>doctorId</label>
                      <input
                        type="numbar"
                        name="doctorId"
                        value={categoryItem?.doctorId}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>catType</label>
                      <input
                        type="numbar"
                        name="catType"
                        value={categoryItem?.catType}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>description</label>
                      <input
                        type="text"
                        name="description"
                        value={categoryItem?.description}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>bgcolor</label>
                      <input
                        type="numbar"
                        name="bgcolor"
                        value={categoryItem?.bgcolor}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>appIconURL</label>
                      <input
                        type="text"
                        name="appIconURL"
                        value={categoryItem?.appIconURL}
                        onChange={(e) => handleChange(e)}
                        className="form-control mt-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-grid gap-2 mt-3">
                  <button
                    onClick={saveCategoryItem}
                    type="submit"
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button
                    name="delete"
                    type="submit"
                    className="btn btn-primary   mt-3"
                    onClick={removeCatItemData}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-trash " /> Delete
                  </button>
                </div>

                {/* <p className="text-center mt-2">
            Forgot <a href="#" onClick={()=> navigate("/login")}>password?</a>
          </p> */}
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
