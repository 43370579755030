import React, { useState, useEffect } from "react";
import "./CustomDropdown.css";
import "../ui/CSS/focusinput.css";

const DurationDropdown = ({ inputRef = null, value, onChange, onBlur }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const durationUnits = ["days", "weeks", "months", "years"];

    useEffect(() => {
        if (isOpen) {
            setHighlightedIndex(0);
        }
    }, [isOpen]);

    useEffect(() => {

        if (value) {

            setSearchTerm(value);
        }
    }, [value]);

    const handleOptionClick = (option) => {
        setSearchTerm(option);
        setIsOpen(false); // Close dropdown
        onChange(option); // Trigger onChange with selected option
    };

    const handleInputFocus = () => setIsOpen(true); // Open dropdown when focused
    const handleInputBlur = (e) => {
        // if (onBlur) onBlur(e.target.value)
        setTimeout(() => setIsOpen(false), 150);
    }// Close dropdown after slight delay

    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, "");
        setSearchTerm(inputValue); // Update the search term when user types
        setIsOpen(true); // Ensure dropdown is open when typing
    };

    // If the user typed something, append the searchTerm to the units, otherwise show default units
    const filteredOptions = searchTerm
        ? durationUnits.map((unit) => `${searchTerm?.replace(/\D/g, "")} ${unit}`)
        : durationUnits.map((unit) => `1 ${unit}`); // Default to "1" when no input

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setHighlightedIndex((prevIndex) =>
                prevIndex === filteredOptions.length - 1 ? 0 : prevIndex + 1
            );
        } else if (e.key === "ArrowUp") {
            setHighlightedIndex((prevIndex) =>
                prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1
            );
        } else if (e.key === "Enter") {
            const selected = filteredOptions[highlightedIndex];
            if (selected) {
                handleOptionClick(selected);
            }
        }
    };

    return (
        <div
            className="dropdown"
            onKeyDown={handleKeyDown}
            tabIndex="0"
            style={{ position: "relative" }}
        >
            <div className="dropdown-header">
                <input
                    ref={inputRef}
                    type="text"
                    value={searchTerm} // No placeholder, just the current value or empty
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    className="prompt form-control border-0"
                    style={{ fontSize: 13, borderRadius: 0 }}
                />
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    {filteredOptions.length > 0 ? (
                        filteredOptions.map((option, index) => (
                            <div
                                key={option}
                                className={`dropdown-item ${index === highlightedIndex ? "highlighted" : ""}`}
                                onMouseDown={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))
                    ) : (
                        <div className="dropdown-item">No options available</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DurationDropdown;
