import React from 'react';
import { IMG_BASE_URL } from '../Constant';
import icons from '../report/Utils/icons';

const Carousel = () => {
  return (
    <div >
      <div id="carouselExampleFade" className="carousel slide carousel-fade " data-bs-ride="carousel">
        <div className="carousel-inner  ">
          <div className="carousel-item active ">
            <img src={icons.slider1} className="d-block  " style={{ height: '80vh', width: "100%", borderRadius: "1rem" }} alt="Images" />
          </div>
          <div className="carousel-item">
            <img src={icons.slider2} className="d-block  " style={{ height: '80vh', width: "100%", borderRadius: "1rem" }} alt="Images..." />
          </div>
          <div className="carousel-item">
            <img src={icons.slider3} className="d-block  " style={{ height: '80vh', width: "100%", borderRadius: "1rem" }} alt="Images..." />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <span className="carousel-control-prev-icon bg-primary rounded" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <span className="carousel-control-next-icon bg-primary rounded" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
