import { IMG_BASE_URL } from "../../../Constant";
import ScrollToTopOnMount from "../../../components/ScrollToTopOnMount";
import RequestCall from "../../../requestCall/RequestCall";
import "./Neurologist.css"




const Neurologist = () => {
{/* <div class="">
  <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
    <div class="col">Column</div>
    <div class="col">Column</div>
    </div> */}

    return (
      <div className="container">
        <ScrollToTopOnMount />
        <div className="m-1 pt-3">
          <div id="head" className="mt-5 pt-4 ">
            <div className=" ">
              <h2 className="fw-bolder">Neurologist</h2>
              <br />
              <div id="line"></div> <br />
              <div className="row">
                <div className="col-lg-8 fs-5 ">
                  A neurologist is a medical doctor who specializes in
                  diagnosing and treating diseases of the brain, spinal cord and
                  nerves. Neurological diseases and conditions can affect nearly
                  every part of your body and affect both adults and children.
                </div>
              </div>
            </div>
          </div>{" "}
          <br />
          <div className="row">
            <div class="col-md-8">
              <img
                className=" w-100 h-100 col-md-4  "
                src={IMG_BASE_URL + "Article/neurologist.webp"}
              />
            </div>
            <div class="col-md-4">
              <RequestCall />
            </div>
          </div>{" "}
          <br />
          <div className="">
            <div className="row " id="ll">
              <div className="fw-bolder fs-5 col-md-1 col-sm-5 col-3">
                Contentsↆ
              </div>
              <div className="col-md-3 col-sm-2 col-2">
                <a className="text-decoration-none fs-5 m-5 ">Overview</a>
              </div>
              <div className="col-md-6  col-sm-7">
                <a
                  className="text-decoration-none fs-5"
                  href="#scrollspyAdditionascroll"
                >
                  Additional Common Questions
                </a>
              </div>
            </div>
          </div>{" "}
          <br /> <br />
          <div className="">
            <div className="row">
              <div className="row">
                <div
                  className="col-md-10 scrollspy"
                  data-bs-spy="scroll"
                  data-bs-target="#ll"
                  data-bs-smooth-scroll="true"
                  tabindex="0"
                >
                  <h2 className="fw-bolder" id="scrollspyOver">
                    {" "}
                    Overview
                  </h2>{" "}
                  <br />
                  <h3 className="fw-bolder"> What is a neurologist ?</h3> <br />
                  <div className="fs-5">
                    {" "}
                    A neurologist is a medical doctor who diagnoses, treats and
                    manages disorders of the brain and nervous system (brain,
                    spinal cord and nerves). A neurologist knows the anatomy,
                    function and conditions that affect your nerves and nervous
                    system. Your nervous system is your body’s command center.
                    It controls everything you think, feel and do — from moving
                    your arm to the beating of your heart.
                  </div>{" "}
                  <br />
                  <h3 className="fw-bolder">
                    {" "}
                    What is a pediatric neurologist ?
                  </h3>{" "}
                  <br />
                  <div className="fs-5">
                    {" "}
                    A pediatric neurologist is a medical doctor who diagnoses,
                    treats and manages disorders of the brain and nervous system
                    in children — from newborn to adolescent. Many of the
                    conditions they treat are the same as those seen in adults,
                    in addition to inherited and developmental conditions.
                  </div>{" "}
                  <br />
                  <h3 className="fw-bolder"> What is a neurosurgeon ?</h3>{" "}
                  <br />
                  <div className="fs-5">
                    A neurosurgeon is a medical doctor who performs surgery on
                    the brain, spinal cord and nerves.
                  </div>
                  <br />
                  <h3 className="fw-bolder">
                    {" "}
                    What diseases and conditions does a neurologist treat ?
                  </h3>{" "}
                  <br />
                  <div className="fs-5">
                    {" "}
                    Some of the most common neurologic disorders a neurologist
                    may treat include: <br /> <br />
                    ● Alzheimer’s disease and other dementias. <br /> <br />
                    ● Amyotrophic lateral sclerosis (also called ALS or Lou
                    Gehrig’s disease). <br /> <br />
                    ● Brain injury, spinal cord injury or vascular
                    malformations. <br /> <br />
                    ● Cerebral aneurysms and arteriovenous malformations. <br />{" "}
                    <br />
                    ● Cerebral palsy and spasticity. <br /> <br />
                    ● Concussion. <br /> <br />
                    ● Encephalitis. <br /> <br />
                    ● Epilepsy. <br /> <br />
                    ● Facial pain syndromes. <br /> <br />
                    ● Headache/migraine. <br /> <br />
                    ● Hydrocephalus. <br /> <br />
                    ● Meningitis. <br /> <br />
                    ● Mental and behavioral health disorders. <br /> <br />
                    ● Multiple sclerosis. <br /> <br />
                    ● Myasthenia gravis and myopathies. <br /> <br />
                    ● Pain in your neck, back and spine. <br /> <br />
                    ● Parkinson’s disease. <br /> <br />
                    ● Peripheral neuropathy. <br /> <br />
                    ● Sleep disorders. <br /> <br />
                    ● Stroke. <br /> <br />
                    ● Tremor, dystonia. <br /> <br />
                    ● Tremor, dystonia. <br /> <br />● Tumors of the brain,
                    spine and nerves.
                  </div>{" "}
                  <br /> <br />
                  <h3 className="fw-bolder">
                    How do neurologists diagnose conditions ?
                  </h3>{" "}
                  <br />
                  <div className="fs-5">
                    {" "}
                    Your neurologist will ask about your medical history, family
                    history, medication history and any current symptoms.
                    They’ll also conduct a neurologic examination, including
                    tests of your:
                    <br /> <br />
                    ● Coordination, balance, reflexes and gait. <br /> <br />
                    ● Muscle strength. <br /> <br />
                    ● Mental health. <br /> <br />
                    ● Vision, hearing and speech. <br /> <br />
                    ● Sensation. <br /> <br />
                    Your neurologist may also order blood, urine or other fluid
                    tests in order to help understand condition severity or
                    check on medication levels. Genetic testing may be ordered
                    to identify inherited disorders. Imaging studies of your
                    nervous system might also be ordered to aid in diagnosis.
                    <br /> <br />
                    Neurologists treat people with medications, physical therapy
                    or other approaches. <br /> <br />
                  </div>
                  <h3 className="fw-bolder">
                    {" "}
                    What types of tests does a neurologist order ?
                  </h3>{" "}
                  <br />
                  <div className="fs-5">
                    Common neurologic tests include: <br />
                    <br />
                    ● Angiography: Angiography can show if blood vessels in your
                    brain, head or neck are blocked, damaged or abnormal. It can
                    detect such things as aneurysms and blood clots. <br />
                    <br />
                    ● Biopsy : A biopsy is the removal of a piece of tissue from
                    your body. Biopsies may be taken of muscle, nerve or brain
                    tissue. <br />
                    <br />
                    ● Cerebrospinal fluid analysis. This test involves the
                    removal of a sample of the fluid that surrounds your brain
                    and spinal cord. The test can detect evidence of a brain
                    bleed, infection, multiple sclerosis and metabolic diseases.
                    <br />
                    <br />
                    ● Computed tomography (CT), magnetic resonance imaging
                    (MRI), X-raysandultrasound.
                    <br />
                    <br />
                    ● Electroencephalography (EEG). This test measures your
                    brain’s electrical activity and is used to help diagnose
                    seizures and infections (such as encephalitis) brain injury
                    and tumors. <br />
                    <br />
                    ● Electromyography (EMG). This test records the electrical
                    activity in muscles and is used to diagnose nerve and muscle
                    disorders, spinal nerve root compression and motor neuron
                    disorders such as amyotrophic lateral sclerosis. <br />
                    <br />
                    ● Electronystagmography (ENG). This group of tests is used
                    to diagnose involuntary eye movement, dizziness and balance
                    disorders. <br />
                    <br />● Evoked potentials. This test measures how quickly
                    and completely electrical signals reach your brain from your
                    eyes, ears or touch to your skin. The test can help diagnose
                    multiple sclerosis, acoustic neuroma and spinal cord injury.{" "}
                    <br />
                    <br />
                    ● Myelography. This test helps diagnose spinal and spinal
                    cord tumors and herniated disks and fractures. <br />
                    <br />
                    ● Polysomnogram. This test measures brain and body activity
                    during sleep and helps diagnose sleep disorders. <br />
                    <br />
                    ● Positron emission tomography (PET). This imaging test can
                    show tumors or be used to evaluate epilepsy, brain tumors,
                    dementia and Alzheimer’s disease. <br />
                    <br />
                    ● Single-photon emission computed tomography (SPECT). This
                    imaging test can diagnose tumors, infections and assess the
                    location of seizures, degenerative spine disease and stress
                    fractures. <br />
                    <br />
                    ● Thermography. This test measures temperature changes
                    within your body or specific organs and is used to evaluate
                    pain syndromes, peripheral nerve disorders and nerve root
                    compression. <br /> <br />
                  </div>
                  <h3 className="fw-bolder">
                    When should I make an appointment with a neurologist?
                  </h3>
                  <div className="fs-5">
                    Some of the more common symptoms for which you may want to
                    see a neurologist (or be referred to one) include: <br />{" "}
                    <br />
                    ● Memory disturbances, forgetfulness.
                    <br /> <br />
                    ● Loss of consciousness. <br />
                    <br />
                    ● Seizures. <br /> <br />
                    ● Taste or smell disturbances. <br /> <br />
                    ● Vision problems. <br /> <br />
                    ● Numbness and tingling sensations. <br /> <br />
                    ● Facial asymmetries (one side of your face doesn’t match
                    the other [eyelid droops, can’t fully smile]). <br /> <br />
                    ● Vertigo, ringing in the ears (tinnitus) and deafness.{" "}
                    <br /> <br />
                    ● Difficulty swallowing, hoarseness in voice, difficulty in
                    shrugging your shoulders or turning your neck, difficulty
                    with tongue movements. <br />
                    ● Muscle weakness, cramps, spasms and twitching. <br />{" "}
                    <br />● Burning or electrical shock-like pain in any body
                    part. <br /> <br />
                    ● Neck or back pain, headache. <br /> <br />
                    ● Imbalance in gait. <br /> <br />
                    ● Tremors. <br /> <br />● Slowness in movement.
                  </div>{" "}
                  <br /> <br />
                  <h3 className="fw-bolder">
                    How should I prepare for my first neurologist appointment?
                  </h3>
                  <div className="fs-5">
                    To get the most out of your neurologist visit, it’s helpful
                    to be prepared. Ways to prepare include: <br /> <br />
                    ● Bring a list of the most important issues you want to
                    discuss with your neurologist. <br /> <br />
                    ● Discuss any changes in your overall health. <br /> <br />
                    ● Discuss your new symptoms or changes in existing or prior
                    symptoms. Keep a symptom diary (and bring it with you) and
                    record events, including day and time they occurred, how
                    long the event lasted, severity, triggers, symptoms and any
                    action you took to end the event. This is especially useful
                    if you have a condition in which symptoms aren’t constant,
                    such as epilepsy, sleep apnea, headaches or Parkinson’s
                    disease. <br /> <br />
                    ● Bring copies of test results, including a CD of images and
                    lab work ordered by other healthcare providers outside of
                    your neurologist’s health care network. <br /> <br />
                    ● Bring a list of all current products you take. Include
                    prescription medications, over-the-counter medications and
                    any vitamins, supplements and herbal products. Also, let
                    your neurologist know about any previous medications that
                    didn’t work or that caused side effects. <br /> <br />
                    ● Bring a list of your known allergies. <br /> <br />
                    ● Bring a friend or relative with you to the appointment to
                    take notes and be another set of ears and eyes. This person
                    can help review your neurologist’s discussion, ask questions
                    and remind you about scheduling tests and follow-up
                    appointments. <br /> <br />
                    ● Ask if you should schedule another appointment to discuss
                    any additional concerns. <br /> <br />
                  </div>
                  <br />
                  <h2 className="fw-bolder" id="scrollspyAdditionascroll">
                    Additional Common Questions
                  </h2>{" "}
                  <br />
                  <h3 className="fw-bolder">
                    How much schooling does it take to become a neurologist?
                  </h3>
                  <div className="fs-5">
                    To become a neurologist, doctors must complete: <br />
                    <br />
                    ● Four years of college. <br /> <br />
                    ● Four years of medical school. <br /> <br />
                    ● One year of an internship (training in neurology and other
                    fields). <br /> <br />
                    ● Three years of residency (continued training concentrating
                    on the field of neurology). <br /> <br />
                    ● Up to three years of a fellowship. This isn’t mandatory,
                    but a fellowship offers additional training in a neurology
                    subspecialty. This training time may be longer if the
                    neurologist chooses to pursue multiple fellowships. <br />{" "}
                    <br />
                  </div>{" "}
                  <br />
                  <h3 className="fw-bolder">
                    {" "}
                    What are some neurology subspecialty fields ?
                  </h3>
                  <div className="fs-5">
                    Some neurology subspecialty fields include: <br /> <br />
                    ● Brain injury medicine. <br /> <br />
                    ● Child neurology. <br /> <br />
                    ● Clinical neurophysiology. <br /> <br />
                    ● Epilepsy. <br /> <br />
                    ● Headache medicine.
                    <br /> <br />
                    ● Geriatric neurology.
                    <br /> <br />
                    ● Neurodevelopmental disabilities.
                    <br /> <br />
                    ● Neuroimaging.
                    <br /> <br />
                    ● Neuro-oncology.
                    <br /> <br />
                    ● Pain medicine.
                    <br /> <br />
                    ● Sleep medicine.
                    <br /> <br />
                    ● Vascular neurology.
                    <br /> <br />
                  </div>
                  <div className="fw-bold fs-5">
                    {" "}
                    A note from Cleveland Clinic
                  </div>{" "}
                  <br />
                  <div className="fs-5">
                    {" "}
                    A neurologist is a medical doctor who specializes in
                    diseases and conditions affecting your brain, spinal cord
                    and nerves. Your neurologist will examine you, order tests,
                    make a diagnosis, treat your condition with medication or
                    physical therapy or refer you to and work together with
                    other specialists, such as a neurosurgeon or
                    neuro-oncologist, if appropriate. Come prepared with your
                    notes, share your health information and never hesitate to
                    ask questions. Your neurologist is here to help diagnose
                    your condition, treat or manage it as best as possible and
                    support you along the way.{" "}
                  </div>
                </div>
                {/* <div className="vl col-lg-1 col-md-1"></div>
                            <div className="col-md-3" >😀 </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Neurologist;