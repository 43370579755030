import React, { useEffect, useState } from "react";
import {
  convertSelectedDataToServerFormat,
  DataConvertecheck,
  removeMatchingItems,
} from "../../DataConverte/DataConverte";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../ReduxApi/ReduxApi";
import IconFunctionality from "../../IconFunctionality";

import Categories from "../../visitConstant/Categories";
import useDebounce from "../../useDebounce/useDebounce";
import apiHelper from "../../ApiHelpar/apiHelper";
import {
  ADDNEWDATAFORALL,
  DROPDOWNSARCHYNAME,
} from "../../visitConstant/ApiConstant";

import {
  customSelectStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from "../../ui/CSS/customStyles";

import CreatableSelect from "react-select/creatable";
import Select, {
  components,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
} from "react-select";
import DropdownSearchApl from "../../ApiHelpar/DropdownSearchApl";
import ToastFist from "../../Toast/ToastFist";
import { ERROR_MSG } from "../../../Constant";

const Complaints = ({ patientData }) => {

  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [colorStatus, setColorStatus] = useState(3);

  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);
  const [searchTerm, setSearchTerm] = useState([]);
  const [serverData, setServerData] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state

  // const debouncedInput = useDebounce(serverData, 3000);

  const { DropdownServerData, DropdownIsLoading, DropdownError } =
    DropdownSearchApl(
      DROPDOWNSARCHYNAME,
      serverData,
      Categories?.COMPLAINTS.catID
    );

  useEffect(() => {
    setSearchTerm(DropdownServerData);
    setIsLoading(DropdownIsLoading);
  }, [DropdownServerData, DropdownIsLoading]);

  const dispatch = useDispatch();
  // console.log("PATIENT DATA ", patientData);

  // Convert the initial data to react-select format
  const initialComplaints = patientData?.complaintsData?.complaints.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const [Complaints, setComplaints] = useState([]);
  const [TempletDataReserve, setTempletDataReserve] = useState([]);

  const TempletDataTransfer = (TempletData) => {
    // console.log("TempletData===", TempletData);
    setIsLoading(true);
    const formattedData = TempletData.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    const currentTempletData = Array.isArray(TempletDataReserve)
      ? TempletDataReserve
      : [];

    const combinedData = [
      ...(Complaints || []),
      ...currentTempletData,
      ...formattedData,
    ];

    const uniqueData = Array.from(
      new Set(combinedData.map((item) => item.value))
    ).map((value) => combinedData?.find((item) => item.value === value));

    setTempletDataReserve(uniqueData);
    setComplaints(uniqueData);

    setIsLoading(false);
  };

  useEffect(() => {
    setTempletDataReserve(initialComplaints);
  }, [patientData?.complaintsData?.complaints]);

  useEffect(() => {
    setComplaints(TempletDataReserve);
    const serverData = convertSelectedDataToServerFormat(
      "complaints",
      TempletDataReserve
    );
    dispatch(updateField("complaintsData", serverData));
  }, [TempletDataReserve]);

 
  const cleanUpData = (data) => {

    // console.log("data------->>>>>", data)
    // Split the data by commas, filter out empty strings or spaces, and join back
    return data
      .split(",") // Split the string into an array by commas
      .map((item) => item.trim()) // Trim whitespace from each item
      .filter((item) => item !== "") // Remove empty items
      .join(","); // Join the cleaned array back into a string
  };

  const ComplaintshandleSelectChange = async (selectedOptions, actionMeta) => {
    if (actionMeta.action === "clear") {
      setComplaints([]);
      setTempletDataReserve([]);
      setServerData("");
      return;
    }

    const updatedOptions = [...selectedOptions];

    for (let i = 0; i < selectedOptions.length; i++) {
      const option = selectedOptions[i];

      if (option.__isNew__) {
        try {
          const newData = await AddItem(option.label);

          if (newData) {
            updatedOptions[i] = newData;
          } else {
            setShowToast(true);
            setColorStatus(3);
            toastMsg("Already Present")
          }
        } catch (error) {
          setShowToast(true);
          setColorStatus(0);
          setToastMsg(ERROR_MSG)
          console.error("Error adding new item:", error);
        }
      }
    }

    // Convert to the server format and clean the data
    let serverData = convertSelectedDataToServerFormat(
      "complaints",
      updatedOptions
    );

  
// console.log(removeMatchingItems(updatedOptions))

    setComplaints(removeMatchingItems(updatedOptions));
    dispatch(updateField("complaintsData", serverData));


    // console.log("Cleaned serverData:", serverData);
  };

  useEffect(() => {
    if (clearAllData) {
      setComplaints([]);
      setTempletDataReserve([]);
    }
  }, [clearAllData]);

  useEffect(() => {
    setSearchTerm(visitPadData?.complaints);
  }, [visitPadData?.complaints]);

  // useEffect(() => {
  //   if (debouncedInput && debouncedInput.length >= 1) {
  //     fetchData(debouncedInput);
  //   }
  // }, [debouncedInput]);

  const AddItem = async (items) => {
    // alert("calling");
    if (items) {
      try {
        const endpoint = ADDNEWDATAFORALL;
        const data = {
          categoryId: Categories?.COMPLAINTS.catID,
          name: items,
        };

        const response = await apiHelper(endpoint, data);
        const dataConvert = response?.respData?.respMsg;
        // console.log("dataConvert", dataConvert);
        if (dataConvert == "Data Already Present") {
          return "";
        } else {
          return { value: dataConvert.id, label: dataConvert.name };
        }

        // return { value: "", label: "" };
      } catch (error) {
        // console.log("Error fetching data:", error);
        setColorStatus(0);
        setShowToast(true);
        toastMsg(ERROR_MSG)
      } finally {
        // console.log("ok");
      }
    }
  };




  return (
    <>
      <IconFunctionality
        catID={Categories?.COMPLAINTS.catID}
        fieldName={Categories?.COMPLAINTS.name}
        name="Complaints"
        iconSave
        iconRefresh
        iconErase
        // iconPlus
        onClick={() => {
          setComplaints([]); // Correctly reset state
          setTempletDataReserve([]); // Correctly reset state
          setServerData("");
        }}
        setTempData={TempletDataTransfer}
      // InputField={1}
      />

      <div className="col-12   col-xxl-9  mx-4 ">
        <CreatableSelect
          closeMenuOnSelect={false}
          key="select-complaints"
          isMulti
          options={
            searchTerm?.length > 0
              ? DataConvertecheck(searchTerm)
              : DataConvertecheck(visitPadData?.complaints)
          }
          placeholder="Complaints..."
          onChange={ComplaintshandleSelectChange}
          value={Complaints}
          onInputChange={(item) => setServerData(item.toUpperCase())}
          inputValue={serverData || ""}
          // menuIsOpen={true} // Keep the menu open to show loader
          isLoading={isLoading} // Show loading state
          loadingMessage={() => "Loading options..."} // Custom loader message
          components={{ DropdownIndicator, IndicatorSeparator }}
          styles={customSelectStyles}
        />
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message={toastMsg}
        duration="Just now"
        status={colorStatus}
      />
    </>
  );
};

export default Complaints;
