import React from 'react'

export default function HighlightTest() {
  return (
    <div>
      <h1 class="text-center fs-3 text-primary fw-5">JIVDANI HOSPITAL.</h1>
      <p class="text-center fw-bold ">
        Book Doctor and Lab Test On Our Platform With Less Than 60 Seconds
      </p>
    </div>
  );
}
