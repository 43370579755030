import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

import LoginScrollpages from "./LoginScrollpages";
import {
  SESSION_ID,
  BASE_URL,
  CUSTOMER_ID,
  DOCTOR_ID,
  USER_ROLE,
  ACCESS_TOKEN,
  USER_INFO,
  GET_CART_COUNT,
  DEBUG_LOG_ENABLED,
  PASSWORD,
  ROLE,
  validateSmsLocalOtp,
  CART_COUNT,
  USER_ROLE_CHANGES_BUTTON,
  GetTransKey,
} from "../Constant";
import swal from "sweetalert";

import { useDispatch } from "react-redux";
import axios from "axios";
import { increment, handleLoggin } from "../actions";

export default function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const username = location?.state?.mobile;
  // const [data, setData] = useState({});
  const [customer, setCustomer] = useState({
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };

  const loginCustomer = (e) => {
    e.preventDefault();
    if (customer.password === "") {
      swal(" Please entery username and password ");
    } else {
      validateOtp(customer.password);
    }
  };
  let sessionId = localStorage.getItem(SESSION_ID);

  const validateOtp = async (password) => {
    try {
      const validateSmsOtpData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            username: username,
            otp: password,
            fcmToken: "",
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      const response = await axios.post(
        BASE_URL + validateSmsLocalOtp,

        validateSmsOtpData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.respData.respMsg) {
        getTransKeyData();
      }

      localStorage.setItem(
        USER_INFO,
        JSON.stringify(response.data.respData.respMsg)
      );

      localStorage.setItem(
        ACCESS_TOKEN,
        response.data.respData.respMsg.accessToken
      );

      localStorage.setItem(
        CUSTOMER_ID,
        response.data.respData.respMsg.customerId
      );

      localStorage.setItem(DOCTOR_ID, response.data.respData.respMsg.doctorId);
      localStorage.setItem(USER_ROLE, response.data.respData.respMsg.role);

      localStorage.setItem(
        USER_ROLE_CHANGES_BUTTON,
        response.data.respData.respMsg.role
      );
      getCartCountData(
        response.data.respData.respMsg.customerId,
        response.data.respData.respMsg.accessToken
      );

      dispatch(handleLoggin(true));
      if (DEBUG_LOG_ENABLED) {
        console.log(response.data);
      }

      navigate("/");
    } catch (error) {
      // alert(error);
      swal("Entered OTP is invalid");
    }
  };

  const getCartCountData = async (cstId, access_token) => {
    try {
      const getCartCountData = JSON.stringify({
        pubInfo: {
          sessionId: sessionId,
        },
        request: {
          busiParams: {
            customerId: cstId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      // dispatch(increment(response.data.respData.respMsg.cartCount));
      localStorage.setItem(ACCESS_TOKEN, access_token);
      let res = await axios.post(BASE_URL + GET_CART_COUNT, getCartCountData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      });

      // const data = res.data;
      dispatch(increment(res.data.respData.respMsg.cartCount));
      localStorage.setItem(CART_COUNT, res.data.respData.respMsg.cartCount);
    } catch (err) {
      // swal(err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const getTransKeyData = async () => {
    try {
      const transKeyData = JSON.stringify({
        pubInfo: {
          sessionId: "",
        },
        request: {
          busiParams: {
            key: "",
            type: 0,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      // const response = await TransKey.create(transKeyData);
      let response = await axios.post(BASE_URL + GetTransKey, transKeyData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
          "Access-Control-Allow-Headers": "Content-Type",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
        },
      });
      // swal("data : "+response.data.respData.respMsg.sessionId);
      //  setData(response.data.respData.respMsg);

      localStorage.setItem(
        SESSION_ID,
        response.data.respData.respMsg.sessionId
      );

      
    } catch (err) {
      swal("Oops, Something Went Wrong ");
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  return (
    <section className=" mt-5 mx-2 py-5">
      <div className="container  ">
        <div className="row ">
          <div className="card rounded-3 text-black ">
            <div className="row ">
              <LoginScrollpages />
              <div className="col-md-6 col-lg-6 col-xl-6 offset-xl">
                <div className="card-body P-5 ">
                  <div className="text-center">
                    <h4 className="mt-1 fw-bold mb-5 ">OTP </h4>
                  </div>
                  <form>
                    <div className="form-outline mb-4">
                      <h5> Verify your mobile number </h5>
                      <p>Enter OTP sent to {username} mobile number</p>
                      {/* <label className="form-label" htmlFor="form2Example11">
                        Mobile number
                      </label> */}
                      <input
                        name="password"
                        onChange={(e) => handleChange(e)}
                        value={customer.password}
                        className="form-control border border-primary mt-5"
                        placeholder="OTP No....."
                        required
                        aria-required="true"
                        type="tel"
                        size="6"
                        minlength="6"
                        maxlength="6"
                      />
                    </div>
                    {/* <p className="text-end">Resend OTP</p> */}

                    <div className="d-grid gap-2 mt-2 text-bottam">
                      <button
                        type="button"
                        className=" btn  btn-block btn-primary mx-5 rounded-2"
                        style={{ marginTop: 90 }}
                        onClick={loginCustomer}
                      >
                        OTP Login
                      </button>
                      {/* <button
                        // onClick={loginCustomer}
                        type="submit"
                        className="btn btn-primary"
                      >
                        OTP Login
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
