// import { useState, useEffect } from "react";
// import data from "../utils/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Placeholder from "../../src/default_img/placeholder.png";
import { IMG_BASE_URL } from "../Constant";

// function Contact() {
const Contact = () => {
  // const [info, setInfo] = useState(null);
  // const [social, setSocial] = useState(null);

  // useEffect(() => {
  //   setInfo(data.info);
  //   setSocial(data.social);
  // }, []);

  return (
    <div className="row m-3  " style={{ paddingTop: "100px" }}>
      <div className="col-lg-6">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <img
              src={IMG_BASE_URL + "socialmedia/HOSPITAL.jpg"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = { Placeholder };
              }}
              class="d-block w-100 "
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block ">
              <h5>
                Jivdani hospital, Dombivali Multispeciality Hospital | ICU,
                General, Surgical, Maternity, Panchkarma
              </h5>
              {/* <p>
                  {" "}
                  Flat No 1809, Paraiso Apt, Kalyan Shilphata Rd Navi Mumbai
                  MH-421204..
                </p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6   list-group-item d-flex justify-content-between align-items-center flex-wrap">
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
              <span> </span>
              Gmail
            </h6>
            <a href="jivdanihospital1@gmail.com" className="ms-auto link-dark">
              info@jivdanihospital.com
            </a>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
              <span> </span>
              Gmail
            </h6>
            <a href="jivdanihospital1@gmail.com" className="ms-auto link-dark">
              jivdanihospital1@gmail.com
            </a>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-brands fa-square-whatsapp" />
              <span> </span>
              whatsapp
            </h6>
            <a href="https://wa.me/93217 75857" className="ms-auto link-dark">
              093217 75857
            </a>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-brands fa-twitter" />
              <span> </span>
              Twitter
            </h6>
            <a href="https://twitter.com" className="ms-auto link-dark">
              Jivdani hospital, Dombivali Multispeciality Hospital
            </a>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
              <span> </span>
              Instagram
            </h6>
            <span class="text-secondary">
              <a
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/jivdanihospital/",
                    "_blank"
                  )
                }
                // href="https://www.instagram.com/jivdanihospital/"
                className="ms-auto link-dark"
              >
                Jivdani hospital instagram
              </a>
            </span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="fa-brands fa-facebook" />
              <span> </span>
              Facebook
            </h6>
            <a
              href="https://www.facebook.com"
              target="_blank"
              className="ms-auto link-dark"
            >
              Jivdani hospital, Dombivali Multispeciality Hospital
            </a>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
              <FontAwesomeIcon icon="map-marker" />
              <span> </span>
              AddAddress
            </h6>
            <div>
              Kalyan - Shilphata Road Desai Village Sagarli Gaon, Maharashtra
              421204 India
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

// const Contact = () => {
//     const [info, setInfo] = useState(null);

//     useEffect(() => {
//         setInfo(data.info);
//     }, []);

//     return (
//         <>
//             <h2>Contact Us</h2>
//             {
//                 info !== null &&
//                 <div  className="container mt-5 py-4 px-xl-5">
//                     <p>Please contact with me if any issues...</p>
//                     <p>
//                         Email - {info.email} <br />
//                         Phone No - {info.phone_no} <br />
//                     </p>
//                 </div>
//             }
//         </>
//      );
// }

export default Contact;
