import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import customStyles from "../ui/CSS/customStyles";
import { updateField } from "../ReduxApi/ReduxApi";
import { useDispatch, useSelector } from "react-redux";
import IconFunctionality from "../IconFunctionality";
import CustomTooltip from "../CustomTooltip";
import Categories from "../visitConstant/Categories";
import { DataConverte } from "../DataConverte/DataConverte";
import CreatableSelect from "react-select/creatable";
import { handleNewOptions } from "../Utils/handleNewOptions";
import CustomDropdownSingleValue from "../CustomDropdown/CustomDropdownSingalValue";
import RefreshToCustomDropdown from "../CustomDropdown/RefreshToCustomDropdown";
import ToastFist from "../Toast/ToastFist";
// Doctor options and specialties
const doctorOptions = [
  { value: "dr-smith", label: "Dr. Smith" },
  { value: "dr-jones", label: "Dr. Jones" },
  { value: "dr-williams", label: "Dr. Williams" },
];

const doctorSpecialties = [
  { value: "cardiology", label: "Cardiology" },
  { value: "neurology", label: "Neurology" },
  { value: "orthopedics", label: "Orthopedics" },
  // ...other specialties
];
const CustomDropdownIndicator = (props) => null;

const Referred = ({ patientData }) => {
  const visitPadData = useSelector((state) => state?.visitPad?.data);
  const [doctors, setDoctors] = useState([
    {
      doctorId: {
        id: "", // Doctor ID
        name: "", // Doctor's Name
        mobileNo: "", // Doctor's Mobile Number
        email: "", // Doctor's Email Address
        active: true, // Whether the doctor is active
        specializations: [], // Array of specializations
      },

      specialityId: {},
    },
  ]);

  const [serverDoctors, setserverDoctors] = useState([
    {
      doctorId: {
        id: "", // Doctor ID
        name: "", // Doctor's Name
        mobileNo: "", // Doctor's Mobile Number
        email: "", // Doctor's Email Address
        active: true, // Whether the doctor is active
        specializations: [], // Array of specializations
      },

      specialityId: {},
    },
  ]);

  const dispatch = useDispatch();

  // const handleSelectChange = async (selectedOption, field, catID) => {
  //  // console.log("Selected option:", selectedOption, field, catID);

  //   try {
  //     // Create shallow copies of the doctors and serverDoctors arrays
  //     const newDoctors = [...doctors];
  //     const serverDataDoctors = [...serverDoctors];

  //     // Ensure the selectedOption has specializations before adding to arrays
  //     if (selectedOption && selectedOption.specializations) {
  //       // Loop through each specialization to create individual entries
  //       selectedOption.specializations.forEach((specialization) => {
  //         // Create a new doctor object with full details
  //         const newDoctor = {
  //           doctorId: {
  //             id: selectedOption.id,
  //             name: selectedOption.name,
  //             mobileNo: selectedOption.mobileNo,
  //             email: selectedOption.email,
  //             active: selectedOption.active,
  //             specializations: selectedOption.specializations,
  //           },
  //           specialityId: {
  //             id: specialization.id,
  //             name: specialization.name,
  //           },
  //         };

  //         // Add to the local doctors array
  //         newDoctors.push(newDoctor);

  //         // Prepare the serverDataDoctors entry
  //         const newServerDoctor = {
  //           doctorId: {
  //             id: selectedOption.id,
  //             name: selectedOption.name,
  //             mobileNo: selectedOption.mobileNo,
  //             email: selectedOption.email,
  //             active: selectedOption.active,
  //             specializations: selectedOption.specializations,
  //           },
  //           specialityId: {
  //             id: specialization.id,
  //             name: specialization.name,
  //           },
  //         };

  //         // Append to the serverDataDoctors array
  //         serverDataDoctors.push(newServerDoctor);
  //       });

  //      // console.log("Updated doctors:", newDoctors);
  //      // console.log("Updated serverDoctors:", serverDataDoctors);

  //       const hasValidData = (doctor) => {
  //         return doctor.doctorId.id !== "" && doctor.doctorId.name !== "";
  //       };

  //       // Filter out objects with empty doctorId data
  //       const filteredData = serverDataDoctors.filter((doctor) => hasValidData(doctor));

  //       // Update local state with the new doctors data
  //       setDoctors(newDoctors);
  //       setserverDoctors(filteredData);

  //       // Dispatch updated data to the Redux store
  //       dispatch(updateField("referredTo", filteredData));
  //     } else {
  //       console.error("Invalid selected option provided.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating doctor selection:", error);
  //   }
  // };

  // const handleSelectChange = async (selectedOption, field, catID) => {
  //  // console.log("Selected option:", selectedOption, field, catID);

  //   try {
  //     // Fetch updated options for the selected doctor (optional)
  //     const updatedOptions = await handleNewOptions(selectedOption, catID);

  //     // Create shallow copies of the doctors and serverDoctors arrays
  //     const newDoctors = [...doctors];
  //     const serverDataDoctors = [...serverDoctors];

  //     // Ensure the selectedOption has doctorId and specialityId before adding to arrays
  //     if (selectedOption && selectedOption && selectedOption.specializations) {
  //       // Create a new doctor object to append, with full details
  //       const newDoctor = {
  //         doctorId: selectedOption.doctorId,
  //         specializations: selectedOption.specializations,
  //       };

  //       // Add to the local doctors array
  //       newDoctors.push(newDoctor);

  //       // Prepare the serverDataDoctors entry with full doctor info and selected specialty
  //       const newServerDoctor = {
  //         id: selectedOption.id,
  //         name: selectedOption.name,
  //         mobileNo: selectedOption.mobileNo,
  //         email: selectedOption.email,
  //         active: selectedOption.active,
  //         specializations: selectedOption.specializations,
  //         specialityId: selectedOption.specializations[0],
  //       };
  //       // Append to the serverDataDoctors array
  //       serverDataDoctors.push(newServerDoctor);

  //      // console.log("Updated doctors:", newDoctors);
  //      // console.log("Updated serverDoctors-------:", serverDataDoctors);

  //       const hasValidData = (doctorId) => {
  //         return doctorId.id !== "" && doctorId.name !== "";
  //       };

  //       // Filter out objects with empty doctorId data
  //       const filteredData = serverDataDoctors.filter((item) =>
  //         hasValidData(item)
  //       );

  //       // Update local state with the new doctors data
  //       setDoctors(filteredData);
  //       setserverDoctors(filteredData);
  //       // Dispatch updated data to the Redux store
  //       dispatch(updateField("referredTo", filteredData));
  //     } else {
  //       console.error("Invalid selected option provided.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating doctor selection:", error);
  //   }
  // };

  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);

//   const handleSelectChange = async (selectedOption, index, catID) => {
//    // console.log("Selected option:", selectedOption, field, catID);

//     try {
//       // Ensure the selectedOption has valid data
//       if (
//         !selectedOption ||
//         !selectedOption.specializations ||
//         !Array.isArray(selectedOption.specializations)
//       ) {
//         console.error("Invalid selected option provided.");
//         return;
//       }

//       // Create shallow copies of the doctors and serverDoctors arrays
//       const newDoctors = [...doctors];
//       const serverDataDoctors = [...serverDoctors];

//       // Get the first specialization (or handle it differently if needed)
//       const firstSpecialization = selectedOption.specializations[0]; // Assuming you only want to handle the first one

//       // Check if the doctor with this specialization already exists
//       const existingDoctorIndex = newDoctors?.findIndex(
//         (doc) =>
//           doc.doctorId.id === selectedOption.id &&
//           doc.specialityId.id === firstSpecialization.id
//       );
     
     

//       // If doctor with the same specialization already exists, avoid duplicate entry
//       // if (existingDoctorIndex === -1) {
//         // Create a new doctor object with full details
//         const newDoctor = {
//           doctorId: {
//             id: selectedOption.id,
//             name: selectedOption.name,
//             mobileNo: selectedOption.mobileNo,
//             email: selectedOption.email,
//             active: selectedOption.active,
//             specializations: selectedOption.specializations, // Full specializations array
//           },
//           specialityId: {
//             id: firstSpecialization.id, // Using only the first specialization
//             name: firstSpecialization.name,
//           },
//         };
//         const data = newDoctors[index] =newDoctor
     
//         // Add to the local doctors array
//         newDoctors.push(data);
//         console.log(newDoctors ,"testing-01")
//         // Prepare the serverDataDoctors entry
//         const newServerDoctor = {
//           doctorId: {
//             id: selectedOption?.id,
//             name: selectedOption?.name,
//             mobileNo: selectedOption?.mobileNo,
//             email: selectedOption?.email,
//             active: selectedOption?.active,
//             specializations: selectedOption?.specializations, // Full specializations array
//           },
//           specialityId: {
//             id: firstSpecialization?.id,
//             name: firstSpecialization?.name,
//           },
//         };

//         // Append to the serverDataDoctors array
//         // serverDataDoctors.push(newServerDoctor);
//         serverDataDoctors.push(data);
//       // } else {
//       //   // console.log("Doctor with this specialization already exists, skipping addition.");
//       // }

//       // console.log("Updated doctors:", newDoctors);
//       // console.log("Updated serverDoctors:", serverDataDoctors);

//       // Function to check if the doctor object has valid data
//       const hasValidData = (doctor) => {
//         return (
//           doctor.doctorId.id &&
//           doctor.doctorId.name &&
//           doctor.specialityId.id &&
//           doctor.specialityId.name
//         );
//       };

//       // Filter out objects with empty or invalid doctorId and specialityId data
//       const filteredData = serverDataDoctors.filter(hasValidData);

//       // Update local state with the new doctors data
//       setDoctors(filteredData);
//       setserverDoctors(filteredData);
// console.log("referredTo", filteredData)
//       // Dispatch updated data to the Redux store
//       dispatch(updateField("referredTo", filteredData));
//     } catch (error) {
//       console.error("Error updating doctor selection:", error);
//     }
//   };


const handleSelectChange = async (selectedOption, index) => {
  try {
    // Validate the selected option
    if (!selectedOption || !Array.isArray(selectedOption.specializations) || selectedOption.specializations.length === 0) {
      console.error("Invalid selected option provided.");
      return;
    }

    // Create shallow copies of the doctors and serverDoctors arrays
    const newDoctors = [...doctors];
    const serverDataDoctors = [...serverDoctors];

    // Get the first specialization
    const firstSpecialization = selectedOption.specializations[0];

    // If an index is provided, update the existing doctor at that index
    if (index !== undefined && index >= 0 && index < newDoctors.length) {
      newDoctors[index] = {
        doctorId: {
          id: selectedOption.id,
          name: selectedOption.name,
          mobileNo: selectedOption.mobileNo,
          email: selectedOption.email,
          active: selectedOption.active,
          specializations: selectedOption.specializations,
        },
        specialityId: {
          id: firstSpecialization.id,
          name: firstSpecialization.name,
        },
      };
    } else {
      // If index is not provided or out of bounds, check for existing doctor
      const existingDoctorIndex = newDoctors.findIndex(
        (doc) => doc.doctorId.id === selectedOption.id && doc.specialityId.id === firstSpecialization.id
      );

      if (existingDoctorIndex !== -1) {
        // Update the existing doctor with new data
        newDoctors[existingDoctorIndex] = {
          ...newDoctors[existingDoctorIndex],
          doctorId: {
            ...newDoctors[existingDoctorIndex].doctorId,
            ...selectedOption,
          },
          specialityId: firstSpecialization,
        };
      } else {
        // Create a new doctor object if it doesn't exist
        const newDoctor = {
          doctorId: {
            id: selectedOption.id,
            name: selectedOption.name,
            mobileNo: selectedOption.mobileNo,
            email: selectedOption.email,
            active: selectedOption.active,
            specializations: selectedOption.specializations,
          },
          specialityId: {
            id: firstSpecialization.id,
            name: firstSpecialization.name,
          },
        };

        // Add the new doctor to the array
        newDoctors.push(newDoctor);
      }
    }

    // Filter out invalid data
    const filteredData = newDoctors.filter((doctor) => {
      return (
        doctor.doctorId.id &&
        doctor.doctorId.name &&
        doctor.specialityId.id &&
        doctor.specialityId.name
      );
    });

    // Update local state with the new doctors data
    setDoctors(filteredData);
    setserverDoctors(filteredData);

    // Dispatch updated data to the Redux store
    dispatch(updateField("referredTo", filteredData));

    console.log("referredTo", filteredData)

  } catch (error) {
    console.error("Error updating doctor selection:", error);
  }
};


  const handleSelectChangespeciality = async (
    selectedOption,
    index,
    field,
    catID
  ) => {
    // console.log("Updated options:", selectedOption, index, field, catID);

    try {
      // Fetch updated options for the selected doctor
      const updatedOptions = await handleNewOptions(selectedOption, catID);

      // Log updated option details for debugging
       console.log("Updated options:", updatedOptions, index, field);

      // Create shallow copies of the doctors and serverDoctors arrays
      const newDoctors = [...doctors];
      const serverDataDoctors = [...serverDoctors];

      // Check if the selectedOption is valid before updating
      if (selectedOption && updatedOptions) {
        // Update the specialty in the newDoctors array
        newDoctors[index][field] = updatedOptions.label; // Update with label

        // Update the specialty ID in the serverDataDoctors array
        serverDataDoctors[index][field] = {
          id: updatedOptions.value, // Assuming updatedOptions has an id property
          name: updatedOptions.label, // Assuming updatedOptions has a label property
        };

        // console.log("Updated serverDataDoctors:", serverDataDoctors);

        // Update local state with new doctor data
        setDoctors(serverDataDoctors);
        setserverDoctors(serverDataDoctors);

        // Dispatch updated data to the Redux store
        dispatch(updateField("referredTo", serverDataDoctors));

        // Optionally add a new doctor if the selected index is the last one
        // Uncomment if necessary
        // if (index === doctors.length - 1) {
        //   addDoctor();
        // }
      } else {
        console.error("Invalid selected option or updated options.");
      }
    } catch (error) {
      // Handle any errors during the operation
      console.error("Error updating specialty selection:", error);
    }
  };

  const handleInputChange = (e, index, field) => {
    try {
      // Create shallow copies of the doctors and serverDoctors arrays
      const newDoctors = [...doctors];
      const serverDataDoctors = [...serverDoctors];

      // Check if the selected field is either mobileNo or email
      if (field === "mobileNo") {
        // Update mobileNo in the doctorId object for both newDoctors and serverDataDoctors
        newDoctors[index].doctorId.mobileNo = e.target.value; // Update local doctor data
        serverDataDoctors[index].doctorId.mobileNo = e.target.value; // Update server data
      } else if (field === "email") {
        // Update email in the doctorId object for both newDoctors and serverDataDoctors
        newDoctors[index].doctorId.email = e.target.value; // Update local doctor data
        serverDataDoctors[index].doctorId.email = e.target.value; // Update server data
      }

      // Log the updated serverDataDoctors array for debugging
      // console.log("Updated serverDataDoctors:", serverDataDoctors);

      // Update state with the modified data
      setDoctors(newDoctors);
      setserverDoctors(serverDataDoctors);

      // Dispatch updated data to the Redux store
      dispatch(updateField("referredTo", serverDataDoctors));
    } catch (error) {
      console.error("Error handling input change:", error);
    }
  };

  const addDoctor = () => {
    setDoctors([
      ...doctors,
      {
        doctorId: {
          id: "", // Doctor ID
          name: "", // Doctor's Name
          mobileNo: "", // Doctor's Mobile Number
          email: "", // Doctor's Email Address
          active: true, // Whether the doctor is active
          specializations: [], // Array of specializations
        },

        specialityId: {},
      },
    ]);

    setserverDoctors([
      ...serverDoctors,
      {
        doctorId: {
          id: "", // Doctor ID
          name: "", // Doctor's Name
          mobileNo: "", // Doctor's Mobile Number
          email: "", // Doctor's Email Address
          active: true, // Whether the doctor is active
          specializations: [], // Array of specializations
        },

        specialityId: {},
      },
    ]);
  };

  const handleSubmit = () => {
    // console.log("Submitted Data:", doctors);
    // Further processing or API call can be made here
  };

  const [DataClear, setDataClear] = useState(false);

  const clearAllData = useSelector((state) => state.ClearAllData.isLoggedIn);

  const handleClearData = useCallback(() => {
    setDoctors([
      {
        doctorId: {
          id: "", // Doctor ID
          name: "", // Doctor's Name
          mobileNo: "", // Doctor's Mobile Number
          email: "", // Doctor's Email Address
          active: true, // Whether the doctor is active
          specializations: [], // Array of specializations
        },

        specialityId: {},
      },
    ]);
    setserverDoctors([
      {
        doctorId: {
          id: "", // Doctor ID
          name: "", // Doctor's Name
          mobileNo: "", // Doctor's Mobile Number
          email: "", // Doctor's Email Address
          active: true, // Whether the doctor is active
          specializations: [], // Array of specializations
        },

        specialityId: {},
      },
    ]);
    setDataClear(true);
    dispatch(updateField("referredTo", []));
  }, [dispatch]);

  useEffect(() => {
    if (clearAllData) {
      handleClearData();
    }
  }, [clearAllData, handleClearData]);

  // Removes a row by index
  const removeRow = (index) => {
    if (doctors.length > 1) {
      const updatedRows = doctors.filter((_, i) => i !== index);
      const serverDoctorsupdatedRows = serverDoctors.filter(
        (_, i) => i !== index
      );
      setDoctors(updatedRows);
      setserverDoctors(serverDoctorsupdatedRows);
      dispatch(updateField("referredTo", serverDoctorsupdatedRows));
      // // console.log(updatedRows)
    }
  };

  useEffect(() => {
    if (patientData?.referredTo) {
      const formattedDoctors = patientData?.referredTo?.map(
        (selectedOption) => ({
          id: selectedOption?.id,
          name: selectedOption?.name,
          mobileNo: selectedOption?.mobileNo,
          email: selectedOption?.email,
          active: selectedOption?.active,
          // specializations: selectedOption?.specializations,
          // specialityId: selectedOption?.specializations[0],
        })
      );

      setDoctors(formattedDoctors);

      if (formattedDoctors.length >= 0) {
        setDoctors([
          {
            doctorId: {
              id: "", // Doctor ID
              name: "", // Doctor's Name
              mobileNo: "", // Doctor's Mobile Number
              email: "", // Doctor's Email Address
              active: true, // Whether the doctor is active
              specializations: [], // Array of specializations
            },

            specialityId: {},
          },
        ]);
        setserverDoctors([
          {
            doctorId: {
              id: "", // Doctor ID
              name: "", // Doctor's Name
              mobileNo: "", // Doctor's Mobile Number
              email: "", // Doctor's Email Address
              active: true, // Whether the doctor is active
              specializations: [], // Array of specializations
            },

            specialityId: {},
          },
        ]);
      }

      dispatch(updateField("referredTo", formattedDoctors));
    }
  }, [patientData?.referredTo]);

  const referredTo = [
    {
      doctorId: {
        id: 1,
        name: "Dr. rahul Smith",
        mobileNo: 8120056106,
        email: "RR.com",
        active: true,
        specializations: [
          { id: 1, name: "Cardiology" },
          { id: 3, name: "Pediatrics" },
        ],
      },
      specialityId: { id: 1, name: "Cardiology" },
    },
    {
      doctorId: {
        id: 2,
        name: "Dr. John Smith",
        mobileNo: 1234567890,
        email: "john@example.com",
        active: true,
        specializations: [
          { id: 1, name: "Cardiology" },
          { id: 3, name: "Pediatrics" },
        ],
      },
      specialityId: { id: 3, name: "Pediatrics" },
    },
  ];
  // console.log(visitPadData?.doctorLists, "visitPadData?.doctorLists---");

  const handleAddNewItemOther = () => {
    setShowToast(true);
    setColorStatus(0);
  };
  return (
    <>
      <IconFunctionality
        name="Referred to"
        // iconSave
        // iconRefresh
        iconErase
        onClick={() => handleClearData()}
        catID={""}
        fieldName={""}
        iconPlus
      />

      <div className="col-12 col-xxl-9 px-4">
        {doctors?.map((doctor, index) => (
          <div className="row mb-3" key={index}>
            <div className="col-4 ">
              <label>Doctor Name</label>
              <div className=" border border-1 d-flex rounded-1 ">
                <div className="col-6 ">
                  <RefreshToCustomDropdown
                    options={visitPadData?.doctorLists}
                    // options={referredTo}
                    value={doctor}
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        selectedOption,
                        index,
                        "doctorName",
                        Categories?.DOCTOR_LIST.catID
                      )
                    }
                    DataClear={DataClear}
                    onAddNewItem={(selectedOption) =>
                      handleAddNewItemOther(
                        index,
                        "unit",
                        index,
                        selectedOption,
                        Categories?.UNITS?.catID
                      )
                    }
                  />

                  {/* <CreatableSelect
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    options={DataConverte(visitPadData?.doctorLists)}
                    placeholder="Doctor Name"
                    onChange={(selectedOption) =>
                      handleSelectChange(
                        selectedOption,
                        index,
                        "doctorName",
                        Categories?.DOCTOR_LIST.catID
                      )
                    }
                    value={
                      doctor.doctorId.name
                       
                    }
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                  /> */}
                </div>

                <div className="col-6 " style={{ paddingLeft: 2 }}>
                  <CreatableSelect
                    closeMenuOnSelect={true}
                    styles={customStyles}
                    options={DataConverte(visitPadData?.specialities)}
                    placeholder="speciality"
                    // onChange={(selectedOption) =>
                    //   handleSelectChange(selectedOption, index, "speciality")

                    // }
                    onChange={(selectedOption) =>
                      handleSelectChangespeciality(
                        selectedOption,
                        index,
                        "specialityId",
                        Categories?.SPECIALITY.catID
                      )
                    }
                    value={
                      doctor.specialityId
                        ? {
                            label: doctor.specialityId.name,
                            value: doctor.specialityId.id,
                          }
                        : null
                    }
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <label>Phone Number</label>
              {/* <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              value={doctor.doctorPhoneNo}
              onChange={(e) => handleInputChange(e, index, "doctorPhoneNo")}
            /> */}

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text rounded-0">+91</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={doctor?.doctorId?.mobileNo}
                  maxlength="10"
                  onChange={(e) => handleInputChange(e, index, "mobileNo")}
                />
              </div>
            </div>

            <div className="col-3">
              <label>Email</label>
              {/* <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={doctor.email}
              onChange={(e) => handleInputChange(e, index, "email")}
            /> */}

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text rounded-0">@</span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={doctor?.doctorId?.email}
                  onChange={(e) => handleInputChange(e, index, "email")}
                />
              </div>
            </div>
            <div className="col-2">
              <label> </label>
              <div>
                {index !== doctors.length - 1 ? (
                  <div onClick={() => removeRow(index)}>
                    <CustomTooltip
                      icon="fa-solid fa-trash-can"
                      tooltipText="Delete"
                    />
                  </div>
                ) : (
                  <button className="btn btn-primary mb-3" onClick={addDoctor}>
                    +
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message="Doctor not added"
        duration="Just now"
        status={colorStatus}
      />

      {/* <button className="btn btn-success" onClick={handleSubmit}>
        Submit
      </button>

      <pre>{JSON.stringify(doctors, null, 2)}</pre> */}
    </>
  );
};

export default Referred;
