
// import { Link } from "react-router-dom";
// import CashLessPolicy from "./CashLessPolicy";
// // import { useNavigate } from "react-router-dom";
// // import Placeholder from "../../src/default_img/placeholder.png";

// function BannerIncidator(props) {
//   return (
//     <button
//       type="button"
//       data-bs-target="#bannerIndicators"
//       data-bs-slide-to={props.index}
//       className={props.active ? "active " : ""}
//       aria-current={props.active}
//     />
//   );
// }

// function BannerImage(props) {
//   // const navigate = useNavigate();
//   return (
//     <div
//       className={"carousel-item " + (props.active ? "active" : "")}
//       data-bs-interval="3000"
//     >
//       <div
//         className="ratio "
//         style={{ "--bs-aspect-ratio": "80%", maxHeight: "550px" }}
//       >
//         <Link
//           // to={"/productdetail/" + props?.itemId}
//           to={"/productdetail/" + props?.item.itemId}
//           onClick={() =>
//             localStorage.setItem("myProduct", JSON.stringify(props.item))
//           }
//         >
//           <img
//             className="rounded bg-dark w-100 h-100 rounded-5"
//             alt=""
//             src={props.image}
//             key={props.image}
//           />
//         </Link>
//       </div>

//     </div>
//   );
// }

// function Banner({ product }) {
//   // useEffect(() => {}, []);

//   return (
//     <div aria-hidden="true">
//       <div
//         id="bannerIndicators"
//         className="carousel carousel-dark  slide"
//         data-bs-ride="carousel"
//         aria-hidden="true"
//         style={{ paddingBottom: "40px" }}
//       >
//         <div className="carousel-indicators">
//           {product?.items?.map((it, index) => (
//             <BannerIncidator
//               item={it}
//               index={"" + index}
//               active={index === 0 ? true : false}
//             />
//           ))}
//         </div>
//         <div className="carousel-inner">

//           {product?.items?.map((it, index) => (
//             <BannerImage
//               item={it}
//               image={it.iconURL}
//               active={index === 0 ? true : false}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Banner;





// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";



// function BannerIndicator(props) {
//   return (
//     <button
//       type="button"
//       data-bs-target="#bannerIndicators"
//       data-bs-slide-to={props.index}
//       className={props.active ? "active " : ""}
//       aria-current={props.active}
//     />
//   );
// } 
// function BannerImage(props) {
//   return (
//     <div
//       className={"carousel-item " + (props.active ? "active" : "")}
//       data-bs-interval="3000"
//     >
//       <div
//         className="ratio"
//         style={{ "--bs-aspect-ratio": "80%", maxHeight: "550px" }}
//       >
//         <Link
//           to={"/productdetail/" + props?.item.itemId}
//           onClick={() =>
//             localStorage.setItem("myProduct", JSON.stringify(props.item))
//           }
//         >
//           <img
//             className="rounded bg-dark w-100 h-100 rounded-5"
//             alt=""
//             src={props.image}
//             key={props.image}
//           />
//         </Link>
//       </div>
//     </div>
//   );
// }

// function Banner({ product }) {
//   const [activeIndex, setActiveIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       // Auto-advance to the next slide
//       setActiveIndex((prevIndex) => (prevIndex + 1) % product?.items.length);
//     }, 3000);

//     // Clear the interval when the component unmounts
//     return () => clearInterval(intervalId);
//   }, [product?.items.length]);

//   const goToSlide = (index) => {
//     setActiveIndex(index);
//   };

//   const goToPrevSlide = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === 0 ? product?.items.length - 1 : prevIndex - 1
//     );
//   };

//   const goToNextSlide = () => {
//     setActiveIndex((prevIndex) => (prevIndex + 1) % product?.items.length);
//   };

//   return (
//     <div aria-hidden="true">
//       <div
//         id="bannerIndicators"
//         className="carousel carousel-dark slide"

//         style={{ paddingBottom: "40px" }}
//       >
//         <div className="carousel-indicators">
//           {product?.items?.map((it, index) => (
//             <BannerIndicator
//               key={index}
//               active={index === activeIndex}
//               onClick={() => goToSlide(index)}
//             />
//           ))}
//         </div>
//         <div className="carousel-inner">
//           {product?.items?.map((it, index) => (
//             <BannerImage
//               key={index}
//               item={it}
//               image={it.iconURL}
//               active={index === activeIndex}
//             />
//           ))}
//         </div>
//         <button
//           className="carousel-control-prev"
//           type="button"
//           onClick={goToPrevSlide}
//         >
//           <span
//             className="carousel-control-prev-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Previous</span>
//         </button>
//         <button
//           className="carousel-control-next"
//           type="button"
//           onClick={goToNextSlide}
//         >
//           <span
//             className="carousel-control-next-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Next</span>
//         </button>
//       </div>
//     </div>
//   );
// }

// export default Banner;



import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IMG_BASE_URL } from "../Constant";
import { Carousel } from "bootstrap";


// function BannerIndicator({ index, active, onClick }) {
//   return (
//     <button
//       type="button"
//       data-bs-target="#bannerIndicators"
//       data-bs-slide-to={index}
//       className={active ? "active" : ""}
//       aria-current={active}
//       onClick={() => onClick(index)}
//     />
//   );
// }

// function BannerImage({ item, image, active }) {
//   return (
//     <div
//       className={`carousel-item ${active ? "active" : ""}`}
//       style={{ maxHeight: "550px"}}
//     >
//       {/* <div
//         className="ratio"
//         style={{ "--bs-aspect-ratio": "80%", maxHeight: "550px" }}
//       > */}
// <Link
//   to={`/productdetail/${item?.itemId}`}
//   onClick={() => localStorage.setItem("myProduct", JSON.stringify(item))}
// >
//         <img
//           className="rounded bg-dark w-100 h-100 rounded-2"
//           alt=""
//           src={ IMG_BASE_URL+image}
//           key={image}
//         />
//       </Link>
//       {/* </div> */}
//     </div>
//   );
// }

// function Banner({ product }) {
//   const [activeIndex, setActiveIndex] = useState(0);

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % product?.items.length);
//     }, 3000);

//     return () => clearInterval(intervalId);
//   }, [product?.items.length]);

//   const goToSlide = (index) => {
//     setActiveIndex(index);
//   };

//   const goToPrevSlide = () => {
//     setActiveIndex((prevIndex) =>
//       prevIndex === 0 ? product?.items.length - 1 : prevIndex - 1
//     );
//   };

//   const goToNextSlide = () => {
//     setActiveIndex((prevIndex) => (prevIndex + 1) % product?.items.length);
//   };

//   return (
//     <div aria-hidden="true">
//       <div
//         id="bannerIndicators"
//         className="carousel carousel-dark slide"
//        style={{ paddingBottom: "40px"}}
//       >
//         <div className="carousel-indicators">
//           {product?.items?.map((it, index) => (
//             <BannerIndicator
//               key={index}
//               index={index}
//               active={index === activeIndex}
//               onClick={goToSlide}
//             />
//           ))}
//         </div>
//         <div className="carousel-inner">

//           {product?.items?.map((it, index) => (
//             <BannerImage
//               key={index}
//               item={it}
//               image={it.iconURL}
//               active={index === activeIndex}
//             />
//           ))}
//         </div>
//         <button
//           className="carousel-control-prev"
//           type="button"
//           onClick={goToPrevSlide}
//         >
//           <span
//             className="carousel-control-prev-icon   rounded-circle btn btn-primary "
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Previous</span>
//         </button>
//         <button
//           className="carousel-control-next"
//           type="button"
//           onClick={goToNextSlide}
//         >
//           <span
//             className="carousel-control-next-icon rounded-circle btn btn-primary "
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden ">Next</span>
//         </button>
//       </div>
//     </div>
//   );
// }






// const Banner = ({ product }) => {
//   const [carouselInstance, setCarouselInstance] = useState(null);

//   useEffect(() => {
//     const carouselElement = document.getElementById("multi-item-carousel");
//     const carousel = new Carousel(carouselElement, {
//       interval: 5000, // Change this to set interval between slides
//       perPage: 1, // Display one item at a time
//  breakpoints: {
//         992: {
//           perPage: 2,
//         },
//         768: {
//           perPage: 1,
//         },
//       },
// });
//     setCarouselInstance(carousel);
//     return () => carousel.dispose(); // Clean up on unmount
//   }, []);

//   return (
//     <div
//       id="multi-item-carousel"
//       className="carousel slide"
//       data-bs-ride="carousel"
//     >
//       <div className="carousel-inner">
//         {product?.items?.map((item, index) => (
//           <div
//             key={index}
//             className={`carousel-item ${index === 0 ? "active" : ""}`}
//           >
//             <div className="col">
//               <div className="card rounded-5">
//                 <Link
//                   to={`/productdetail/${item?.itemId}`}
//                   onClick={() =>
//                     localStorage.setItem("myProduct", JSON.stringify(item))
//                   }
//                 >
//                   <img
//                     src={IMG_BASE_URL + item.iconURL}
//                     className="card-img-top rounded-5 "
//                     alt={item.title}
//                     style={{ maxHeight: 550}}
//                   />
//                 </Link>

//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <button
//         className="carousel-control-prev "
//         type="button"
//         data-bs-target="#multi-item-carousel"
//         data-bs-slide="prev"
//       >
//         <span
//           className="carousel-control-prev-icon  rounded-circle btn btn-primary"
//           aria-hidden="true"
//         ></span>
//         <span className="visually-hidden">Previous</span>
//       </button>
//       <button
//         className="carousel-control-next"
//         type="button"
//         data-bs-target="#multi-item-carousel"
//         data-bs-slide="next"
//       >
//         <span
//           className="carousel-control-next-icon  rounded-circle btn btn-primary"
//           aria-hidden="true"
//         ></span>
//         <span className="visually-hidden">Next</span>
//       </button>
//     </div>
//   );
// };











// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// function Banner({ product }) {
//   let sliderRef = useRef(null);

//   const play = () => {
//     sliderRef.current.slickPlay();
//   };

//   const pause = () => {
//     sliderRef.current.slickPause();
//   };

//   const NextArrow = ({ onClick }) => (
//     <div
//       className="slick-arrow next"
//       onClick={onClick}
//       style={{ color: "black" }}
//     >
//       Next
//     </div>
//   );

//   const PrevArrow = ({ onClick }) => (
//     <div
//       className="slick-arrow prev"
//       onClick={onClick}
//       style={{ color: "black" }}
//     >
//       Previous
//     </div>
//   );

//   const settings = {
//     dots: true,
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     // nextArrow: <NextArrow />,
//     // prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//     ],
//   };

//   return (
//     <div
//       className="slider-container p-2"
//       //  style={{ backgroundColor: "red"}}
//     >
//       <Slider ref={sliderRef} {...settings}>
//         {product.items.map((item, index) => (
//           <div key={item.id}>
//             <Link
//               to={`/productdetail/${item?.itemId}`}
//               onClick={() =>
//                 localStorage.setItem("myProduct", JSON.stringify(item))
//               }
//             >
//               <img
//                 src={IMG_BASE_URL + item.iconURL}
//                 alt={item.title}
//                 className="carousel-image rounded-4 me-5"
//                 style={{ maxHeight: 300, maxWidth: "98%" }}
//               />
//             </Link>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }




import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Banner.css"; // Import the custom CSS file



function Banner({ product }) {
  let sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="slider-container p-2">
      <Slider ref={sliderRef} {...settings}>
        {product.items.map((item, index) => (
          <div key={item.id}>
            <Link
              to={`/productdetail/${item?.itemId}`}
              onClick={() =>
                localStorage.setItem("myProduct", JSON.stringify(item))
              }
            >
              <img
                src={IMG_BASE_URL + item.iconURL}
                alt={item.title}
                className="carousel-image rounded-4 me-5"
                style={{ maxHeight: 195, width: "98%" }}
              // style={{ maxHeight: 300, maxWidth: "98%" }}
              />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}







// import Slider from "react-slick";

// function Banner({ product }) {
//   const settings = {
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "80px",
//     slidesToShow: 1,
//     speed: 500,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,

//   };
//   return (
//     <div className="slider-container">
//       <Slider {...settings}>
//         {product.items.map((item, index) => (
//           <div key={item.id}>
//             <img
//               src={IMG_BASE_URL + item.iconURL}
//               alt={item.title}
//               className="carousel-image rounded-4 "
//               //style={{   maxHeight:450, maxWidth: 5000 }}
//             />
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// }

























// const Banner = ({ product }) => {
//   const [carouselInstance, setCarouselInstance] = useState(null);

//   // Initialize the carousel when component mounts
//   React.useEffect(() => {
//     const carouselElement = document.getElementById("multi-item-carousel");
//     const carousel = new Carousel(carouselElement, {
//       interval: 5000, // Change this to set interval between slides
//       perPage: 3, // Number of items to show per slide
//       peek: 20, // Space between items
//       breakpoints: {
//         992: {
//           perPage: 2,
//         },
//         768: {
//           perPage: 1,
//         },
//       },
//     });
//     setCarouselInstance(carousel);
//     return () => carousel.dispose(); // Clean up on unmount
//   }, []);

//   return (
//     <div
//       id="multi-item-carousel"
//       className="carousel slide"
//       data-bs-ride="carousel"
//     >
//       <div className="carousel-inner">
//         {product.items.map((item, index) => (
//           <div
//             key={index}
//             className={`carousel-item ${index === 0 ? "active" : ""}`}
//           >
//             <div className="d-flex justify-content-between">
//               {product.items.map((subItem, subIndex) => (
//                 <div key={subIndex} className="col">
//                   <div className="card">
//                     <img
//                       src={IMG_BASE_URL + subItem.iconURL}
//                       className="card-img-top"
//                       // alt={subItem.title}
//                     />
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//       <button
//         className="carousel-control-prev"
//         type="button"
//         data-bs-target="#multi-item-carousel"
//         data-bs-slide="prev"
//       >
//         <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Previous</span>
//       </button>
//       <button
//         className="carousel-control-next"
//         type="button"
//         data-bs-target="#multi-item-carousel"
//         data-bs-slide="next"
//       >
//         <span className="carousel-control-next-icon" aria-hidden="true"></span>
//         <span className="visually-hidden">Next</span>
//       </button>
//     </div>
//   );
// };











export default Banner;




