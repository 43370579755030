import React, { useEffect, useMemo, useState } from "react";
import {
  BASE_URL,
  ROLE,
  ROLE_LAB,
  SESSION_ID,
  SUPER_ADNIM,
  update_User_Token,
  USER_ROLE,
} from "../Constant";
import swal from "sweetalert";
import Dropwoun from "./Dropwoun";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToastFist from "../report/Toast/ToastFist";
import axios from "axios";
import { Roles } from "./Booking";
import { calculateTimeDifference } from "../report/DataConverte/DataConverte";
import moment from "moment";


const fontSizeMap = {
  s: '100px',   // Small
  m: '180px',   // Medium
  l: '190px',   // Large
};

const textSizeMap = {
  s: '20px',   // Small
  m: '24px',   // Medium
  l: '30px',   // Large
};
const textSizeMap1 = {
  s: '24px',   // Small
  m: '32px',   // Medium
  l: '34px',   // Large
};

const textSizeListMap = {
  s: '60px',   // Small
  m: '90px',   // Medium
  l: '110px',
};

const getStatusColor = (status) => {
  switch (status) {
    case 2:
      return "#e6dcff";
    case 3:
      return "#d1e7dd";
    case 4:
      return "#f9fad4";
    default: return "#fff";
  }
};

const TokenCard = ({ token, onLab, onEdit, onUpdateStatus, view, FontSize, isToggeled }) => {

  const handleStatusChange = (token, value) => {
    token.status = value;
    // console.log( token)
    onUpdateStatus(token);
  };

  const userRole = useMemo(() => localStorage.getItem(USER_ROLE), []);
  // const userRole = useMemo(() => (Roles))

  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const [ShowEdit, setShowEdit] = useState(false);
  const [isLab, setIsLab] = useState(false);
  const [SuprAdmin, setSuprAdmin] = useState(false);


  // console.log(isLab, SuprAdmin, ShowEdit, " fffl")

  const fontSize = fontSizeMap[FontSize] || fontSizeMap.m;
  const textSize = textSizeMap[FontSize] || textSizeMap.m;
  const textSize1 = textSizeMap1[FontSize] || textSizeMap1.m;
  const textSizeList = textSizeListMap[FontSize] || textSizeListMap.m;

  useEffect(() => {
    if (userRole?.includes(SUPER_ADNIM)) {
      setShowEdit(true);
      setSuprAdmin(true);
    } else if (userRole?.includes(ROLE)) {
      setShowEdit(true);
    } else if (userRole?.includes(ROLE_LAB)) {
      setIsLab(true);
    }
  }, []);

  const getButtonClass = () => {
    switch (token.status) {
      case 1:
        return "light";
      case 2:
        return "light";
      case 3:
        return "light";
      default:
        return "light";
    }
  };

  const handleVisit = async (data) => {

    if (data?.status == 1) {
      try {
        const requestPayload = {
          pubInfo: { sessionId: localStorage.getItem(SESSION_ID) },
          request: {
            busiParams: {
              ...data,
              status: 2,
              endTime: moment().format("HH:mm"),
              type: 0,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        };
        let res = await axios.post(
          BASE_URL + update_User_Token,
          JSON.stringify(requestPayload),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Success on the call fo ");
        navigate("/reportMain", { state: data });
        // console.log("success", res)
      } catch (error) {
        console.log("Success on error ", error);
        navigate("/reportMain", { state: data });
      }
    } else {
      navigate("/reportMain", { state: data });
    }
  };
  // console.log(isToggeled, "ff")
  return (
    <div className="pt-4">
      {view === "grid" ? (
        <div className="col card-group"
        >
          <div
            className={`card 
                pb-2 mb-3`
            }
            style={{
              borderRadius: 16,
              backgroundColor: getStatusColor(token.status)
            }}
          >
            <div className="d-flex justify-content-between ">
              {!isToggeled &&
                <p
                  className={` text-start fw-medium  px-4 py-1  text-${token.status === 1 ? "dark" : "dark"
                    }`}
                  style={{ margin: 0, borderTopLeftRadius: 16, borderBottomRightRadius: 6, fontSize: textSize, backgroundColor: "rgba(130, 80, 255, 0.1)", alignSelf: "start" }}
                >
                  {token.status === 1
                    ? "In Queue"
                    : token.status === 2
                      ? "In Progress"
                      : token.status === 3
                        ? "Complete"
                        : "Pending"}
                </p>
              }
              <div className="mx-4" style={{ fontSize: textSize, paddingTop: 3, justifyContent: "flex-end", fontFamily: 'sans-serif' }}>
                <FontAwesomeIcon icon="fa-solid fa-clock" /> {calculateTimeDifference(token?.startTime, token?.endTime)}
              </div>
            </div>
            <p
              className={`card-text text-center  fw-bold text-dark `}
              style={{ fontSize: fontSize, margin: 0 }}
              onClick={
                SuprAdmin
                  ? onEdit
                  : ShowEdit
                    ? token.status == "3"
                      ? () => setShowToast(true)
                      : onEdit
                    : null
              }

            // onClick={showEdit ? onEdit : null}
            >
              {token.token}
            </p>
            <p
              className={`card-text text-center text-truncate px-4 fw-semibold  text-${token.status === 1 ? "dark" : "dark"}`}
              style={{ fontSize: textSize1, paddingBottom: 15 }}
            >
              {token.name}
            </p>
            {isToggeled && !isLab ? (
              <>
                <div
                  className="px-4 border-0 text-light align-self-end "
                  style={{ marginTop: "auto", width: "100%" }}
                >
                  <Dropwoun
                    token={token}
                    showEdit={isToggeled}
                    handleStatusChange={handleStatusChange}
                    Size={FontSize}
                  />
                </div>

                {token?.status === 4 || token?.status === 3 ? (
                  <div className="d-flex justify-content-between my-2 ms-4 ">
                    <button
                      type="button"
                      className={`btn btn-${getButtonClass()}  `}
                      onClick={() => navigate("/reportMain", { state: token })}
                      style={{ width: FontSize == "s" ? "100px" : "136px", height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray', }}
                    >
                      <p className="text-center" style={{ fontSize: FontSize == "s" ? "18px" : "20px", margin: 0, color: "black" }}> Visit Pad</p>
                    </button>

                    <button
                      type="button"
                      className={`btn btn-light me-${!token?.labTest ? '4' : '0'}  `}
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate("/Report", {
                          state: {
                            receivedData: token,
                            selectedLanguages: "en",
                            ageGender: {
                              age: token?.age,
                              gender: token?.gender,
                            },
                          },

                        })
                      }
                      }
                      style={{ height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray' }} >
                      <FontAwesomeIcon
                        icon="fa-solid fa-print"
                        className="btn-light"
                      />
                    </button>

                    {token?.labTest &&
                      (token?.status === 3 || token?.status === 4) && (
                        <div className="me-2">
                          <button
                            type="button"
                            className="btn  me-3 "
                            onClick={onLab}
                            style={{ backgroundColor: '#FCFF6F', height: FontSize == "s" ? "40px" : "44px", width: "90px", fontSize: FontSize == "s" ? "18px" : "20px", borderColor: 'lightgray' }}
                          >
                            Lab
                          </button >
                        </div>
                      )}
                  </div>
                ) : (
                  <button
                    type="button"
                    className={`btn btn-${getButtonClass()} ms-4 my-2`}
                    onClick={() => handleVisit(token)}
                    style={{ width: "136px", height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray', }}
                  >
                    <p className="text-center" style={{ fontSize: FontSize == "s" ? "18px" : "20px", margin: 0, color: "black" }}> Visit Pad</p>
                  </button>
                )}
              </>
            ) : isLab ?
              token?.labTest &&
              (token?.status === 3 || token?.status === 4) && (
                <div className="mx-4 mb-2">
                  <button
                    type="button"
                    className="btn  "
                    onClick={onLab}
                    style={{ backgroundColor: '#FCFF6F', height: FontSize == "s" ? "40px" : "44px", width: "100%", fontSize: FontSize == "s" ? "18px" : "20px", borderColor: 'lightgray' }}
                  >
                    Lab
                  </button >

                </div>
              )
              : null}
          </div>
        </div>
      ) : (
        <div className="table-responsive  ">
          <table className="table table-hover table-borderless">
            <tbody >
              <tr
                onClick={
                  SuprAdmin
                    ? onEdit
                    : ShowEdit
                      ? token.status == "3"
                        ? () => setShowToast(true)
                        : onEdit
                      : null
                }
                key={token.token}
                className={`text-center ${token.status === 1
                  ? ""
                  : token.status === 2
                    ? "table-primary"
                    : token.status === 3
                      ? "table-success"
                      : "table-secondary"
                  }`}
              // style={{ borderWidth: 2, borderColor: "green", }}

              >
                <td
                  className={`fw-bold text-dark rounded-start-3 `}
                  style={{ width: "10%", alignContent: 'center', fontSize: textSizeList, }} // Fixed width for token

                >
                  {token.token}
                </td>
                <td
                  className={` fw-bold text-dark `}
                  style={{ fontSize: textSize1, width: "25%", alignContent: 'center', }} // Fixed width for name

                >
                  {token.name}
                </td>
                {!isToggeled && <td
                  className=" fw-bold text-dark text-center  "
                  style={{ width: "15%", alignContent: 'center', fontSize: FontSize == "s" ? "20px" : "24px", }} // Fixed width for status
                >
                  {/* Status:{" "} */}
                  {token.status === 1
                    ? "In Queue"
                    : token.status === 2
                      ? "In Progress"
                      : token.status === 3
                        ? "Complete"
                        : "Pending"}
                </td>}
                <td className="text-center "
                  style={{ width: "15%", alignContent: 'center', fontSize: FontSize == "s" ? "20px" : "24px", fontFamily: 'sans-serif' }} >
                  <div >
                    <FontAwesomeIcon icon="fa-solid fa-clock" /> {calculateTimeDifference(token?.startTime, token?.endTime)}
                  </div>
                </td>
                {isToggeled && !isLab ? (
                  <>
                    <td className="text-light" style={{ width: "13%", alignContent: 'center', paddingTop: 15 }}>
                      <div onClick={(e) =>
                        e.stopPropagation()}>
                        <Dropwoun
                          token={token}
                          showEdit={isToggeled}
                          handleStatusChange={handleStatusChange}
                          Size={FontSize}
                        />
                      </div>
                    </td>


                    {token.status === 4 || token.status === 3 ? (
                      <td style={{ width: "30%", alignContent: 'center' }} className="rounded-end-3" >
                        <td style={{ width: "15%", alignContent: 'start' }}>
                          <button
                            type="button"
                            className={`btn btn-${getButtonClass(
                              token.status
                            )} `}
                            // style={{ width: "10%" }}
                            onClick={(e) => {
                              e.stopPropagation()
                              navigate("/reportMain", { state: token })
                            }
                            }
                            style={{ width: FontSize == "s" ? "100px" : "136px", height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray', }}
                          >
                            <p className="text-center" style={{ fontSize: FontSize == "s" ? "18px" : "20px", margin: 0, color: "black" }}> Visit Pad</p>
                            {/* <FontAwesomeIcon
                              icon="fa-square-arrow-up-right"
                              className="btn-outline-primary border-0 ms-2"
                              style={{ marginLeft: "0.5rem" }}
                            /> */}
                          </button>
                        </td>
                        <td style={{ width: "15%", alignContent: 'center' }}>
                          <button
                            type="button"
                            className="btn btn-light "
                            onClick={(e) => {
                              e.stopPropagation()
                              navigate("/Report", {
                                state: {
                                  receivedData: token,
                                  selectedLanguages: "en",
                                  ageGender: {
                                    age: token?.age,
                                    gender: token?.gender,
                                  },
                                },
                              })
                            }
                            }
                            style={{ height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray' }}
                          >
                            <FontAwesomeIcon icon="fa-solid fa-print" />
                          </button>
                        </td>
                        <td style={{ width: token?.labTest ? "20%" : "10%", alignContent: 'center', }} >
                          {token?.labTest &&
                            (token?.status === 3 || token?.status === 4) && (
                              <div onClick={(e) =>
                                e.stopPropagation()}>
                                <button
                                  type="button"
                                  className="btn  me-3 "
                                  onClick={onLab}
                                  style={{ backgroundColor: '#FCFF6F', height: FontSize == "s" ? "40px" : "44px", width: "90px", fontSize: FontSize == "s" ? "18px" : "20px", borderColor: 'lightgray' }}
                                >
                                  Lab
                                </button >
                              </div>
                            )}
                        </td>
                      </td>
                    ) : (
                      <td style={{ width: "30%", alignContent: 'center' }} className="rounded-end-3">
                        <button
                          type="button"
                          className={`btn btn-${getButtonClass(token.status)} mx-3 `}
                          onClick={(e) => {
                            e.stopPropagation()
                            handleVisit(token)
                          }}
                          style={{ width: FontSize == "s" ? "100px" : "136px", height: FontSize == "s" ? "40px" : "44px", borderColor: 'lightgray', }}
                        >
                          <p className="text-center" style={{ fontSize: FontSize == "s" ? "18px" : "20px", margin: 0, color: "black" }}> Visit Pad</p>
                        </button>
                      </td>
                    )}
                  </>) : isLab ?

                  token?.labTest &&
                  (token?.status === 3 || token?.status === 4) && (
                    <td style={{ width: "20%", alignContent: 'center' }} className="rounded-end-3">
                      <div className="mx-4 mb-2">
                        <button
                          type="button"
                          className="btn  "
                          onClick={onLab}
                          style={{ backgroundColor: '#FCFF6F', height: FontSize == "s" ? "40px" : "44px", width: "100%", fontSize: FontSize == "s" ? "18px" : "20px", borderColor: 'lightgray' }}
                        >
                          Lab
                        </button >

                      </div>
                    </td>
                  )
                  : null}
              </tr>
            </tbody>
          </table>
        </div>
      )
      }
      <ToastFist
        showToast={showToast}
        setShowToast={setShowToast}
        title="Indication"
        message="Access Denied"
        duration="Just now"
      />

    </div >
  );
};

export default TokenCard;
