// selectStyles.js

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    // boxShadow: state.isFocused ? "none" : provided.boxShadow,
    // "&:hover": {
    //   border: "none",
    // },

  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 16,
  }),
  option: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
};

export default customStyles;

// Custom components to disable dropdown indicator and separator
export const DropdownIndicator = () => null;
export const IndicatorSeparator = () => null;
export const IconFunctionalityOption = {
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    fontSize: "15px",
    // paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: isSelected
      ? "#177ee0" // Background color when the option is selected
      : isFocused
        ? "#cce0ff" // Background color when the option is hovered
        : "#f5f8ff", // Default background color
    color: isSelected ? "#fff" : "#177ee0", // Text color when selected
    fontWeight: isSelected ? "bold" : "unset", // Bold text for selected option
    width: "auto", // Allow the option width to adjust dynamically
    minWidth: "fit-content",
    whiteSpace: "nowrap",

  }),
  menu: (provided) => ({
    ...provided,
    width: "auto", // Allow the dropdown menu to adjust to content
    minWidth: "fit-content", // Ensure it doesn't shrink smaller than the content

  }),
}

// Reusable styles for CreatableSelect component
export const customSelectStyles = {

  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: "#fef0c7", // Correct color without template literal
    color: "black",
    fontWeight: "bold",
    padding: "5px",
    borderRadius: "5px 0px 0px 5px",
  }),
  // menu: (provided) => ({
  //   ...provided,
  //   width: "auto", // Allow the dropdown menu to adjust to content
  //   minWidth: "fit-content", // Ensure it doesn't shrink smaller than the content

  // }),
  multiValueRemove: (base) => ({
    ...base,
    // Correct border style
    backgroundColor: "#fef0c7",
    //  height: "100%",
    //  cursor: "pointer", // Adding pointer cursor for better UX
    borderRadius: "0px 5px 5px 0px",
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    fontSize: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: isSelected
      ? "#177ee0" // Background color when the option is selected
      : isFocused
        ? "#cce0ff" // Background color when the option is hovered
        : "#f5f8ff", // Default background color
    color: isSelected ? "#fff" : "#177ee0", // Text color when selected
    fontWeight: isSelected ? "bold" : "unset", // Bold text for selected option

  }),
  // container: (base) => ({
  //   ...base,

  // }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#2684FF' : '', // default border color
    '&:hover': {
      borderColor: '#2684FF', // hover border color
    },
    borderRadius: '8px', // Rounded border radius
    border: '2px solid #d8dce3', // Default border with 2px thickness
    minHeight: '30px', // Custom height for the input field
    boxShadow: state.isFocused ? '0 0 5px rgba(38, 132, 255, 0.5)' : 'none', // Box shadow on focus
  }),


  multiValue: (base) => ({
    ...base,
    //   border: `2px dotted #7B68EE`,
    //  borderRadius:"10px"
    borderRadius: 5,
  }),
};

export const SinglecustomSelectStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    "&:hover": {
      border: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 16,
  }),
  option: (provided) => ({
    ...provided,
    borderRadius: 5,
  }),

  option: (base, { isSelected, isFocused }) => ({
    ...base,
    fontSize: "15px",
    // paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: isSelected
      ? "#177ee0" // Background color when the option is selected
      : isFocused
        ? "#cce0ff" // Background color when the option is hovered
        : "#f5f8ff", // Default background color
    color: isSelected ? "#fff" : "#177ee0", // Text color when selected
    fontWeight: isSelected ? "bold" : "unset", // Bold text for selected option
    margin: 0,
    borderRadius: 7,


  }),
};

export const PrintDropdownStyle = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#6c757d" : "#ced4da",
    boxShadow: state.isFocused ? "0 0 0 1px #6c757d" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#28a745" : state.isFocused ? "#f8f9fa" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "#e9ecef",
    },
    width: "auto", // Allow the option width to adjust dynamically
    minWidth: "fit-content", // Ensure the option's width matches the content size
    whiteSpace: "nowrap", // Prevent text from wrapping
  }),
  menu: (provided) => ({
    ...provided,
    width: "auto", // Allow the dropdown menu to adjust to content
    minWidth: "fit-content", // Ensure it doesn't shrink smaller than the content
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#495057",
  }),
}