import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faEye,
  faCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { ACCESS_TOKEN, BASE_URL, SESSION_ID } from "../Constant";

function FileUploadModal({
  product,
  productItemId,
  isOpen,
  onClose,
  showEdit,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const fileInputRef = useRef(null);
const [Loading,setLoading]=React.useState(false);


  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile({
        file,
        previewURL: URL.createObjectURL(file),
      });
    }
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile({
        file,
        previewURL: URL.createObjectURL(file),
      });
    }
  };

  const handleDragOver = (e) => e.preventDefault();

  const handleCancel = () => setSelectedFile(null);

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        swal("No file selected.");
        return;
      }
      console.log(selectedFile.file);
      // const newfile=  Object.defineProperty(selectedFile.file, "name", {value: productItemId+selectedFile.file.name})

      const formData = new FormData();
      formData.append("file", selectedFile.file);
      formData.append("folderId", "10");
      formData.append("orderId", productItemId);

      console.log(formData);
      const response = await axios.post(
        BASE_URL + "/uploadFileToFolder",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      await updateReport(response.data.fileDownloadUri);
      swal("Upload successful");
    } catch (error) {
      swal("Error uploading file:", error.message);
    }
  };

  const updateReport = async (url) => {
    setLoading(true)
    try {
      const payload = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            orderId: productItemId,
            itemListReport: [{ itemId: product.itemId, reportUrl: url }],
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
console.log(payload)
      const response = await axios.post(`${BASE_URL}updateReport`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      });

      swal(response.data.respData.message);
      setLoading(false)
      handleCancel();
    } catch (error) {
      setLoading(false)
      swal("Error updating report:", error.message);
    }
  };


  const repotDelet =async(imageUrl,orderNo)=>{


    try {
      const payload = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            filePath: imageUrl,
            id: orderNo,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });


      console.log(payload)

      const response = await axios.post(`${BASE_URL}deleteFile`,payload,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },


      })

      swal(response.data.respData.message);
      
    } catch (error) {
      console.log(error)
      
    }

  }










  const openFullscreenModal = (imageUrl) => setFullscreenImage(imageUrl);
  const closeFullscreenModal = () => setFullscreenImage(null);

  return (
    <>
      <div
        className={`modal ${isOpen ? "show" : ""}`}
        tabIndex={-1}
        style={{
          display: isOpen ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Report's</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              />
            </div>
            <div className="modal-body">
              <ul className="list-group">
              {showEdit && (
                <li className="list-group-item d-flex justify-content-between align-items-center">
                 
                    <div
                      style={{
                        border: `3px solid ${
                          selectedFile || isHovered ? "green" : "gray"
                        }`,
                        borderRadius: "10px",
                        maxWidth: 200,
                        padding: "1px",
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <label>
                        {selectedFile ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {selectedFile.previewURL ? (
                              <img
                                src={selectedFile.previewURL}
                                alt="Selected"
                                style={{
                                  width: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <p>Selected File: {selectedFile.file.name}</p>
                            )}
                          </div>
                        ) : (
                          <div className="text-center">
                            <FontAwesomeIcon
                              icon={faCloudArrowUp}
                              className="text-primary"
                              style={{ width: 180, height: 50 }}
                            />
                            <p>Upload File</p>
                          </div>
                        )}
                      </label>
                    </div>
             
                  <div>
                    <button
                      className="btn btn-sm btn-outline-primary mx-3"
                      onClick={handleUpload}
                    >
{Loading &&  
<div class="spinner-border text-primary me-2 " style={{height:15,width:15}} role="status">
  <span class="visually-hidden">Loading...</span>
</div>

}
                      Upload
                    </button>
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </li>
              )}
               
                {product?.reportUrlList  ? (
                  product?.reportUrlList.map((reportUrl) => (
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                      <span>
                        <img
                          src={reportUrl}
                          alt="Report"
                          style={{ width: 30 }}
                          className="rounded me-2"
                        />
                        {product.title.length <= 25
                          ? product.title
                          : `${product.title.slice(0, 25)}...`}
                        {/* {product.reportUrl} */}
                      </span>
                      <div>
                        <span
                          className="badge bg-primary rounded-pill me-3"
                          onClick={() => openFullscreenModal(reportUrl)}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </span>
                        <Link to={reportUrl} target="_blank" download>
                          <span className="badge bg-primary rounded-pill me-3">
                            <FontAwesomeIcon icon={faCircleDown} />
                          </span>
                        </Link>
                        {showEdit && (
                        <span className="badge bg-primary rounded-pill" onClick={()=>repotDelet(reportUrl,product.id)}>
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </span>
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span>
                      <FontAwesomeIcon
                        icon="fa-solid fa-file-excel"
                        style={{ width: 30 }}
                        className="rounded me-2"
                      />
                      Report not available
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {fullscreenImage && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex={-1}
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeFullscreenModal}
                />
              </div>
              <div className="modal-body d-flex justify-content-center align-items-center">
                <img
                  src={fullscreenImage}
                  alt="Fullscreen"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FileUploadModal;
