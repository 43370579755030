import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

import { iconColor, headerText } from "./visitConstant/repotOtherConstant";

const LeftMenuBar = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="row g-3 w-90">
        <div
          className="col-12 d-flex flex-column align-items-center text-center"
          onClick={() => navigate("/")}
        >
          <FontAwesomeIcon
           // icon="fa-solid fa-folder-plus"
           icon="fa-solid fa-house"
            className="mb-2  fs-5"
            style={{ color: iconColor }}
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
          >
            {/* Add Folder */}

            Home
          </span>
        </div>
        <div
          className="col-12 d-flex flex-column align-items-center text-center"
          onClick={() => navigate("/tokenPage")}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-file-circle-plus"
            className="mb-2  fs-5"
            style={{ color: iconColor }}
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
          >
            {/* New File */}
             Reports
          </span>
        </div>
        <div
          className="col-12 d-flex flex-column align-items-center text-center"
          onClick={() => navigate("/mastermedicine")}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-book-medical"
            className="mb-2  fs-5"
            style={{ color: iconColor }}
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
          >
            Medical Book
          </span>
        </div>
        <div className="col-12 d-flex flex-column align-items-center text-center"    onClick={() => navigate("/")}>
          <FontAwesomeIcon
            icon="fa-solid fa-flask-vial"
            className="mb-2  fs-5"
            style={{ color: iconColor }}
           
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
           
          >
            Lab Test
          </span>
        </div>
        <div className="col-12 d-flex flex-column align-items-center text-center">
          <FontAwesomeIcon
            icon="fa-brands fa-wpforms"
            className="mb-2 fs-5"
            style={{ color: iconColor }}
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
          >
            Forms
          </span>
        </div>
        <div className="col-12 d-flex flex-column align-items-center text-center">
          <FontAwesomeIcon
            icon="fa-solid fa-right-to-bracket"
            className="mb-2 fs-5"
            style={{ color: iconColor }}
          />
          <span
            className=""
            style={{ fontSize: 11, color: headerText, fontWeight: "bold" }}
          >
            Login
          </span>
        </div>
      </div>
    </>
  );
};
export default LeftMenuBar;
