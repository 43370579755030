
import React, { useState } from "react";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import {
  BASE_URL,
  ERROR_MSG,
  GENERATEUSERTOKEN,
  IMG_BASE_URL,
  SESSION_ID,
  Tokan_Status_In_Queue,
  Tokan_Type_Generate,
} from "../Constant";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

import TokenForm from "./TokenForm";
import Carousel from "./Carousel";
import ToastFist from "../report/Toast/ToastFist";
import { useDispatch } from "react-redux";
import { setUserToken } from "../report/ReduxApi/UserToken";

const CreateToken = () => {
  const [user, setUser] = useState({
    name: "",
    mobileNo: "",
    address: "",
    date: "",
    type: 0,
    status: 0,
    comment: "",
    age: "",
    gender: 'M'

  });
  const [showToast, setShowToast] = useState(false);
  const [colorStatus, setColorStatus] = useState(3);
  const [toastMsg, setToastMsg] = useState("");
  const section = localStorage.getItem(SESSION_ID);
  const [inputValue, setInputValue] = useState("");
  const [inputMobailValue, setInputMobailValue] = useState("");
  const dispatch = useDispatch();

  const [currentDate, setCurrentDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const handleChange = (e) => {
    // if (e.target.name === "name") {
    //   setUser({ ...user, ["name"]: inputValue });
    // } else {
    setUser({ ...user, [e.target.name]: e.target.value });
    // }

    // console.log({ ...user, [e.target.name]: e.target.value })
  };



  const saveUser = async (e) => {

    // e.preventDefault();
    if (user?.name === "" || user?.mobileNo === "") {
      setShowToast(true);
      setColorStatus(3)
      setToastMsg("Please enter name and mobile number");
    } else {
      await generateUserToken(user?.name, user?.mobileNo, user?.address, user?.age, user?.gender);

      //console.log(user.name, user.mobileNo, user.address, user.age ,user?.gender)
    }
  };

  const generateUserToken = async (name, mobileNo, address, age, gender) => {

    try {
      const userData = {
        pubInfo: {
          sessionId: section,
        },
        request: {
          busiParams: {
            name: name?.trim(),
            date: currentDate,
            mobileNo: mobileNo?.trim(),
            address: address?.trim(),
            type: Tokan_Type_Generate,
            status: Tokan_Status_In_Queue,
            comment: "",
            age,
            gender,
            startTime: moment().format("HH:mm")
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      };

      // console.log(userData, "FINSAL ")

      const response = await axios.post(
        BASE_URL + GENERATEUSERTOKEN,
        userData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
          },
        }
      );
       console.log("token data response", response)
      const userTokenData = response?.data?.respData?.respMsg?.userTokenList[0];
      // console.log("token data redux", userTokenData)

      setShowToast(true);
      setColorStatus(1)
      setToastMsg("Patient token generated successfully");
      // swal("Success", "User token generated successfully", "success");
      setUser({
        name: "",
        mobileNo: "",
        address: "",
        date: "",
        type: 0,
        status: 0,
        comment: "",
        age: "",
        gender: ''
      });
      setCurrentDate(moment(new Date()).format("YYYY-MM-DD"))
      setInputValue("");
      setInputMobailValue("")
    } catch (err) {
      setCurrentDate(moment(new Date()).format("YYYY-MM-DD"))
      setShowToast(true);
      setColorStatus(0)
      setToastMsg(ERROR_MSG);
      // console.error(err);
    } finally {
      setUser({
        name: "",
        mobileNo: "",
        address: "",
        date: "",
        type: 0,
        status: 0,
        comment: "",
        age: "",
        gender: ''
      });
      setInputValue("");
      setInputMobailValue("")
      setCurrentDate(moment(new Date()).format("YYYY-MM-DD"))
    }
  };

  const onSelect = (value) => {
    // console.log(value);
    if (value) {
      setUser({
        name: value?.name,
        mobileNo: value?.mobileNo,
        address: value?.address,
        date: value?.date,
        type: 0,
        status: 0,
        comment: value?.comment,
        age: value?.age,
        gender: value?.gender,
      });
      setInputValue(value?.name);
      setInputMobailValue(value?.mobileNo)
    }
  };

  return (
    <>
      <div className="row  mt-5" >
        <ScrollToTopOnMount />
        <div className=" rounded-3 text-black">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-12">
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <div className="row g-0">
                    <div className="col-lg-8 d-flex align-items-center">
                      <div className="card-body  text-black">
                        <TokenForm

                          user={user}
                          handleChange={handleChange}
                          saveUser={saveUser}
                          onSelect={onSelect}
                          inputValue={inputValue}
                          setInputValue={setInputValue}
                          setInputMobailValue={setInputMobailValue}
                          currentDate={currentDate}
                          setCurrentDate={setCurrentDate}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-4 d-none d-lg-block bg-primary align-self-center"
                      style={{ height: '80vh', borderRadius: "1rem" }}
                    >
                      {/* <img
                        src={`${IMG_BASE_URL}logo192.png`}
                        style={{ width: 70, height: 60 }}
                        className="img-fluid mt-5"
                        alt="appLogo"
                      /> */}
                      <Carousel />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastFist
          showToast={showToast}
          setShowToast={setShowToast}
          title="Indication"
          message={toastMsg}
          duration="Just now"
          status={colorStatus}
        />
      </div>
    </>
  );
};

export default CreateToken;
