import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { DEBUG_LOG_ENABLED, addCustomer, BASE_URL ,ACCESS_TOKEN} from "../Constant";
import LoginScrollpages from "../login/LoginScrollpages";
import swal from "sweetalert";
import axios from "axios";
export default function SignUp (props) {
  let [authMode, setAuthMode] = useState("signin");

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [customer, setCustomer] = useState({
    customerId: "",
    name: "",
    emailId: "",
    mobileNo: "",
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };
  const saveCustomer = async (e) => {
  // const saveCustomer =   async (e) => {
    e.preventDefault();
    if (
      customer.name === "" ||
      customer.password === "" ||
      customer.emailId === "" ||
      customer.mobileNo === ""
    ) {
      swal(" Please enter all fields ");
    } else if (confirmPassword !== customer.password) {
      swal(" password not matched ");
    } else {
      try {
        const customerData = JSON.stringify({
          pubInfo: {
            sessionId: "3D3CFD149568C3AD31F4D3A9A9010C84",
          },
          request: {
            busiParams: {
              name: customer.name,
              emailId: customer.emailId,
              mobileNo: customer.mobileNo,
              password: customer.password,
            },
            isEncrypt: false,
            transactionId: "897987987989",
          },
        });

        let response = await axios.post(BASE_URL + addCustomer, customerData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        });

   
        // .then((response) => {
        swal(" " + response.data.respData.message);
        navigate("/login");
        if (DEBUG_LOG_ENABLED) {
          console.log(response.data);
        }
        // })
      }
    
      catch (err) {
        swal("error : " + err);
        if (DEBUG_LOG_ENABLED) {
            console.log(err);
          }
     }
    }
  };


 












  return (
    // <div className="Auth-form-container">
    //   <form className="Auth-form">
    //     <div className="Auth-form-content">
    //       <h3 className="Auth-form-title">Sign Up</h3>
    //       <div className="text-center">
    //         Already registered?{" "}
    //         <a
    //           href="#"
    //           className="link-info"
    //           onClick={() => navigate("/login")}
    //         >
    //           {" "}
    //           Sign In
    //         </a>
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Full Name</label>
    //         <input
    //           type="text"
    //           name="name"
    //           maxlength="25"
    //           value={customer.name}
    //           onChange={(e) => handleChange(e)}
    //           className="form-control mt-1"
    //           placeholder="e.g Ram Kumar"
    //         />
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Mobile Number</label>
    //         <input
    //           type="text"
    //           name="mobileNo"
    //           maxlength="12"
    //           value={customer.mobileNo}
    //           onChange={(e) => handleChange(e)}
    //           className="form-control mt-1"
    //           placeholder="Mobile Number"
    //         />
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Email address</label>
    //         <input
    //           type="email"
    //           name="emailId"
    //           maxlength="25"
    //           value={customer.emailId}
    //           onChange={(e) => handleChange(e)}
    //           className="form-control mt-1"
    //           placeholder="Email Address"
    //         />
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Password</label>
    //         <input
    //           type="password"
    //           name="password"
    //           maxlength="15"
    //           value={customer.password}
    //           onChange={(e) => handleChange(e)}
    //           className="form-control mt-1"
    //           placeholder="Password"
    //         />
    //       </div>
    //       <div className="form-group mt-3">
    //         <label>Confirm Password</label>
    //         <input
    //           type="password"
    //           name="confirm password"
    //           maxlength="15"
    //           value={confirmPassword}
    //           onChange={(e) => setConfirmPassword(e.target.value)}
    //           className="form-control mt-1"
    //           placeholder="Confirm Password"
    //         />
    //       </div>
    //       <div className="d-grid gap-2 mt-3">
    //         <button
    //           onClick={saveCustomer}
    //           type="submit"
    //           className="btn btn-info"
    //         >
    //           Submit
    //         </button>
    //       </div>
    //     </div>
    //   </form>
    // </div>

    <section className=" py-5">
      <div className="container py-5 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="card rounded-3 text-black">
            <div className="row g-0">
            
              <LoginScrollpages/>
              <div className="col-md-12 col-lg-6 col-xl-4 offset-xl-1 p-5">
               
                <h3 className="Auth-form-title">Sign Up</h3>
                <div className="text-center">
                  Already registered?{" "}
                  <a className="link-info" onClick={() => navigate("/login")}>
                    {" "}
                    Sign In
                  </a>
                  <div className="d-flex align-items-center justify-content-center pt-4">
                    <p className="mb-0 me-2"> You Are Doctor?</p>
                    <input
                      class="form-check-input "
                      type="radio"
                      onClick={() => navigate("/drsignup")}
                    />
                    <label class="form-check-label pe-2">Yes</label>

                    <input
                      class="form-check-input"
                      type="radio"
                      checked
                      onClick={() => navigate("/signup")}
                    />
                    <label class="form-check-label">No</label>
                  </div>
                  {/* <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      onClick={() => navigate("/signup")}
                      checked
                    />
                    <label class="form-check-label">Yes</label>
                  </div> */}
                </div>
                <div className="form-group mt-3">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    maxlength="25"
                    value={customer.name}
                    onChange={(e) => handleChange(e)}
                    className="form-control mt-1"
                    placeholder="e.g Ram Kumar"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNo"
                    maxlength="12"
                    value={customer.mobileNo}
                    onChange={(e) => handleChange(e)}
                    className="form-control mt-1"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    name="emailId"
                    maxlength="25"
                    value={customer.emailId}
                    onChange={(e) => handleChange(e)}
                    className="form-control mt-1"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    maxlength="15"
                    value={customer.password}
                    onChange={(e) => handleChange(e)}
                    className="form-control mt-1"
                    placeholder="Password"
                  />
                </div>
                <div className="form-group mt-3">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    name="confirm password"
                    maxlength="15"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="form-control mt-1"
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button
                    onClick={saveCustomer}
                    type="submit"
                    className="btn btn-info"
                  >
                    Submit
                  </button>
                </div>
                {/* </div> */}
                {/* </form> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
