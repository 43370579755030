import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

import {
  SESSION_ID,
  CUSTOMER_ID,
  BASE_URL,
  GET_OREDER_HISTORY,
  ACCESS_TOKEN,
  DEBUG_LOG_ENABLED,
  DOCTOR_ID,
  getOrderInfoByDate,
  USER_ROLE,
  ROLE,
  getCustomerAddressByUserId,
  ROLEDOCTOR,
} from "../Constant";
import { useState, useEffect } from "react";

import moment from "moment";

import { json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import AccountMenu from "../profile/account-menu";
import OrderHistoryItem from "../order_history/OrderHistoryItem";

import axios from "axios";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import OrderHistoryItemDetail from "./OrderHistoryItemDetail";

function OrderHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderItemList, setOrderItemList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  let [showEdit, setShowEdit] = useState(false);
  //  let [startdate, setStartdate] = useState("2023-01-01");
  let [Enddate, setenddate] = useState(moment().format("YYYY-MM-DD"));
  let [Loader, setLoader] = useState(false);
  const isLogged = useSelector((state) => state.isLogged);

  const getAddressLists = async () => {
    try {
      const customerData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: localStorage.getItem(CUSTOMER_ID),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + getCustomerAddressByUserId,
        customerData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      setAddressList(filterByAddressId(response.data.respData.respMsg));
      localStorage.setItem(
        "addressList",
        JSON.stringify(response.data.respData.respMsg)
      );
      if (DEBUG_LOG_ENABLED) {
        console.log(response.data);
      }
    } catch (err) {
      swal("data : " + err);
      if (DEBUG_LOG_ENABLED) {
        console.log(err);
      }
    }
  };

  const filterByAddressId = (addressItemList, addressId) => {
    const addressItem = addressItemList.filter((item) => item.id === addressId);
    return addressItemList;
  };
  const getOrdertLists = async () => {
    const customerId = localStorage.getItem(CUSTOMER_ID);
    const doctorId = localStorage.getItem(DOCTOR_ID);

    try {
      const orderListData = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            customerId: doctorId === "" || doctorId === "0" ? customerId : "",
            doctorId: doctorId === "" || doctorId === "0" ? "" : doctorId,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });

      let response = await axios.post(
        BASE_URL + GET_OREDER_HISTORY,
        orderListData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );

      // swal("data : "+response.data.respData.respMsg.sessionId);
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      if (DEBUG_LOG_ENABLED) {
        console.log("orderItemList : " + orderItemList);
      }
      //swal("data : "+catItemList.length);
    } catch (err) {
      if (err.response?.status === 401) {
        // navigate("/login");
      } else {
        swal("data : " + err);
        if (DEBUG_LOG_ENABLED) {
          console.log(err);
        }
      }
    }
  };

  const getOrderDate = async (startdate, Enddate) => {
    setLoader(true);


    try {
      const getOrderByDate = JSON.stringify({
        pubInfo: {
          sessionId: localStorage.getItem(SESSION_ID),
        },
        request: {
          busiParams: {
            // startDate: "2023-03-20",
            // endDate: moment().format("YYYY-MM-DD"),
            startDate: startdate ?? moment().subtract(1, "days").format("YYYY-MM-DD"),
            endDate: Enddate ?? moment().format("YYYY-MM-DD"),
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      });
      let response = await axios.post(
        BASE_URL + "getOrderInfoByDate",
        getOrderByDate,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
          },
        }
      );
      setOrderItemList(response.data.respData.respMsg.morderInfoList);
      setLoader(false);
      console.log(response.data.respData.respMsg.morderInfoList);
    } catch (error) {
      console.log(error);
      swal("Something went wrong!");

      setLoader(false);
    }
  };

  useEffect(() => {
    const addressList = localStorage.getItem("addressList");
    const addressItemList = JSON.parse(addressList);
    const userRole = localStorage.getItem(USER_ROLE);
    if (userRole?.includes(ROLE)) {
      setShowEdit(true);
      getOrderDate();
    }

    // if (addressItemList?.lenght == 0 || addressItemList === null) {
    //   getAddressLists();
    // } else {
    //   setAddressList(addressItemList);
    // }
    //  getAddressLists();

    userRole?.includes(ROLE) ? getOrderDate() : getOrdertLists();
    console.log("useEffect,oderhistory");
    //getOrderDate();
  }, []);

  const [customer, setCustomer] = useState({
    startdate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    endtdate: moment().format("YYYY-MM-DD"),
  });

  const handleChange = (e) => {
    setenddate(customer.endtdate);
    const value = e.target.value;
    setCustomer({ ...customer, [e.target.name]: value });
  };
  const loginCustomer = () => {
    // setStartdate(customer.startdate);
    //  setenddate(customer.endtdate);

    if (
      //  customer.username === "" ,

      (customer.endtdate === "", customer.startdate === "")
    ) {
      swal(" Please entery data  ");
    } else {
      // loginData(customer.username, customer.password, customer.idToken);

      getOrderDate(customer.startdate, customer.endtdate);
    }
  };

  return (
    <div className="container mt-5 py-5 px-xl-5" >
      <div className="container py-4" style={{ paddingTop: "100px" }}>
        <div className="row g-3">
          {/* <div className="col-lg-3">
            <AccountMenu current="order-history" />
          </div> */}
          <div className="col">
            {showEdit ? (
              <div className="row g-2 mb-4">
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      name="startdate"
                      type="date"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={customer.startdate}
                      required
                      aria-required="true"
                    />
                    <label htmlFor="floatingInputGrid">startDate </label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <input
                      name="endtdate"
                      type="date"
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      // value={Enddate}
                      value={customer.endtdate}
                      required
                      aria-required="true"
                    />
                    <label htmlFor="floatingInputGrid">
                      EndtDate (current date)
                    </label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating">
                    <button
                      onClick={loginCustomer}
                      type="submit"
                      className="btn btn-primary m-2 py-2"
                    >
                      Submit
                      {Loader && (
                        <div
                          className="spinner-border text-light mx-1 "
                          style={{ height: 20, width: 20 }}
                          role="status"
                        ></div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {orderItemList?.length === 0 ? (
              isLogged ? (
                <div className="container py-4">Empty booking history</div>
              ) : (
                <div class="d-flex justify-content-center">
                  <div className=" text-center">
                    <h5 className="card-title">
                      To see your order history, please log in to your account.
                      Once you're logged in, you can access your order history
                      and track your past orders.
                    </h5>
                    <button
                      onClick={() => navigate("/login")}
                      type="submit"
                      className="btn btn-primary m-2 px-5"
                    >
                      login
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div>
                {orderItemList?.map((it) => (
                  <>
                    {showEdit ? (
                      <OrderHistoryItemDetail
                        id={it?.orderNum}
                        order={it.id}
                        product={it}
                        cancel={it.catType}

                        addressData={it?.address}
                        getOrderDate={getOrderDate}
                      />
                    ) : (
                      <OrderHistoryItem
                        id={it?.orderNum}
                        product={it}
                        // cancel={it.paymentType}
                        // addressData={filterByAddressId(addressList, it.addressId)}
                        addressData={it?.address}
                      />
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
