


// const ClearAll =()=>{


//     return true
// }

// export default ClearAll;
// auth.js

// Action Types

// auth.js

// Action Types
export const SET_LOGIN_STATUS = 'DATA CLEAN';

// Actions with Thunk for Timeout
export const setDataStatus = (isLoggedIn) => {
  return (dispatch) => {
    // Set the login status
    dispatch({
      type: SET_LOGIN_STATUS,
      payload: isLoggedIn,
    });

    // Automatically reset the login status to false after 1000ms
    setTimeout(() => {
      dispatch({
        type: SET_LOGIN_STATUS,
        payload: false,
      });
    }, 5000);
  };
};

// Initial State
const initialState = {
  isLoggedIn: false,
};

// Reducer
const DataClearAll = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};

export default DataClearAll;
