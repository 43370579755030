import React, { useState, useEffect } from "react";
import axios from "axios";
import Headline from "../landing/ Headline";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpalText from "../landing/SimpalText";
// import "./InstagramCarousel.css"; // Create a CSS file for custom styles

function InstagramCarousel({ item, navigate, showEdit, nama }) {
  const [mediaPosts, setMediaPosts] = useState([]);
  //const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // if (item.viewMoreTitleID){
    //    setAccessToken(item.viewMoreTitleID)
    //  // alert(item.viewMoreTitleID)
    // }

    const apiUrl = `https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${item.viewMoreTitleID}`;

    axios
      .get(apiUrl)
      .then((response) => {
        const posts = response.data.data;
        const mediaPosts = posts.filter(
          (post) => post.media_type === "IMAGE" || post.media_type === "VIDEO"
        );
        if (mediaPosts) {
          localStorage.setItem("mediaPosts", mediaPosts);
          setMediaPosts(mediaPosts);
        } else {
          const instadata = localStorage.getItem("mediaPosts");
          setMediaPosts(instadata);
        }
      })
      .catch((error) => {
        console.error("Error fetching Instagram media:", error);
        const instadata = localStorage.getItem("mediaPosts");
        setMediaPosts(instadata);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4, // Number of slides to show on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1350, // Adjust for tablets and smaller screens
        settings: {
          slidesToShow: 3, // Number of slides to show on tablets
        },
      },
      {
        breakpoint: 995, // Adjust for tablets and smaller screens
        settings: {
          slidesToShow: 2, // Number of slides to show on tablets
        },
      },
      {
        breakpoint: 768, // Adjust for mobile screens
        settings: {
          slidesToShow: 1, // Number of slides to show on mobile screens
        },
      },
    ],
  };
  const gradientStyle = {
    background: "linear-gradient(135deg, #833ab4, #fd1d1d, #fcb045 ,#EBE76C)",
    backgroundSize: "300% 300%",
    animation: "gradientAnimation 3s ease infinite",
  };

  return (
    <div className="instagram-carousel  ">
      <div className="mb-3 fw-semibold">
        {mediaPosts?.length < 0 && (
          <SimpalText
            item={item}
            navigate={navigate}
            showEdit={showEdit}
            nama={nama}
          />
        )}
      </div>

      <div
        className="container  p-4 "
        //  style={gradientStyle}
      >
        {mediaPosts?.length < 0 && (
          <Slider {...settings}>
            {mediaPosts?.map((post, index) => (
              <div
                key={post?.id}
                className="carousel-item"
                onClick={() =>
                  window.open("https://www.instagram.com/jivdanihospital/")
                }
              >
                {post?.media_type === "IMAGE" && (
                  <img
                    style={{ height: 300, width: 300 }}
                    src={post?.media_url}
                    alt={post?.caption || "Instagram Image"}
                    className="carousel-image rounded-4 mx-auto"
                  />
                )}
                {post?.media_type === "VIDEO" && (
                  <div className="card rounded-4 me-1">
                    <video
                      controls
                      style={{ height: 300, width: 300 }}
                      src={post?.media_url}
                      type="video/mp4"
                      className="carousel-video rounded-4 mx-auto"
                    />
                  </div>
                )}
                {/* <div className="caption">
                
                {post.caption.length > 100
                  ? post.caption.substring(0, 100) + "......."
                  : post.caption}
              </div> */}
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default InstagramCarousel;
