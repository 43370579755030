// // src/redux/actions/templateActions.js

// import axios from 'axios';
// import { ACCESS_TOKEN, BASE_URL } from '../../Constant';

// // Action Types
// const FETCH_TEMPLATE_NAMES_REQUEST = 'FETCH_TEMPLATE_NAMES_REQUEST';
// const FETCH_TEMPLATE_NAMES_SUCCESS = 'FETCH_TEMPLATE_NAMES_SUCCESS';
// const FETCH_TEMPLATE_NAMES_FAILURE = 'FETCH_TEMPLATE_NAMES_FAILURE';

// // Action Creators
// const getRootTemplateRequest = () => ({
//   type: FETCH_TEMPLATE_NAMES_REQUEST,
// });

// const getRootTemplatesSuccess = (templateNames) => ({
//   type: FETCH_TEMPLATE_NAMES_SUCCESS,
//   payload: templateNames,
// });

// const getRootTemplateFailure = (error) => ({
//   type: FETCH_TEMPLATE_NAMES_FAILURE,
//   payload: error,
// });

// // Async Action to Fetch Data
// export const getRootTemplateData = ( id) => async (dispatch) => {
//   dispatch(getRootTemplateRequest());

//   try {
//     const response = await axios.post(
//       `${BASE_URL}/getRootTemplate`, // Ensure BASE_URL is properly appended
//       {
//         pubInfo: {
//           sessionId: "3762D32E2F01170A2D5B50B5C74F48C7",
//         },
//         request: {
//           busiParams: {
//             id:5,
//           },
//           isEncrypt: false,
//           transactionId: "897987987989",
//         },
//       },
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
//         },
//       }
//     );

//      dispatch(getRootTemplatesSuccess(response.data));
//    // console.log(  "response=======>>>>>>>======>>>>>>",    response.data)
//   } catch (error) {
//     dispatch(getRootTemplateFailure(error.message));
//   }
// };

// // Initial State
// const rootTemplateInitialState = {
//   loading: false,
//   rootTemplateData: [],
//   rootTemplateError: '',
// };

// // Reducer
// export const rootTemplateReducer = (state = rootTemplateInitialState, action) => {
//   switch (action.type) {
//     case FETCH_TEMPLATE_NAMES_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case FETCH_TEMPLATE_NAMES_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         rootTemplateData: action.payload,
//         rootTemplateError: '',
//       };
//     case FETCH_TEMPLATE_NAMES_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         rootTemplateData: [],
//         rootTemplateError: action.payload,
//       };
//     default:
//       return state;
//   }
// };



import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL, SESSION_ID } from '../../Constant';
import { GET_ROOT_TEMPLATE } from '../visitConstant/ApiConstant';

// Action Types
const FETCH_ROOT_TEMPLATE_NAMES_REQUEST = 'FETCH_ROOT_TEMPLATE_NAMES_REQUEST';
const FETCH_ROOT_TEMPLATE_NAMES_SUCCESS = 'FETCH_ROOT_TEMPLATE_NAMES_SUCCESS';
const FETCH_ROOT_TEMPLATE_NAMES_FAILURE = 'FETCH_ROOT_TEMPLATE_NAMES_FAILURE';

// Action Creators
const fetchRootTemplateRequest = () => ({
  type: FETCH_ROOT_TEMPLATE_NAMES_REQUEST,
});

export const fetchRootTemplatesSuccess = (templateNames) => ({
  type: FETCH_ROOT_TEMPLATE_NAMES_SUCCESS,
  payload: templateNames,
});

const fetchRootTemplateFailure = (error) => ({
  type: FETCH_ROOT_TEMPLATE_NAMES_FAILURE,
  payload: error,
});

// Async Action
export const getRootTemplateData = (id) => async (dispatch) => {
  dispatch(fetchRootTemplateRequest());

  const section = localStorage.getItem(SESSION_ID);
  try {
    const response = await axios.post(
      `${BASE_URL}${GET_ROOT_TEMPLATE}`,
      {
        pubInfo: {
          sessionId: section,
        },
        request: {
          busiParams: {
            id: id,
          },
          isEncrypt: false,
          transactionId: "897987987989",
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
      }
    );
    // console.log(response.data.respData.respMsg, ' temp root')
    dispatch(fetchRootTemplatesSuccess(response.data.respData.respMsg));
  } catch (error) {
    dispatch(fetchRootTemplateFailure(error.message));
    // console.log(error, ' temp root')
  }
};


// Initial State
const rootTemplateInitialState = {
  loading: false,
  rootTemplateData: [],
  rootTemplateError: '',
};

// Reducer
export const rootTemplateReducer = (state = rootTemplateInitialState, action) => {
  switch (action.type) {
    case 'FETCH_ROOT_TEMPLATE_NAMES_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_ROOT_TEMPLATE_NAMES_SUCCESS':
      return {
        ...state,
        loading: false,
        rootTemplateData: action.payload,
        rootTemplateError: '',
      };
    case 'FETCH_ROOT_TEMPLATE_NAMES_FAILURE':
      return {
        ...state,
        loading: false,
        rootTemplateData: [],
        rootTemplateError: action.payload,
      };
    default:
      return state;
  }
};

