import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Color } from "../../visitConstant/Color";

import icons from "../../Utils/icons";
import Categories from "../../visitConstant/Categories";

const MasterSideBar = ({ handleSideBar }) => {
  const [activeItemId, setActiveItemId] = useState(Categories.MEDICINE.catID); // Use IDs for tracking active state
  const navigate = useNavigate();

  // Function to handle item clicks and set the active ID
  const handleItemClick = (id) => {
    setActiveItemId(id); // Set the clicked item's ID as active
    handleSideBar(id);
  };

  return (
    <>
      <div className="row g-5 ">
        {/* Medicine Analyst */}
        <div
          className="d-flex flex-column align-items-center text-center"
          onClick={() => handleItemClick(Categories.MEDICINE.catID)}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-pills"
            className="mb-2 fs-5"
            style={{
              cursor: "pointer",
              color:
                activeItemId === Categories.MEDICINE.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          />

          <span
            style={{
              fontSize: 12,
              fontWeight: "bolder",
              color:
                activeItemId === Categories.MEDICINE.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          >
            Medicine <br /> Analyst
          </span>
        </div>

        {/* New File */}
        <div
          className="d-flex flex-column align-items-center text-center"
          onClick={() => handleItemClick(Categories.DIAGNOSIS.catID)}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-briefcase-medical"
            className="mb-2 fs-5"
            style={{
              cursor: "pointer",
              color:
                activeItemId === Categories.DIAGNOSIS.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          />

          <FontAwesomeIcon icon="fa-regular fa-notes-medical" />
          <span
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color:
                activeItemId === Categories.DIAGNOSIS.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          >
            Diagnosis
          </span>
        </div>

        {/* Medical Book */}
        <div
          className="d-flex flex-column align-items-center text-center"
          onClick={() => handleItemClick(Categories.INVESTIGATIONS.catID)}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-syringe"
            className="mb-2 fs-5"
            style={{
              cursor: "pointer",
              color:
                activeItemId === Categories.INVESTIGATIONS.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          />
          <span
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color:
                activeItemId === Categories.INVESTIGATIONS.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          >
            Investigation
          </span>
        </div>

        {/* Lab Test */}
        <div
          className="d-flex flex-column align-items-center text-center"
          onClick={() => handleItemClick(Categories.HISTORY.catID)}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-flask-vial"
            className="mb-2 fs-5"
            style={{
              cursor: "pointer",
              color:
                activeItemId === Categories.HISTORY.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          />
          <span
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color:
                activeItemId === Categories.HISTORY.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          >
            History
          </span>
        </div>

        {/* Forms */}
        <div
          className="d-flex flex-column align-items-center text-center"
          onClick={() => handleItemClick(Categories.COMPLAINTS.catID)}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-bed-pulse"
            className="mb-2 fs-5"
            style={{
              cursor: "pointer",
              color:
                activeItemId === Categories.COMPLAINTS.catID
                  ? Color.primary
                  : Color.graydark, // Color changes based on activeItemId
            }}
          />
          <span
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color: activeItemId === Categories.COMPLAINTS.catID ? Color.primary : Color.graydark, // Color changes based on activeItemId
            }}
          >
            Complaints
          </span>
        </div>
      </div>
    </>
  );
};

export default MasterSideBar;
